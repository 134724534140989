import { Button, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { Stack } from '@mui/system';
import classNames from 'classnames';
import { rem } from '../../app/AppUtilities';
import { EditButton } from '../editButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      color: theme.pauseComponent.timer.color.default,
      borderRadius: rem(65),
      backgroundColor: theme.pauseComponent.timer.background,
      alignItems: 'center',
      padding: `${rem(12)} ${rem(22)} ${rem(12)} ${rem(24)}`,
      textTransform: 'none',
    },
    pauseText: {
      fontWeight: 800,
      fontSize: rem(theme.generalStyles.bigTextSize),
      lineHeight: rem(14),
    },
    channels: {
      fontWeight: 400,
      fontSize: rem(theme.generalStyles.baseTextSize),
      lineHeight: rem(14),
    },
    time: {
      fontWeight: 800,
      fontSize: rem(theme.generalStyles.largeTextSize),
      lineHeight: rem(38),
    },
    disabled: {
      '&.Mui-disabled': {
        color: theme.pauseComponent.timer.color.default,
      },
    },
    pending: {
      '&.Mui-disabled': {
        color: theme.pauseComponent.timer.color.pending,
      },
    },
    fadeIn: {
      animation: 'fadeIn 1s infinite alternate',
    },
    stackContainer: {
      padding: `${rem(1)} 0px`,
    },
  })
);
export interface PauseTimerLabel {
  banner: string;
  channel: string;
  time: string;
}
export interface PauseTimerData {
  timerEnabled: boolean;
  disabled?: boolean;
  pending?: boolean;
  isAnimationEnabled: boolean;
}

export interface PauseTimerProps {
  label: PauseTimerLabel;
  data: PauseTimerData;
  onPauseTimerButtonClick: () => void;
}

const PauseTimerButton = ({ label, data, onPauseTimerButtonClick }: PauseTimerProps) => {
  const classes = useStyles();
  const { timerEnabled, disabled, pending, isAnimationEnabled } = data;
  const clickAction = !disabled && !pending ? onPauseTimerButtonClick : undefined;

  return (
    <Button
      onClick={clickAction}
      disabled={disabled || pending}
      className={classNames(
        classes.root,
        isAnimationEnabled && classes.fadeIn,
        disabled && classes.disabled,
        pending && classes.pending
      )}
      data-testid="pause-edit-button"
    >
      <Stack direction="row" gap={rem(24)} alignItems="center" className={classes.stackContainer}>
        <Stack direction="column" gap={rem(8)} alignItems="flex-start">
          <div className={classes.pauseText}>{label.banner}</div>
          <div className={classes.channels}>{label.channel}</div>
        </Stack>

        {timerEnabled && (
          <div className={classes.time} data-testid="pause-timer">
            {label.time}
          </div>
        )}
        {!disabled && <EditButton />}
      </Stack>
    </Button>
  );
};
export default PauseTimerButton;
