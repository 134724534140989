import { NetworkStatus, useQuery } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { LoadingButton } from '@mui/lab';
import classNames from 'classnames';
import { useState } from 'react';
import { rem } from '../app/AppUtilities';
import { BranchesQueryData, BranchesQueryVariables } from '../app/RestaurantInfoQueryType';
import { useSettings } from '../app/SettingsContext';
import { useTraductions } from '../app/TraductionsContext';
import DecorationLine, { DecorationLineVariant } from '../prepTime/DecorationLine';
import { MENU_ITEMS_QUERY, RESTAURANT_BRANCHES_QUERY } from '../queries/queries';
import { mapToChannel } from '../utils/ThirdPartyOrderUtils';
import EmptyListMessage from './EmptyListMessage';
import ItemStatesCaptions from './ItemStatesCaptions';
import { Data, Variables } from './MenuItemsTypes';
import RestaurantMenuItem from './RestaurantMenuItem';
import SearchBar from './SearchBar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: rem(theme.spacing(2)),
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
    leftContainer: {
      [theme.breakpoints.up('md')]: {
        width: `calc(70% - ${rem(theme.spacing(2))})`,
      },
    },
    menuItemsList: {
      height: '100%',
      marginBottom: rem(190),
      overflowY: 'auto',
      [theme.breakpoints.up('md')]: {
        marginBottom: rem(theme.spacing(1)),
      },
    },
    menuItemsListWithWarning: {
      [theme.breakpoints.down('md')]: {
        marginBottom: rem(290),
      },
    },
    queryMessage: {
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
    showMoreButton: {
      margin: `${rem(theme.spacing(3))} 0`,
      width: '50%',
      fontSize: rem(theme.generalStyles.mediumTextSize),
      letterSpacing: rem(1),
      wordSpacing: rem(2),
    },
  })
);

export default function MenuItemsPage() {
  const settings = useSettings();
  const traductions = useTraductions();
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const branchId = settings.currentBranchId;
  const limit = 10;

  const { data } = useQuery<BranchesQueryData, BranchesQueryVariables>(RESTAURANT_BRANCHES_QUERY, {
    skip: !settings.currentRestaurantId,
    variables: { restaurantId: settings.currentRestaurantId! },
  });

  const externalChannels = data?.branches
    .find((branch) => branch.id === branchId)
    ?.thirdPartyOrderIntegrations?.map(mapToChannel);

  const showExternalChannelsWarning = !!externalChannels?.length;

  if (!branchId) {
    return null;
  }

  return (
    <>
      <header className={classes.leftContainer}>
        <Typography variant="h4" className={classes.title}>
          {traductions.menuItemsPage.pageTitle}
        </Typography>
        <DecorationLine variant={DecorationLineVariant.Red} />
        <SearchBar setSearch={setSearch} />
      </header>
      <Query<Data, Variables>
        query={MENU_ITEMS_QUERY}
        variables={{ branchId, offset: 0, limit, search }}
        fetchPolicy="network-only"
        notifyOnNetworkStatusChange
      >
        {({ data, loading, error, fetchMore, networkStatus }) => {
          if (loading && !data) return <p className={classes.queryMessage}>{traductions.menuItemsPage.loading}</p>;
          if (error) return <p className={classes.queryMessage}>{traductions.menuItemsPage.error}</p>;

          if (data) {
            return (
              <div
                className={classNames(
                  classes.leftContainer,
                  classes.menuItemsList,
                  showExternalChannelsWarning && classes.menuItemsListWithWarning
                )}
              >
                {!data.items.results.length && <EmptyListMessage message={traductions.menuItemsPage.emptyList} />}
                {data.items.results.map((item) => (
                  <RestaurantMenuItem key={item.id} itemData={item} />
                ))}
                {data.items.hasMore && (
                  <LoadingButton
                    classes={{ root: classes.showMoreButton }}
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      fetchMore({
                        variables: {
                          branchId,
                          offset: data.items.results.length,
                          limit,
                          search,
                        },
                        updateQuery: (prev, { fetchMoreResult }) => {
                          if (!fetchMoreResult) return prev;
                          return Object.assign({}, prev, {
                            items: {
                              ...fetchMoreResult.items,
                              results: [...prev.items.results, ...fetchMoreResult.items.results],
                            },
                          });
                        },
                      })
                    }
                    loading={networkStatus === NetworkStatus.fetchMore}
                    loadingPosition="end"
                    endIcon={<span />}
                    data-testid="menu-items-load-more-button"
                  >
                    {traductions.ordersListPage.ordersShowMore}
                  </LoadingButton>
                )}
              </div>
            );
          }

          return null;
        }}
      </Query>
      <ItemStatesCaptions externalChannels={externalChannels} />
    </>
  );
}
