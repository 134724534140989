import { List } from '@mui/material';
import { DefaultTheme } from '@mui/material/styles/createTheme';
import { createStyles, makeStyles } from '@mui/styles';
import { WithTestId } from '@ueat/poke';

import { rem } from '../../app/AppUtilities';
import { SelectableTileListItem, SelectableTileListItemElement } from './';

export interface SelectableTileListData {
  elements: SelectableTileListItemElement[];
}

export interface SelectableTileListAction {
  onChange: (value: any) => void;
}

export interface SelectableTileListProps extends SelectableTileListAction, WithTestId {
  data: SelectableTileListData;
}

const useStyles = makeStyles((theme: DefaultTheme) => {
  const gap = rem(40);
  return createStyles({
    list: {
      display: 'flex',
      flexWrap: 'wrap',
      '@media (max-height: 680px)': {
        gap: rem(32),
      },
      gap,
      padding: '0px',

      '& .MuiListItem-root': {
        flex: `1 0 calc(50% - ${gap})`,
      },
    },
  });
});

export const SelectableTileList = ({ data, onChange, testId }: SelectableTileListProps) => {
  const styles = useStyles();

  return (
    <List className={styles.list}>
      {data.elements.map((element) => (
        <SelectableTileListItem
          key={element.value}
          data={{
            element,
          }}
          onSelect={onChange}
          testId={testId}
        />
      ))}
    </List>
  );
};
