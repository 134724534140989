import { useMutation } from '@apollo/client';
import { useEffect, useReducer } from 'react';
import { useTraductions } from '../../app/TraductionsContext';
import { PAUSE_RESTAURANT } from '../../mutations/mutations';
import { PAUSE_STATES_QUERY } from '../../queries/queries';
import {
  initializeState,
  PauseChannelData,
  pauseChannelDrawerReducer,
  PauseChannelDrawerReducerType,
  PauseChannelDrawerState,
} from '../pauseChannelDrawer';
import { ChannelStates, OrderChannelEnum, PauseAmount } from '../types/PauseTypes';

export interface UsePauseChannelDrawerType {
  state: PauseChannelDrawerState;
  isNextButtonDisabled: boolean;
  isBackButtonDisabled: boolean;
  isPrimaryButtonStartIconDisplayed: boolean;
  isPrimaryButtonEndIconDisplayed: boolean;
  handleSelectDelay: (delay: PauseAmount) => void;
  handleSynchronizeDelay: (synchronizeDelay: boolean) => void;
  handleSelectItem: (channelId: string) => void;
  handleTimeExpiry: (channelId: string) => void;
  handleNextAction: () => void;
  handleBackAction: () => void;
}

interface PauseRestaurantData {
  pauseRestaurant: ChannelStates[];
}

interface PauseRestaurantVariables {
  branchId: number;
  channels: OrderChannelEnum[];
  pauseAmount: PauseAmount;
  synchronizeDelay: boolean;
}

interface Props {
  data: PauseChannelData;
  onCloseDrawer: () => void;
  onResumeChannel: (channelsToResume: OrderChannelEnum[]) => void;
  handlePauseSuccessNotification: (pauseStates: ChannelStates[]) => void;
  handlePauseErrorNotification: () => void;
}

export const usePauseChannelDrawer = ({
  data,
  onCloseDrawer,
  onResumeChannel,
  handlePauseSuccessNotification,
  handlePauseErrorNotification,
}: Props): UsePauseChannelDrawerType => {
  const traductions = useTraductions();

  const [state, dispatch] = useReducer(
    pauseChannelDrawerReducer,
    {
      channelList: data.channelList,
      traductions: traductions.pauseBanner,
    },
    initializeState
  );

  useEffect(() => {
    if (data.open) {
      dispatch({
        type: PauseChannelDrawerReducerType.INITIALIZE,
        payload: { channelList: data.channelList, traductions: traductions.pauseBanner },
      });
    }
  }, [data.open, data.channelList, traductions.pauseBanner]);

  const [pauseRestaurant, { loading: pauseRestaurantLoading }] = useMutation<
    PauseRestaurantData,
    PauseRestaurantVariables
  >(PAUSE_RESTAURANT, {
    onCompleted: (data) => {
      handlePauseSuccessNotification(data.pauseRestaurant);
      onCloseDrawer();
    },
    onError: () => {
      handlePauseErrorNotification();
    },
    refetchQueries: [
      {
        query: PAUSE_STATES_QUERY,
        variables: { branchId: data.branchId },
      },
    ],
  });

  const handlePauseRestaurant = () => {
    pauseRestaurant({
      variables: {
        branchId: data.branchId,
        channels: state.selectedChannelsToPauseIdsList,
        pauseAmount: state.delaysList.elements.find((delayElement) => delayElement.selected)?.value,
        synchronizeDelay: state.synchronizeDelay,
      },
    });
  };

  const handleSelectDelay = (delay: PauseAmount) => {
    dispatch({ type: PauseChannelDrawerReducerType.DELAY_SELECTED, payload: { delay } });
  };

  const handleSynchronizeDelay = (synchronizeDelay: boolean) => {
    dispatch({ type: PauseChannelDrawerReducerType.SYNCHRONIZE_DELAY_CHANGED, payload: { synchronizeDelay } });
  };
  const handleSelectItem = (channelId: string) => {
    dispatch({ type: PauseChannelDrawerReducerType.CHANNEL_SELECTED, payload: { channelId: channelId } });
  };

  const handleNextAction = () => {
    if (state.currentStep === 'channelsSelection') {
      if (state.selectedChannelsToResumeIdsList.length > 0) {
        onResumeChannel(state.selectedChannelsToResumeIdsList);
      } else {
        dispatch({ type: PauseChannelDrawerReducerType.NEXT_ACTION });
      }
    } else {
      handlePauseRestaurant();
    }
  };

  const handleBackAction = () => {
    dispatch({ type: PauseChannelDrawerReducerType.BACK_ACTION });
  };

  const handleTimeExpiry = (channelId: string) => {
    dispatch({ type: PauseChannelDrawerReducerType.TIME_EXPIRY, payload: { channelId: channelId } });
  };

  return {
    state,
    isNextButtonDisabled: pauseRestaurantLoading,
    isBackButtonDisabled: pauseRestaurantLoading,
    isPrimaryButtonStartIconDisplayed:
      state.currentStep === 'channelsSelection' && state.selectedChannelsToResumeIdsList.length > 0,
    isPrimaryButtonEndIconDisplayed:
      state.currentStep === 'channelsSelection' && state.selectedChannelsToPauseIdsList.length > 0,
    handleSelectDelay,
    handleSynchronizeDelay,
    handleSelectItem,
    handleNextAction,
    handleBackAction,
    handleTimeExpiry,
  };
};
