import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { RestaurantMenuItemState, RestaurantMenuItemType } from './MenuItemsTypes';
import { MENU_ITEM_STATE_MUTATION } from '../mutations/mutations';
import { useMutation } from '@apollo/client/react';
import { useTraductions } from '../app/TraductionsContext';
import { useSettings } from '../app/SettingsContext';
import { rem } from '../app/AppUtilities';
import { green, orange, red } from '@mui/material/colors';
import { snackbarCloseAction } from '../app/SnackbarActions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItemContainer: {
      marginBottom: rem(theme.spacing(3)),
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: rem(theme.spacing(3)),
    },
    icon: {
      fontSize: rem(theme.generalStyles.bigIconSize),
    },
    toggle: {
      height: rem(75),
      minWidth: rem(74),
      backgroundColor: 'white',
      border: `${rem(1)} solid #DDDDDD !important`,
    },
    on: {
      backgroundColor: `${green[800]} !important`,
      color: `${theme.palette.primary.contrastText} !important`,
    },
    temporaryOff: {
      backgroundColor: `${orange[800]} !important`,
      color: `${theme.palette.primary.contrastText} !important`,
    },
    off: {
      backgroundColor: `${red[800]} !important`,
      color: `${theme.palette.primary.contrastText} !important`,
    },
    disabled: {
      color: 'rgba(0, 0, 0, 0.12) !important',
      '&.Mui-selected': {
        backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
      },
    },
    menuItemNameWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: rem(theme.spacing(0.5)),
    },
    menuItemName: {
      margin: 0,
      fontSize: rem(theme.generalStyles.baseTextSize + 4),
      fontWeight: 'bold',
      display: '-webkit-box',
      overflow: 'hidden',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2,
    },
    readonlyLabel: {
      margin: 0,
      fontSize: rem(theme.generalStyles.baseTextSize),
      fontWeight: 600,
      fontStyle: 'italic',
      color: 'rgba(0, 0, 0, 0.5)',
    },
  })
);

interface Data {
  changeState: {
    id: number;
  };
}

interface Variables {
  itemId: number;
  branchId: number;
  state: RestaurantMenuItemState;
}

interface Props {
  itemData: RestaurantMenuItemType;
}

export default function RestaurantMenuItem({ itemData }: Props) {
  const settings = useSettings();
  const traductions = useTraductions();
  const classes = useStyles();
  const itemId = itemData.id;
  const branchId = settings.currentBranchId;

  const [updateItemState, { loading }] = useMutation<Data, Variables>(MENU_ITEM_STATE_MUTATION, {
    onCompleted: () => {
      enqueueSnackbar({
        message: traductions.menuItemsPage.availabilitySaveSuccess,
        action: snackbarCloseAction,
      });
    },
    onError: () => {
      enqueueSnackbar({
        message: traductions.main.genericError,
        variant: 'error',
        action: snackbarCloseAction,
      });
    },
  });

  const handleChange = (_event: React.MouseEvent<HTMLElement, MouseEvent>, newState: RestaurantMenuItemState) => {
    if (newState && branchId) {
      updateItemState({
        variables: { itemId, branchId, state: newState },
      });
    }
  };

  const disabled = itemData.isStateReadOnly || loading;

  return (
    <div className={classes.menuItemContainer}>
      <ToggleButtonGroup value={itemData.state} exclusive onChange={handleChange}>
        <ToggleButton
          value={RestaurantMenuItemState.Active}
          classes={{ root: classes.toggle, selected: classes.on, disabled: classes.disabled }}
          disabled={disabled}
        >
          <CheckCircleOutlineIcon classes={{ root: classes.icon }} />
        </ToggleButton>
        <ToggleButton
          value={RestaurantMenuItemState.Temporary}
          classes={{ root: classes.toggle, selected: classes.temporaryOff, disabled: classes.disabled }}
          disabled={disabled}
        >
          <PauseCircleOutlineIcon classes={{ root: classes.icon }} />
        </ToggleButton>
        <ToggleButton
          value={RestaurantMenuItemState.Disabled}
          classes={{ root: classes.toggle, selected: classes.off, disabled: classes.disabled }}
          disabled={disabled}
        >
          <HighlightOffOutlinedIcon classes={{ root: classes.icon }} />
        </ToggleButton>
      </ToggleButtonGroup>
      <div className={classes.menuItemNameWrapper}>
        <p className={classes.menuItemName}>{itemData.adminName}</p>

        {itemData.isStateReadOnly && (
          <p className={classes.readonlyLabel}>{traductions.menuItemsPage.availabilityReadonly}</p>
        )}
      </div>
    </div>
  );
}
