import { Status } from '../orderDetail/OrderDetailTypes';
import { OrderChannelEnum } from '../pauseChannel/types/PauseTypes';
import { ServiceType } from '../types/ServicesTypes';

export interface Data {
  orders: {
    hasMore: boolean;
    totalCount: number;
    results: OrderResults[];
    __typename: string;
  };
}

export interface Variables {
  branchId: number;
  restaurantId?: number;
  filter: OrderStatus;
  offset: number;
}

export interface OrderResults {
  id: number;
  shortNumber: number;
  pickupDate: string;
  isPaid: boolean;
  status: Status;
  channel: OrderChannelEnum;
  thirdPartyOrderExternalReference: string | null;
  customer: {
    fullName: string;
    phoneNumber: string;
  };
  service: {
    type: ServiceType;
    name: string;
  };
  totals: {
    total: number;
  };
}

export enum OrderStatus {
  pending = 'PENDING',
  preordered = 'PRE_ORDERS',
  processed = 'COMPLETED',
  all = 'ALL',
}
