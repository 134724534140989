import { enqueueSnackbar } from 'notistack';
import React from 'react';
import DelayContainer, { DelayType } from './DelayContainer';
import TimerIcon from '@mui/icons-material/TimerOutlined';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme, Typography } from '@mui/material';
import { useSettings } from '../app/SettingsContext';
import { useTraductions } from '../app/TraductionsContext';
import { Data, Variables } from './PrepTimeTypes';
import { Query } from '@apollo/client/react/components';
import { RESTAURANT_DELAY_QUERY } from '../queries/queries';
import { rem } from '../app/AppUtilities';
import moment from 'moment';
import 'moment-timezone';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageContainer: {
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'center',
    },
    queryMessage: {
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
    totalTimeContainer: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      flexBasis: '100%',
    },
    totalTimeBox: {
      display: 'flex',
      alignItems: 'center',
      margin: `${rem(theme.spacing(3))} 0 0`,
      padding: `${rem(theme.spacing(2))} ${rem(theme.spacing(6))}`,
      border: `${rem(theme.generalStyles.largeDecorationHeight)} solid ${theme.palette.primary.main}`,
      textTransform: 'uppercase',
      fontSize: rem(theme.generalStyles.veryBigText),
      fontWeight: 'bold',
    },
    totalTimeTitle: {
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
    totalTime: {
      margin: 0,
    },
    expiration: {
      position: 'absolute',
      top: '100%',
      left: 0,
      width: '100%',
      textAlign: 'center',
      fontSize: rem(theme.generalStyles.mediumTextSize),
      textTransform: 'none',
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
    icon: {
      height: rem(theme.generalStyles.veryBigIconSize),
      width: rem(theme.generalStyles.veryBigIconSize),
      marginRight: rem(theme.spacing(2)),
    },
  })
);

export default function PrepTimePage() {
  const settings = useSettings();
  const traductions = useTraductions();
  const classes = useStyles();
  const branchId = settings.currentBranchId;

  const handleError = (error: string) => {
    enqueueSnackbar({
      variant: 'error',
      message: error,
    });
  };

  if (!branchId) {
    return null;
  }

  return (
    <>
      <Query<Data, Variables> query={RESTAURANT_DELAY_QUERY} variables={{ branchId }} fetchPolicy="no-cache">
        {({ data, loading, error, refetch }) => {
          if (loading) return <p className={classes.queryMessage}>{traductions.preparationTimePage.loading}</p>;
          if (error) return <p className={classes.queryMessage}>{traductions.preparationTimePage.error}</p>;

          if (data) {
            const expirationDateTime = moment(data.preparationTime.base.temporaryExpiration)
              .tz(settings.currentBranch?.timezone || '')
              .format('LLL');

            const hasDelivery = !!settings.currentBranch?.services?.find((s) => s.type === 'DELIVERY');

            let totalMinutes = data.preparationTime.base.totalMinutes;
            if (hasDelivery) {
              totalMinutes = Number(totalMinutes) + Number(data.preparationTime.delivery.totalMinutes);
            }

            return (
              <div className={classes.pageContainer}>
                <DelayContainer
                  variant={DelayType.Base}
                  delaysData={data}
                  refetchDelays={refetch}
                  setMutationError={handleError}
                />
                <DelayContainer
                  variant={DelayType.Delivery}
                  delaysData={data}
                  refetchDelays={refetch}
                  setMutationError={handleError}
                />
                <div className={classes.totalTimeContainer}>
                  <Typography variant="h4" className={classes.totalTimeTitle}>
                    {traductions.preparationTimePage.totalDelayTitle}
                  </Typography>
                  <div className={classes.totalTimeBox}>
                    <TimerIcon
                      classes={{
                        root: classes.icon,
                      }}
                    />
                    <p className={classes.totalTime} data-testid="prep-totalTime">
                      {totalMinutes} {traductions.preparationTimePage.minutes}
                    </p>
                    {!!data.preparationTime.base.temporaryExpiration && (
                      <p
                        className={classes.expiration}
                      >{`${traductions.preparationTimePage.expiration} ${expirationDateTime}`}</p>
                    )}
                  </div>
                </div>
              </div>
            );
          }

          return null;
        }}
      </Query>
    </>
  );
}
