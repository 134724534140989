import React from 'react';
import { ApolloError } from '@apollo/client';

import { useTraductions } from './TraductionsContext';
import { useQueryMessageStyle } from './RestaurantManager';

interface Props {
  error: ApolloError;
}

const INTERNAL_ERROR = 'Cannot resolve';
const CUSTOM_ERROR_PREFIX = 'GraphQL error: GraphQL.ExecutionError: ';
const CUSTOM_ERROR_PREFIX_LENGTH = CUSTOM_ERROR_PREFIX.length;

export default function ErrorMessage({ error }: Props) {
  const traductions = useTraductions();
  const queryMessageClass = useQueryMessageStyle();

  let message = traductions.orderDetailPage.error;
  if (error.message.indexOf(INTERNAL_ERROR) < 0 && error.message.indexOf(CUSTOM_ERROR_PREFIX) === 0) {
    message = error.message.substr(CUSTOM_ERROR_PREFIX_LENGTH);
  }

  return <p className={queryMessageClass.message}>{message}</p>;
}
