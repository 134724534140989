import { useUnleashContext } from '@unleash/proxy-client-react';
import { useEffect } from 'react';
import { useSettings } from '../app/SettingsContext';
import { getUeatEnvironment } from '../utils/env';

export default function UnleashConfigurator() {
  const updateContext = useUnleashContext();
  const settings = useSettings();

  useEffect(() => {
    if (settings.authToken && settings.currentBranchId && settings.currentRestaurantId) {
      updateContext({
        properties: {
          isGlobalAdmin: settings.isGlobalAdmin?.toString(),
          headquarterCompanyId: settings.currentRestaurantId.toString(),
          companyProfileId: settings.currentBranchId.toString(),
          headquarterId: settings.currentHeadquarterId?.toString() ?? '',
          /**
           * Since our Unleash license only supports two environements, we need an additional
           * custom constraint to be able to differentiate Staging & Beta from Development. The
           * different name is to avoid the constraint being overwritten by the Unleash-used
           * environment variable.
           */
          ueatEnvironment: getUeatEnvironment(),
        },
      });
    }
  }, [settings.currentRestaurantId, settings.currentBranchId, settings.authToken, settings.currentHeadquarterId]);

  return null;
}
