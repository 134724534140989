import { useApolloClient } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { getOperationName } from 'apollo-link';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { emitCustomEvent } from 'react-custom-events';
import { useLocation } from 'react-router-dom';
import { rem } from '../app/AppUtilities';
import { useIsPosIntegrated, useSettings } from '../app/SettingsContext';
import { useTraductions } from '../app/TraductionsContext';
import { useAlerts } from '../app/WebSocketClient';
import { EventTypes } from '../events';
import FormattedPrice from '../formatted/FormattedPrice';
import { ORDER_PROCESSED_MUTATION } from '../mutations/mutations';
import SeparationLine from '../orderDetail/SeparationLine';
import generateReceipt from '../print/generateReceipt';
import print from '../print/print';
import { ORDERS_QUERY, ORDER_POPUP_QUERY } from '../queries/queries';
import { useSoundPlayer } from '../sounds/useSoundPlayer';
import { ValidationError } from '../types/errors';
import useNativeEvent from '../useNativeEvent';
import DialogButtons from './DialogButtons';
import { Data, Variables } from './EventModalTypes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    date: {
      display: 'inline-block',
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
    text: {
      marginTop: rem(theme.spacing(2)),
      color: theme.palette.secondary.main,
      lineHeight: 1,
      fontSize: rem(theme.generalStyles.mediumTextSize),
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: rem(theme.spacing(3)),
      fontSize: rem(theme.generalStyles.biggerTextSize),
      fontWeight: 'bold',
      padding: rem(theme.generalStyles.containerPadding),
      borderLeft: `${rem(10)} solid ${theme.palette.primary.main}`,
    },
    titleText: {
      paddingLeft: rem(theme.generalStyles.containerPadding),
      display: 'inline-block',
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
    line: {
      margin: `0 ${rem(theme.spacing(1))}`,
    },
    icon: {
      height: rem(theme.generalStyles.mediumIconSize),
      width: rem(theme.generalStyles.mediumIconSize),
    },
    queryMessage: {
      display: 'inline-block',
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
  })
);

export enum ModalCloseButtonVariant {
  Single = 'single',
  All = 'all',
}

const PRINTED_ORDER_IDS: number[] = [];

function EventModal() {
  const client = useApolloClient();
  const [open, setOpen] = React.useState(false);
  const [currentOrderId, setCurrentOrderId] = useState<number | undefined>();
  const [alerts] = useAlerts();
  const settings = useSettings();
  const traductions = useTraductions();
  const location = useLocation();
  const classes = useStyles();
  const soundPlayer = useSoundPlayer();
  const isPosIntegrated = useIsPosIntegrated();

  useEffect(() => {
    return () => {
      soundPlayer.stopSound();
    };
  }, []);

  useEffect(() => {
    if (settings.alertProfile.alertsOn) {
      if (!open && alerts.length > 0) {
        setOpen(true);

        soundPlayer.playSound();
        setCurrentOrderId(alerts[alerts.length - 1].OrderId);
      }
    }
  }, [
    alerts,
    setOpen,
    open,
    soundPlayer,
    settings.alertProfile.soundOn,
    settings.alertProfile.alertsOn,
    location.pathname,
  ]);

  useEffect(() => {
    if (alerts.length === 0) {
      soundPlayer.stopSound();
    }
  }, [alerts]);

  useEffect(() => {
    if (!isPosIntegrated && settings.printState.isAutomatic) {
      for (let alert of alerts) {
        if (PRINTED_ORDER_IDS.indexOf(alert.OrderId) < 0) {
          PRINTED_ORDER_IDS.push(alert.OrderId);
          const order = { shortNumber: alert.OrderNumber, sessionId: alert.SessionId } as any;
          generateReceipt(settings, order)
            .then((receiptData) => print(settings, alert.OrderId, receiptData, true))
            .catch((e: Error) => {
              emitCustomEvent(EventTypes.LOG, { message: e.message, error: e });
              if (e instanceof ValidationError) {
                return;
              }

              enqueueSnackbar({
                message: e.message,
                variant: 'error',
              });
            });
        }
      }
    }
  }, [settings, alerts, isPosIntegrated]);

  useNativeEvent('print-succeed', (data: any) => {
    if (data?.orderId) {
      client
        .mutate({
          mutation: ORDER_PROCESSED_MUTATION,
          variables: {
            id: data.orderId,
          },
          refetchQueries: [getOperationName(ORDERS_QUERY)!],
        })
        .catch((e: Error) => {
          emitCustomEvent(EventTypes.LOG, { message: e.message, error: e });
        });
    }
  });

  useNativeEvent<Error>('print-failed', (e) => {
    enqueueSnackbar({
      message: e.message,
      variant: 'error',
    });
    emitCustomEvent(EventTypes.LOG, { message: e.message, error: e });
  });

  if (!alerts) {
    return null;
  }

  return (
    <>
      <Dialog open={open} maxWidth="sm" fullWidth={true}>
        <DialogTitle
          classes={{
            root: classes.title,
          }}
        >
          <NewReleasesIcon className={classes.icon} />
          <span className={classes.titleText}>{traductions.eventModal.title}</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            classes={{
              root: classes.text,
            }}
            id="alert-dialog-description"
          >
            <Query<Data, Variables>
              query={ORDER_POPUP_QUERY}
              variables={{
                branchId: settings.currentBranchId!,
                id: currentOrderId as number,
                restaurantId: settings.currentRestaurantId!,
              }}
              fetchPolicy="no-cache"
            >
              {({ data, loading, error }) => {
                if (loading) return <span className={classes.queryMessage}>{traductions.eventModal.loading}</span>;
                if (error) return <span className={classes.queryMessage}>{traductions.eventModal.error}</span>;

                if (data && data.order) {
                  return (
                    <>
                      {`#${data.order.shortNumber} - `}
                      <span className={classes.date}>{traductions.eventModal.readyOn}</span>
                      {` ${moment(data.order.pickupDate)
                        .tz(settings.currentBranch!.timezone as string)
                        .format('LLL')} - Total : `}
                      <FormattedPrice currency={settings.currentBranch!.currency} value={data.order.totals.total} />
                    </>
                  );
                }

                return null;
              }}
            </Query>
          </DialogContentText>
        </DialogContent>
        <SeparationLine className={classes.line} />
        <DialogActions>
          <DialogButtons setOpen={setOpen} />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EventModal;
