import { FormControl, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Autocomplete } from '@ueat/poke';
import { useMemo } from 'react';
import { rem } from '../app/AppUtilities';
import { Branch, Restaurant } from '../app/RestaurantInfoQueryType';
import { useSettings } from '../app/SettingsContext';
import { getCultureText } from '../utils/i18n';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      marginBottom: rem(theme.spacing(4)),
    },
    formControl: {
      minWidth: 120,
      width: rem(theme.generalStyles.settingsInputContainerWidth),
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
    },
    formTitle: {
      marginBottom: rem(theme.spacing(3)),
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
  })
);

export enum Choices {
  Restaurant = 'restaurant',
  Branch = 'branch',
}

interface SettinggSelectionProps {
  type: Choices;
  itemsList: Array<Restaurant | Branch>;
  selectedValue?: number;
  setSelectedValue: (value: number) => void;
  selectionType: string;
  title: string;
}

export default function SettingsSelection({
  type,
  itemsList,
  selectionType,
  title,
  selectedValue,
  setSelectedValue,
}: SettinggSelectionProps) {
  const classes = useStyles();
  const settings = useSettings();

  const itemListFormatted = useMemo(() => {
    return itemsList
      .map(({ id, name }) => {
        const formattedName = getCultureText(name, settings.culture);
        return { value: id, label: formattedName };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [itemsList]);

  return (
    <div className={classes.container}>
      <Typography
        variant="h4"
        classes={{
          root: classes.formTitle,
        }}
        align="center"
      >
        {title}
      </Typography>
      <FormControl className={classes.formControl}>
        <Autocomplete
          onChange={setSelectedValue}
          value={itemListFormatted.find((item) => item.value === selectedValue)}
          title={selectionType}
          virtualized
          elements={itemListFormatted}
          testId={`settings-${type}-selection-autocomplete`}
        />
      </FormControl>
    </div>
  );
}
