export interface SupportPageType {
  baseTitle: string;
  videoTutorialTitle: string;
  phone: string;
  email: string;
  international: string;
  quebecArea: string;
  videoTutorialParagraph: string;
  poweredBy: string;
  buttonText: string;
}

export const enCa: SupportPageType = {
  baseTitle: 'technical support',
  videoTutorialTitle: 'Video tutorial',
  phone: 'by phone :',
  email: 'by email :',
  international: 'international',
  quebecArea: 'quebec area',
  videoTutorialParagraph: 'to learn more about our application and its features :',
  poweredBy: 'powered by',
  buttonText: 'view',
};

export const frCa: SupportPageType = {
  baseTitle: 'assistance technique',
  videoTutorialTitle: 'Tutoriel vidéo',
  phone: 'par téléphone :',
  email: 'par courriel :',
  international: 'international',
  quebecArea: 'région de québec',
  videoTutorialParagraph: 'pour en apprendre plus sur notre application et ses fonctionnalités :',
  poweredBy: 'propulsé par',
  buttonText: 'visionner',
};
