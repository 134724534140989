import { Button, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { RouteComponentProps, withRouter } from 'react-router';
import { rem } from '../app/AppUtilities';
import { useTraductions } from '../app/TraductionsContext';
import { OrderChannelEnum } from '../pauseChannel/types/PauseTypes';
import {
  isThirdPartyOrderIntegrationCancellation,
  isThirdPartyOrderCancellationSupported,
} from '../utils/ThirdPartyOrderUtils';
import { OrderNumberLabel } from './OrderDetailTypes';

type Props = {
  setOpen: (value: boolean) => void;
  buttonIsDisabled: boolean;
  orderChannel: OrderChannelEnum;
  isPaid: boolean;
} & RouteComponentProps<{}>;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonStyle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 'bold',
      fontSize: rem(theme.generalStyles.mediumTextSize),
    },
    warningLabel: {
      color: theme.palette.info.dark,
      fontStyle: 'italic',
      fontSize: rem(theme.generalStyles.smallerTextSize),
    },
  })
);

function RefundOrCancelButton({ setOpen, buttonIsDisabled, orderChannel, isPaid }: Props) {
  const traductions = useTraductions();
  const classes = useStyles();

  var isThirdPartyOrderCancellation = isThirdPartyOrderIntegrationCancellation(orderChannel);
  var unableCancelMsg = isThirdPartyOrderCancellation
    ? traductions.orderDetailPage.unableCancelMessage.replace('THIRD-PARTY-INTEGRATION', OrderNumberLabel[orderChannel])
    : '';

  const handleClick = () => {
    setOpen(true);
  };

  const messageButton =
    !isThirdPartyOrderCancellation && isPaid ? traductions.orderDetailPage.refund : traductions.orderDetailPage.cancel;

  return (
    <>
      <Button
        disabled={buttonIsDisabled}
        className={classes.buttonStyle}
        color="primary"
        variant="contained"
        onClick={handleClick}
        data-testid="order-detail-refund-or-cancel-button"
      >
        {messageButton}
      </Button>
      {!isThirdPartyOrderCancellationSupported(orderChannel) && buttonIsDisabled && !!unableCancelMsg && (
        <p className={classes.warningLabel}>{unableCancelMsg}</p>
      )}
    </>
  );
}

export default withRouter(RefundOrCancelButton);
