import React from 'react';
import FormattedPrice from '../formatted/FormattedPrice';
import { useTraductions } from '../app/TraductionsContext';
import { Promotion } from './OrderDetailTypes';
import SeparationLine from './SeparationLine';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';
import ItemElement, { ElementType } from './ItemElement';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flexShrink: 0,
      flexBasis: 'auto',
    },
  })
);

interface Props {
  promotionData: Promotion[] | null;
  currency: string;
}

export default function Promotions({ promotionData, currency }: Props) {
  const classes = useStyles();
  const traductions = useTraductions();

  if (!promotionData || !promotionData.length) {
    return null;
  }

  return (
    <>
      <SeparationLine />
      {promotionData.map((promotion, index) => (
        <li className={classes.itemContainer} key={index}>
          <ItemElement type={ElementType.Name}>{traductions.orderDetailPage.promotion}</ItemElement>
          <ItemElement type={ElementType.Price}>
            <FormattedPrice value={promotion.amount * -1} currency={currency} />
          </ItemElement>
        </li>
      ))}
    </>
  );
}
