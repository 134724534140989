import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import { rem } from '../app/AppUtilities';
import { useSettings } from '../app/SettingsContext';
import { useTraductions } from '../app/TraductionsContext';
import { AVAILABLE_SOUNDS } from '../sounds/SoundAssets';
import { useSoundPlayer } from '../sounds/useSoundPlayer';
import { getSoundPathById } from '../sounds/SoundUtilities';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: rem(theme.spacing(1)),
      minWidth: 120,
      width: rem(theme.generalStyles.settingsInputContainerWidth),
      marginRight: 'auto',
      marginLeft: 'auto',
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
    },
    select: {
      fontSize: rem(theme.generalStyles.baseTextSize + 4),
    },
    selectEmpty: {
      marginTop: rem(theme.spacing(2)),
    },
    label: {
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
  })
);

const selectionId = 'sound-selection';
const selectionName = 'sound';

export default function SoundSelection() {
  const classes = useStyles();
  const traductions = useTraductions();
  const settings = useSettings();
  const startingId = settings.alertProfile.id;
  const [selectedSound, setSelectedSound] = useState({
    name: selectionName,
    id: startingId,
  });
  const soundPlayer = useSoundPlayer();

  const handleChange = (event: SelectChangeEvent<string>) => {
    soundPlayer.stopSound();
    setSelectedSound((oldSound) => ({
      ...oldSound,
      id: event.target.value as string,
    }));

    const soundId = event.target.value as string;
    settings.setAlertProfile(settings.alertProfile.alertsOn, settings.alertProfile.soundOn, soundId);
    soundPlayer.playOnce(soundId);
  };

  if (!settings.alertProfile.alertsOn || !settings.alertProfile.soundOn) {
    return null;
  }

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor={selectionId} className={classes.label} variant="standard">
        {traductions.settingsPage.sound}
      </InputLabel>
      <Select
        className={classes.select}
        value={selectedSound.id}
        onChange={handleChange}
        inputProps={{
          name: selectionName,
          id: selectionId,
          'data-testid': 'settings-sound-selection-input',
        }}
        variant="standard"
        data-testid="settings-sound-selection-dropdown"
      >
        {AVAILABLE_SOUNDS.map((sound) => (
          <MenuItem
            className={classes.select}
            key={sound.id}
            value={sound.id}
            data-testid={`settings-sound-selection-option-${sound.id}`}
          >
            {sound.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
