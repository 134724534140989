import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './app/App';
import ErrorBoundary from './app/ErrorBoundary';

// Wait for the UEAT version to be set
setTimeout(() => {
  ReactDOM.render(
    <ErrorBoundary>
      <App />
    </ErrorBoundary>,
    document.getElementById('root')
  );
}, 100);
