import { Settings } from '../app/SettingsContext';
import { AVAILABLE_SOUNDS } from './SoundAssets';
import { canPlayNativeSound } from '../app/features';
import { sendNativeMessage } from '../useNativeEvent';
import { getImageUrl } from '../utils/env';

export function getSoundPath(settings: Settings): string {
  return getSoundPathById(settings.alertProfile.id);
}

export function getSoundPathById(soundId: string): string {
  const currentSound = AVAILABLE_SOUNDS.find((sound) => sound.id === soundId);

  if (currentSound) {
    return `${getImageUrl()}/${currentSound.path}`;
  } else {
    return '';
  }
}

export function createSoundPlayer(currentSoundPath: string, loop: boolean = true): HTMLAudioElement {
  const soundPlayer = new Audio(currentSoundPath);
  soundPlayer.pause();
  soundPlayer.loop = loop;

  return soundPlayer;
}

let playPromise: Promise<void>;

export function playAlertSound(soundPlayer: HTMLAudioElement, isOn: boolean, loop: boolean = true) {
  if (isOn && soundPlayer.paused) {
    if (canPlayNativeSound()) {
      sendNativeMessage('playSound', { src: soundPlayer.src, loop });
    } else {
      try {
        playPromise = soundPlayer.play();
      } catch {
        // Make sure playing the sound does not crash the app
      }
    }
  }
}

export function stopAlertSound(soundPlayer: HTMLAudioElement) {
  if (canPlayNativeSound()) {
    sendNativeMessage('stopSound');
  } else {
    if (!soundPlayer.paused) {
      if (playPromise) {
        playPromise.then(() => {
          soundPlayer.pause();
        });
      } else {
        soundPlayer.pause();
      }
    }
  }
}
