import { ListItem, ListItemButton, ListItemText } from '@mui/material';
import { DefaultTheme } from '@mui/material/styles/createTheme';
import { createStyles, makeStyles } from '@mui/styles';
import { Selectable, SelectableElement, WithTestId } from '@ueat/poke';
import classNames from 'classnames';

import { rem } from '../../AppUtilities';

export interface SelectableTileListItemElement extends Selectable, SelectableElement {}

export interface SelectableTileListItemData {
  element: SelectableTileListItemElement;
}

export interface SelectableTileListItemProps extends WithTestId {
  data: SelectableTileListItemData;
  onSelect: (value: string) => void;
}

const useStyles = makeStyles((theme: DefaultTheme) =>
  createStyles({
    root: {
      padding: 0,
    },
    button: {
      background: theme.palette.background.paper,
      borderRadius: rem(8),
      height: rem(100),
      padding: rem(theme.spacing(2)),
      '&:hover': {
        background: theme.palette.background.paper,
        boxShadow: theme.generalStyles.buttonHoverBoxShadow,
        filter: theme.generalStyles.buttonHoverFilter,
      },
      '&:active': {
        background: theme.pauseComponent.button.background.secondary,
      },
      '& .MuiListItemText-primary': {
        color: theme.palette.text.default,
        fontWeight: '700',
        fontSize: rem(theme.generalStyles.largeTextSize),
      },

      '&.Mui-selected': {
        background: theme.palette.primary.main,

        '& .MuiListItemText-primary': {
          color: theme.palette.background.paper,
        },
        '&:hover': {
          background: theme.palette.primary.main,
          boxShadow: 'none',
          filter: 'none',
        },

        '&:active': {
          background: theme.pauseComponent.button.background.tertiary,
        },
      },
    },
    itemText: {
      display: 'inline-flex',
      justifyContent: 'center',
    },
  })
);

export const SelectableTileListItem = ({ data, onSelect, testId }: SelectableTileListItemProps) => {
  const styles = useStyles();

  const handleOnSelect = () => {
    onSelect(data.element.value);
  };

  return (
    <ListItem disablePadding className={classNames(styles.root)}>
      <ListItemButton
        selected={data.element.selected}
        className={styles.button}
        onClick={handleOnSelect}
        data-testid={testId ? `${testId}-button-${data.element.value}` : undefined}
      >
        <ListItemText className={classNames(styles.itemText)} primary={data.element.label} />
      </ListItemButton>
    </ListItem>
  );
};
