import { useQuery } from '@apollo/client';
import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { RouteComponentProps } from 'react-router';
import { rem } from '../app/AppUtilities';
import ErrorMessage from '../app/ErrorMessage';
import LoadingMessage from '../app/LoadingMessage';
import { useSettings } from '../app/SettingsContext';
import { SectionShortName } from '../appMenu/SectionsInfo';
import { ORDER_DETAIL_QUERY } from '../queries/queries';
import AdditionnalInfo from './AdditionnalInfo';
import OrderBackButton from './OrderBackButton';
import OrderBill from './OrderBill';
import { Order, Status } from './OrderDetailTypes';
import ProcessButton from './ProcessButton';
import { emitCustomEvent } from 'react-custom-events';
import { EventTypes } from '../events';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageContainer: {
      padding: rem(theme.spacing(3)),
      paddingTop: rem(theme.generalStyles.appBarPadding),
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        paddingTop: rem(theme.generalStyles.appBarPadding - 5),
      },
    },
    contentContainer: {
      width: '100%',
      maxHeight: 'calc(100% - 70px)',
      flexBasis: 'auto',
      flexGrow: 1,
      display: 'flex',
      '& p': {
        margin: 0,
      },
      '& ul': {
        display: 'flex',
        flexDirection: 'column',
        listStyle: 'none',
      },
    },
    bottomNavigation: {
      marginTop: rem(theme.spacing(3)),
      flexBasis: rem(70),
      display: 'flex',
      border: `${rem(1)} solid #DDDDDD`,
      borderRadius: rem(theme.generalStyles.borderRadius),
    },
  })
);

interface RouteParams {
  id: string;
}

interface Props extends RouteComponentProps<RouteParams> {}

const ORDER_NOT_FOUND_MESSAGE = 'ORDER_NOT_FOUND';

export default function OrderDetailPage({ match, history }: Props) {
  const settings = useSettings();
  const classes = useStyles();
  const id: number = parseInt(match.params.id);
  const missingBranchData = !settings.currentBranchId || !settings.currentRestaurantId;
  const { data, loading, error } = useQuery<{ order: Order }>(ORDER_DETAIL_QUERY, {
    variables: { id, branchId: settings.currentBranchId, restaurantId: settings.currentRestaurantId },
    fetchPolicy: 'no-cache',
    skip: missingBranchData,
  });

  if (missingBranchData) {
    return null;
  }

  if (loading) {
    return <LoadingMessage />;
  }

  if (error) {
    const notFoundError = error.graphQLErrors?.find((error) => error.extensions?.code === ORDER_NOT_FOUND_MESSAGE);
    if (notFoundError) {
      history.replace('/orders/pending');
      return null;
    }

    const stringError = JSON.stringify(error, ['message', 'arguments', 'type', 'name']);
    emitCustomEvent(EventTypes.LOG, {
      message: `Error fetching order: ${stringError}`,
      error: stringError,
    });
    return <ErrorMessage error={error} />;
  }

  if (data && data.order) {
    return (
      <div className={classes.pageContainer}>
        <div className={classes.contentContainer}>
          <AdditionnalInfo orderData={data.order} />
          <OrderBill orderData={data.order} />
        </div>
        <nav className={classes.bottomNavigation}>
          <OrderBackButton to={SectionShortName.NewOrders} />
          <ProcessButton orderId={data.order.id} disabled={isAlreadyProcessed(data.order.status)} />
        </nav>
      </div>
    );
  }

  return null;
}

function isAlreadyProcessed(status: Status): boolean {
  switch (status) {
    case Status.COMPLETED:
    case Status.VOIDED:
    case Status.REFUNDED:
      return true;

    default:
      return false;
  }
}
