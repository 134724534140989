export interface Data {
  items: {
    hasMore: boolean;
    totalCount: number;
    results: RestaurantMenuItemType[];
  };
}

export interface Variables {
  branchId: number;
  offset: number;
  limit: number;
  search: string;
}

export interface RestaurantMenuItemType {
  id: number;
  adminName: string;
  displayName?: string;
  state: RestaurantMenuItemState;
  isStateReadOnly: boolean;
}

export enum RestaurantMenuItemState {
  Active = 'ACTIVE',
  Temporary = 'TEMPORARY_DISABLED',
  Disabled = 'DISABLED',
}
