import React, { useContext, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useFlag } from '@unleash/proxy-client-react';
import { useAuth } from 'react-oidc-context';
import { emitCustomEvent } from 'react-custom-events';
import { EventTypes } from '../events';

import { Branch, Restaurant } from './RestaurantInfoQueryType';
import { AlertProfile, AVAILABLE_SOUNDS } from '../sounds/SoundAssets';
import { PrintState } from '../settings/SettingsPrint';
import { PrintBrand, PrintConnection } from '../traductions/settingsPage';
import { FEATURE_FLAGS } from '../featureFlags/FEATURE_FLAGS';
import { getMainApiUrl, isPrintPreviewEnabled } from '../utils/env';

export enum Culture {
  frCa = 'fr-CA',
  enCa = 'en-CA',
  enUS = 'en-US',
  enGB = 'en-GB',
}

export enum Currency {
  CAD = 'CAD',
  USD = 'USD',
  GBP = 'GBP',
  EUR = 'EUR',
}

type AuthToken = string;

interface SettingsState {
  mainApiUrl: string;
  culture: Culture;
  authToken?: AuthToken;
  restaurantData?: Restaurant[];
  currentBranchId?: number;
  currentBranchIsPosIntegrated?: boolean;
  currentHeadquarterId?: number;
  currentRestaurantId?: number;
  currentRestaurant?: Restaurant;
  currentBranch?: Branch;
  alertProfile: AlertProfile;
  printState: PrintState;
  userRole?: string;
  printPreviewEnabled: boolean;
  isGlobalAdmin: boolean;
  userName?: string;
}

interface SettingsActions {
  changeCulture(culture: Culture): void;

  setRestaurant(
    currentRestaurantId: number,
    currentBranchId: number,
    restaurantData: Restaurant[],
    branchData: Branch[]
  ): void;

  setAlertProfile(alertsOn: boolean, soundOn: boolean, id: string): void;

  setPrintState(printState: PrintState): void;

  login(authToken?: AuthToken): void;

  loginSilent?(): Promise<unknown>;

  logout(): void;

  setUserRole(userRole: string): void;

  setGlobalAdmin(isGlobalAdmin: boolean): void;
}

export interface Settings extends SettingsState, SettingsActions {}

export const SettingsStateContext = React.createContext<SettingsState>({
  mainApiUrl: '',
  printPreviewEnabled: false,
  culture: Culture.enCa,
  alertProfile: { alertsOn: true, soundOn: true, id: AVAILABLE_SOUNDS[0].id },
  printState: { isAutomatic: false, brand: PrintBrand.None, model: '', connection: PrintConnection.USB },
  isGlobalAdmin: false,
});

const SettingsActionsContext = React.createContext<SettingsActions>({
  changeCulture() {
    throw new Error('Missing SettingsProvider');
  },
  setRestaurant() {
    throw new Error('Missing SettingsProvider');
  },
  setAlertProfile() {
    throw new Error('Missing SettingsProvider');
  },
  setPrintState() {
    throw new Error('Missing SettingsProvider');
  },
  login() {
    throw new Error('Missing SettingsProvider');
  },
  logout() {
    throw new Error('Missing SettingsProvider');
  },
  setUserRole() {
    throw new Error('Missing SettingsProvider');
  },
  setGlobalAdmin(isGlobalAdmin: boolean) {
    throw new Error('Missing SettingsProvider');
  },
});

interface Props {
  culture?: Culture;
  children: React.ReactNode;
}

interface LoadedSettings {
  restaurantData?: Restaurant[];
  currentRestaurant?: Restaurant;
  currentBranch?: Branch;
}

export const getDefaulSavedSettings = (culture?: Culture) => ({
  mainApiUrl: getMainApiUrl(),
  printPreviewEnabled: isPrintPreviewEnabled(),
  culture: culture || getDefaultCulture(),
  alertProfile: { alertsOn: true, soundOn: true, id: AVAILABLE_SOUNDS[0].id },
  printState: { isAutomatic: false, brand: '', model: '' },
});

export function SettingsProvider({ culture, children }: Props) {
  const [storageSettings, setStorageSettings] = useState<SettingsState>(() => {
    const savedSettingsStr = localStorage.getItem('uboard-settings');
    const savedSettings = savedSettingsStr ? JSON.parse(savedSettingsStr) : null;
    const finalSettings = savedSettings || getDefaulSavedSettings(culture);
    finalSettings.printPreviewEnabled = isPrintPreviewEnabled();
    finalSettings.mainApiUrl = getMainApiUrl();
    return finalSettings;
  });

  const [loadedSettings, setLoadedSettings] = useState<LoadedSettings>();

  const actions = useMemo<SettingsActions>(() => {
    const setNewValue = (newSettings: SettingsState) => {
      setStorageSettings(newSettings);
      localStorage.setItem('uboard-settings', JSON.stringify(newSettings));
    };

    return {
      changeCulture(culture: Culture) {
        setNewValue({ ...storageSettings, culture });
      },
      setRestaurant(
        currentRestaurantId: number,
        currentBranchId: number,
        restaurantData: Restaurant[],
        branchData: Branch[]
      ) {
        const currentRestaurant = restaurantData.find((r) => r.id === currentRestaurantId) ?? restaurantData[0];
        const currentBranch = branchData.find((b) => b.id === currentBranchId) ?? branchData[0];
        const isPosIntegrated = currentBranch?.isPosIntegrated;
        const currentHeadquarterId = currentRestaurant?.headquarterId;

        setNewValue({
          ...storageSettings,
          currentRestaurantId: currentRestaurant?.id ?? currentRestaurantId,
          currentBranchId: currentBranch?.id ?? currentBranchId,
          currentHeadquarterId,
          currentBranchIsPosIntegrated: isPosIntegrated,
        });
        setLoadedSettings({ ...loadedSettings, restaurantData, currentRestaurant, currentBranch });
      },
      setAlertProfile(alertsOn: boolean, soundOn: boolean, id: string) {
        setNewValue({ ...storageSettings, alertProfile: { alertsOn, soundOn, id } });
      },
      setPrintState(printState: PrintState) {
        setNewValue({ ...storageSettings, printState });
      },
      login(authToken: AuthToken) {
        setNewValue({ ...storageSettings, authToken });
      },
      logout() {
        setNewValue({
          ...storageSettings,
          authToken: undefined,
        });
      },
      setUserRole(userRole: string) {
        setNewValue({ ...storageSettings, userRole: userRole });
      },
      setGlobalAdmin(isGlobalAdmin: boolean) {
        setNewValue({ ...storageSettings, isGlobalAdmin });
      },
    };
  }, [storageSettings, loadedSettings, setStorageSettings, setLoadedSettings]);

  const isIdentityNewUIEnabled = useFlag(FEATURE_FLAGS.IDENTITY_NEW_UI);
  const auth = useAuth();

  const settings = {
    ...storageSettings,
    ...loadedSettings,
    ...(isIdentityNewUIEnabled && {
      authToken: auth.user?.access_token,
    }),
  };

  const actionsValue = {
    ...actions,
    ...(isIdentityNewUIEnabled && {
      login: () => {
        auth.signinRedirect();
      },
      loginSilent: () => {
        return auth.signinSilent();
      },
      logout: async () => {
        if (!auth.user) return;
        const { state, id_token } = auth.user;
        emitCustomEvent(EventTypes.LOG, {
          message: 'User logged out',
          extras: {
            authToken: auth.user.access_token,
          },
        });
        auth.removeUser();
        auth.signoutRedirect({ state, id_token_hint: id_token });
      },
    }),
  };

  return (
    <SettingsStateContext.Provider value={settings}>
      <SettingsActionsContext.Provider value={actionsValue}>
        <IntlProvider locale={settings.culture}>{children}</IntlProvider>
      </SettingsActionsContext.Provider>
    </SettingsStateContext.Provider>
  );
}

export function useSettings(): Settings {
  const settings = useContext(SettingsStateContext);
  const actions = useContext(SettingsActionsContext);

  return useMemo<Settings>(
    () => ({
      ...settings,
      ...actions,
    }),
    [settings, actions]
  );
}

export function useIsPosIntegrated(): boolean | undefined {
  const settings = useSettings();

  return settings.currentBranchIsPosIntegrated;
}

function getDefaultCulture(): Culture {
  if (navigator.language.indexOf('fr') === 0) {
    return Culture.frCa;
  }

  return Culture.enCa;
}
