export interface LoginPageType {
  title: string;
  placeholderEmail: string;
  placeholderPW: string;
  btnSubmit: string;
  error400: string;
  errorU: string;
}

export const enCa: LoginPageType = {
  title: 'Please log in',
  placeholderEmail: 'Email address',
  placeholderPW: 'Password',
  btnSubmit: 'Log in',
  error400: 'Wrong email/password combination',
  errorU: 'Unknown error, please retry',
};

export const frCa: LoginPageType = {
  title: 'Connectez-vous',
  placeholderEmail: 'Adresse courriel',
  placeholderPW: 'Mot de passe',
  btnSubmit: 'Se connecter',
  error400: 'Mauvaise combinaison courriel / mot de passe',
  errorU: 'Erreur inconnue, recommencez',
};
