import { useTraductions } from '../app/TraductionsContext';
import { Order } from './OrderDetailTypes';
import Subsection from './Subsection';
import SubTitle from './SubTitle';

interface Props {
  orderData: Order;
}

export function Address({ orderData }: Props) {
  const traductions = useTraductions();

  if (!orderData.customer.deliveryAddress) {
    return (
      <Subsection
        title={traductions.orderDetailPage.phone}
        text={orderData.customer.phoneNumber}
        testId="order-detail-phone-number"
      />
    );
  }

  const { civicNumber, streetName, apartment, city, province, postalCode, country } =
    orderData.customer.deliveryAddress;
  const { phoneNumber } = orderData.customer;

  return (
    <div>
      <SubTitle>{traductions.orderDetailPage.address}</SubTitle>
      <p>{`${civicNumber} ${streetName} ${apartment ? `#${apartment}` : ''}`}</p>
      <p>{`${city} (${province})`}</p>
      <p>{`${postalCode} ${country}`}</p>
      <p data-testid="order-detail-phone-number">{phoneNumber}</p>
    </div>
  );
}
