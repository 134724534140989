import { useEffect } from 'react';

export default function useNativeEvent<TData>(eventName: string, callback: (data: TData) => void) {
  useEffect(() => {
    const onMessage = (e: any) => {
      if (e.data.eventName === eventName) {
        callback(e.data.data);
      }
    };

    window.addEventListener('message', onMessage);
    return () => window.removeEventListener('message', onMessage);
  }, [eventName, callback]);
}

export function sendNativeMessage(eventName: string, data?: any) {
  const json = JSON.stringify({
    eventName,
    data,
  });

  if ((window as any).ReactNativeWebView) {
    (window as any).ReactNativeWebView.postMessage(json);
  } else {
    window.postMessage(json, '*');
  }
}
