import React from 'react';
import { Order } from './OrderDetailTypes';
import FormattedPrice from '../formatted/FormattedPrice';
import { useTraductions } from '../app/TraductionsContext';
import SeparationLine from './SeparationLine';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import ItemElement, { ElementType } from './ItemElement';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flexShrink: 0,
      flexBasis: 'auto',
    },
  })
);

interface Props {
  orderData: Order;
  currency: string;
}

export default function TipAndTotals({ orderData, currency }: Props) {
  const classes = useStyles();
  const traductions = useTraductions();

  if (orderData.totals) {
    return (
      <>
        <SeparationLine />
        {!!orderData.totals.tip && (
          <li className={classes.itemContainer}>
            <ItemElement type={ElementType.Name}>{traductions.orderDetailPage.tip}</ItemElement>
            <ItemElement type={ElementType.Price}>
              <FormattedPrice value={orderData.totals.tip} currency={currency} />
            </ItemElement>
          </li>
        )}
        <li className={classes.itemContainer}>
          <ItemElement type={ElementType.Name}>{traductions.orderDetailPage.grandTotal}</ItemElement>
          <ItemElement type={ElementType.Price}>
            <FormattedPrice value={orderData.totals.total} currency={currency} />
          </ItemElement>
        </li>
      </>
    );
  }

  return null;
}
