import React from 'react';
import { Theme, Typography, FormControlLabel, SelectChangeEvent, Switch } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useTraductions } from '../app/TraductionsContext';
import classNames from 'classnames';
import { useSettings } from '../app/SettingsContext';
import { rem } from '../app/AppUtilities';
import { canPrint, canPrintWith } from '../app/features';
import SettingsSelect from './SettingsSelect';
import SettingsInput from './SettingsInput';
import { PrintBrand, PrintConnection } from '../traductions/settingsPage';
import { isIOS } from 'react-device-detect';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      marginBottom: rem(theme.spacing(4)),
    },
    inputBase: {
      width: rem(theme.generalStyles.settingsInputContainerWidth),
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
    },
    switch: {
      marginBottom: rem(theme.spacing(2)),
      marginRight: 0,
      position: 'relative',
      right: rem(theme.spacing(1)),
    },
    switchLabel: {
      paddingLeft: rem(theme.spacing(1)),
      fontSize: rem(theme.generalStyles.baseTextSize + 4),
    },
    title: {
      marginBottom: rem(theme.spacing(2)),
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
  })
);

export interface PrintState {
  isAutomatic: boolean;
  brand: PrintBrand;
  model: string;
  connection: PrintConnection;
  ipAddress?: string;
  serialNumber?: string;
}

export default function SettingsPrint() {
  const settings = useSettings();
  const classes = useStyles();
  const traductions = useTraductions();
  const [printState, setPrintState] = React.useState<PrintState>({ ...settings.printState });

  const handleIsAutomaticSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPrintState = { ...printState, isAutomatic: event.target.checked };
    setPrintState(newPrintState);
    settings.setPrintState({ ...printState, isAutomatic: event.target.checked });
  };

  const handleChange = (event: SelectChangeEvent<string> | React.ChangeEvent<{ name: string; value: unknown }>) => {
    const inputName = event.target.name ? event.target.name : 'undefined';
    const newPrintState = { ...printState, [inputName]: event.target.value as string };
    setPrintState(newPrintState);
    settings.setPrintState(newPrintState);
  };

  if (!canPrint() && !settings.printPreviewEnabled) {
    return null;
  }

  const brandList = traductions.settingsPage.printBrandList.filter((brand) => canPrintWith(brand.value));

  return (
    <div className={classes.container}>
      <Typography
        variant="h4"
        classes={{
          root: classes.title,
        }}
        align="center"
      >
        {traductions.settingsPage.printTitle}
      </Typography>
      <FormControlLabel
        classes={{
          root: classNames(classes.inputBase, classes.switch),
          label: classes.switchLabel,
        }}
        control={
          <Switch
            checked={printState.isAutomatic}
            onChange={handleIsAutomaticSwitch}
            color="primary"
            size="medium"
            value="isAutomatic"
          />
        }
        label={traductions.settingsPage.automaticPrint}
      />
      <SettingsSelect
        id="print-brand"
        value={printState.brand || PrintBrand.None}
        label={traductions.settingsPage.printBrand}
        options={brandList}
        onChange={handleChange}
        name={'brand'}
      />
      {printState.brand === PrintBrand.Epson && (
        <SettingsSelect
          id="print-model"
          value={printState.model}
          label={traductions.settingsPage.printModel}
          options={traductions.settingsPage.epsonPrintModelList}
          onChange={handleChange}
          name={'model'}
        />
      )}
      {printState.brand === PrintBrand.StarMicronics && (
        <SettingsSelect
          id="print-model"
          value={printState.model}
          label={traductions.settingsPage.printModel}
          options={traductions.settingsPage.starPrintModelList}
          onChange={handleChange}
          name={'model'}
        />
      )}
      {printState.brand === PrintBrand.HPRT && (
        <SettingsSelect
          id="print-model"
          value={printState.model}
          label={traductions.settingsPage.printModel}
          options={traductions.settingsPage.hprtPrintModelList}
          onChange={handleChange}
          name={'model'}
        />
      )}
      <SettingsSelect
        id="print-connection"
        value={printState.connection}
        label={traductions.settingsPage.printConnection}
        options={traductions.settingsPage.printConnectionList}
        onChange={handleChange}
        name={'connection'}
      />
      {settings.printState.connection === PrintConnection.Network && (
        <SettingsInput
          id="print-ipaddress"
          value={printState.ipAddress || ''}
          label={traductions.settingsPage.printIpAddress}
          onChange={handleChange}
          name={'ipAddress'}
        />
      )}
      {settings.printState.connection === PrintConnection.USB &&
        printState.brand === PrintBrand.StarMicronics &&
        isIOS && (
          <SettingsInput
            id="print-serialNumber"
            value={printState.serialNumber || ''}
            label={traductions.settingsPage.serialNumber}
            onChange={handleChange}
            name={'serialNumber'}
          />
        )}
    </div>
  );
}
