import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Avatar, Theme } from '@mui/material';
import FeedbackIcon from '@mui/icons-material/FeedbackOutlined';
import { rem } from '../app/AppUtilities';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      paddingLeft: rem(30),
      paddingRight: rem(74),
      marginBottom: rem(theme.spacing(1)),
    },
    noteContainer: {
      display: 'flex',
      alignItems: 'center',
      background: '#F6F8FB',
      borderRadius: rem(8),
      padding: rem(theme.generalStyles.containerPadding),
    },
    noteIconBackground: {
      background: 'rgba(37, 130, 251, 0.08)',
      marginRight: rem(theme.spacing(1)),
      width: rem(32),
      height: rem(32),
    },
    noteIcon: {
      color: '#035FD6',
      width: rem(18),
      height: rem(18),
    },
    note: {
      fontSize: rem(theme.generalStyles.smallerTextSize + 1),
      lineHeight: 1.5,
      fontStyle: 'italic',
      fontWeight: 400,
    },
  })
);

interface Props {
  note: string | null;
}

export default function ItemNote({ note }: Props) {
  const classes = useStyles();

  if (!note) {
    return null;
  }

  return (
    <div className={classes.container}>
      <div className={classes.noteContainer}>
        <Avatar className={classes.noteIconBackground}>
          <FeedbackIcon className={classes.noteIcon} />
        </Avatar>
        <div className={classes.note}>{note}</div>
      </div>
    </div>
  );
}
