import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import Fab from '@mui/material/Fab';
import { CircularProgress, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles';
import PrintIcon from '@mui/icons-material/Print';
import { rem } from '../app/AppUtilities';
import { isPrinterEnabled } from '../app/features';
import { useSettings } from '../app/SettingsContext';
import generateReceipt from '../print/generateReceipt';
import print from '../print/print';
import { Order } from './OrderDetailTypes';
import useNativeEvent from '../useNativeEvent';
import PrintPreviewModal from '../print/PrintPreviewModal';
import { ValidationError } from '../types/errors';
import { emitCustomEvent } from 'react-custom-events';
import { EventTypes } from '../events';
import { LogLevel } from '../events/log/LogLevels';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      height: rem(75),
      width: rem(75),
      margin: rem(theme.spacing(1)),
      position: 'absolute',
      bottom: rem(theme.spacing(3)),
      left: '50%',
      transform: 'translate(-50%)',
    },
    icon: {
      height: rem(theme.generalStyles.mediumIconSize),
      width: rem(theme.generalStyles.mediumIconSize),
    },
  })
);

interface Props {
  order: Order;
}

export default function ManualPrintButton({ order }: Props) {
  const settings = useSettings();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState('');
  const [isPrintPreviewModalOpen, setIsPrintPreviewModalOpen] = useState(false);

  function togglePrintPreviewModal() {
    setIsPrintPreviewModalOpen(!isPrintPreviewModalOpen);
  }

  useNativeEvent('print-succeed', () => {
    setLoading(false);
  });

  useNativeEvent<Error>('print-failed', (e) => {
    setLoading(false);
    enqueueSnackbar({ message: e.message, variant: 'error' });
  });

  const handleClick = async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    try {
      const receiptData = await generateReceipt(settings, order);
      if (settings.printPreviewEnabled) {
        setLoading(false);
        setImage('data:image/png;base64,' + receiptData);
        togglePrintPreviewModal();
      } else {
        print(settings, order.id, receiptData);
      }
    } catch (e) {
      setLoading(false);
      if (e instanceof ValidationError) {
        return;
      }

      const typedError = e as Error;
      enqueueSnackbar({
        message: typedError?.message,
        variant: 'error',
      });
    }
  };

  if (!isPrinterEnabled(settings) && !settings.printPreviewEnabled) {
    return null;
  }

  return (
    <>
      <Fab color="primary" aria-label="print" className={classes.button} onClick={handleClick}>
        {loading && <CircularProgress style={{ color: '#fff' }} />}
        {!loading && <PrintIcon className={classes.icon} />}
      </Fab>

      {isPrintPreviewModalOpen && (
        <PrintPreviewModal onClose={togglePrintPreviewModal} open={isPrintPreviewModalOpen} image={image} />
      )}
    </>
  );
}
