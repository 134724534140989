import React from 'react';

import { useTraductions } from './TraductionsContext';
import { useQueryMessageStyle } from './RestaurantManager';

export default function LoadingMessage() {
  const traductions = useTraductions();
  const queryMessageClass = useQueryMessageStyle();

  return <p className={queryMessageClass.message}>{traductions.orderDetailPage.loading}</p>;
}
