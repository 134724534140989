import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import UpdateIcon from '@mui/icons-material/Update';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { rem } from '../app/AppUtilities';
import { useIsPosIntegrated } from '../app/SettingsContext';
import { useTraductions } from '../app/TraductionsContext';

interface Props extends RouteComponentProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: rem(theme.generalStyles.ordersBottomNavigationHeight),
      position: 'fixed',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: 10,
      boxShadow: `0px ${rem(2)} ${rem(4)} ${rem(-1)} rgba(0,0,0,0.2), 0px ${rem(4)} ${rem(
        5
      )} 0px rgba(0,0,0,0.14), 0px ${rem(1)} ${rem(10)} 0px rgba(0,0,0,0.12)`,
    },
    icon: {
      height: rem(theme.generalStyles.mediumIconSize),
      width: rem(theme.generalStyles.mediumIconSize),
    },
    label: {
      fontSize: `${rem(theme.generalStyles.baseTextSize)} !important`,
    },
  })
);

function OrdersBottomNavigation({ location }: Props) {
  const traductions = useTraductions();
  const classes = useStyles();
  const hideNewOrdersTab = useIsPosIntegrated();

  return (
    <BottomNavigation value={getActiveNav(location.pathname)} showLabels className={classes.root}>
      {!hideNewOrdersTab && (
        <BottomNavigationAction
          classes={{ label: classes.label }}
          component={Link}
          to={'/orders/pending'}
          label={traductions.bottomNav.new}
          icon={<NewReleasesIcon className={classes.icon} />}
          data-testid="orders-pending-link"
        />
      )}

      <BottomNavigationAction
        classes={{ label: classes.label }}
        component={Link}
        to={'/orders/preorders'}
        label={traductions.bottomNav.preorders}
        icon={<UpdateIcon className={classes.icon} />}
        data-testid="orders-preorders-link"
      />
      <BottomNavigationAction
        classes={{ label: classes.label }}
        component={Link}
        to={'/orders/processed'}
        label={traductions.bottomNav.processed}
        icon={<CheckCircleIcon className={classes.icon} />}
        data-testid="orders-processed-link"
      />
    </BottomNavigation>
  );
}

function getActiveNav(pathname: string) {
  switch (pathname) {
    case '/orders/preorders':
      return 1;
    case '/orders/processed':
      return 2;
    default:
      return 0;
  }
}

export default withRouter(OrdersBottomNavigation);
