export interface ThirdPartyDeliveryPartnerPageType {
  pageTitle: string;
  emptyList: string;
  legend: string;
  on: string;
  off: string;
  loading: string;
  error: string;
  mutationError: string;
}

export const enCa: ThirdPartyDeliveryPartnerPageType = {
  pageTitle: 'Third-party delivery partners',
  emptyList: 'no corresponding third party delivery partners found',
  legend: 'partner states',
  on: 'active',
  off: 'inactive',
  loading: 'loading third party delivery partners...',
  error: 'an error has occured while loading third party delivery partners.',
  mutationError: 'could not change third party delivery partner state.',
};

export const frCa: ThirdPartyDeliveryPartnerPageType = {
  pageTitle: 'Partenaires de livraison tiers',
  emptyList: "aucun partenaire de livraison tiers correspondant n'a été trouvé",
  legend: 'états partenaires',
  on: 'actif',
  off: 'inactif',
  loading: 'chargement de partenaires de livraison tiers...',
  error: "une erreur s'est produite lors du chargement des partenaires de livraison tiers.",
  mutationError: "impossible de modifier l'état du partenaire de livraison tiers.",
};
