import React from 'react';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { rem } from '../app/AppUtilities';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    emptyListMessage: {
      fontSize: rem(theme.generalStyles.mediumTextSize),
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
  })
);

interface Props {
  message: string;
}

export default function EmptyListMessage({ message }: Props) {
  const classes = useStyles();

  return <p className={classes.emptyListMessage}>{message}</p>;
}
