import { ThirdPartyOrderIntegration } from '../src/types/ThirdPartyOrderIntegrationTypes';
import { Branch, Restaurant } from '../src/app/RestaurantInfoQueryType';

export const currentBranch: Branch = {
  id: 236,
  name: '{ "fr": "1ere Avenue" }',
  currency: 'CAD',
  timezone: 'America/New_York',
  hasManagerCode: false,
  isPosIntegrated: false,
  thirdPartyOrderIntegrations: Object.values(ThirdPartyOrderIntegration).filter(
    (channel) => channel != ThirdPartyOrderIntegration.UNKNOWN && channel != ThirdPartyOrderIntegration.WEB
  ),
  services: [
    {
      type: 'TAKEOUT',
      name: 'Takeout',
      hasExternalDeliveryProvider: false,
    },
    {
      type: 'TABLE',
      name: 'At the table',
      hasExternalDeliveryProvider: false,
    },
  ],
};

const currentRestaurant: Restaurant = {
  id: 132,
  headquarterId: 321,
  name: 'Yuzu sushi',
};

export const PreviewMock = {
  currentRestaurant,
  currentBranch,
};
