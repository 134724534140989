import { WithTestId } from '@ueat/poke';
import classNames from 'classnames';
import React from 'react';
import { Culture, useSettings } from '../app/SettingsContext';

interface Props extends React.HTMLAttributes<HTMLParagraphElement>, WithTestId {
  serviceName: string;
}

export default function FormattedService({ className, serviceName, testId }: Props) {
  const settings = useSettings();
  const frenchCulture: Culture = Culture.frCa;
  const unwantedString = 'Service ';

  let serviceNameToShow = serviceName;

  if (settings.culture === frenchCulture && serviceName.search(unwantedString) !== -1) {
    serviceNameToShow = serviceName.replace(unwantedString, '');
  }

  return (
    <p className={classNames(className, 'type')} data-testid={testId}>
      {serviceNameToShow}
    </p>
  );
}
