export interface EventModalType {
  title: string;
  close: string;
  closeAll: string;
  show: string;
  readyOn: string;
  loading: string;
  error: string;
}

export const enCa: EventModalType = {
  title: 'new order received',
  close: 'close',
  closeAll: 'close all',
  show: 'show details',
  readyOn: 'pickup at',
  loading: 'loading order...',
  error: 'error while loading order details',
};

export const frCa: EventModalType = {
  title: 'nouvelle commande reçue',
  close: 'fermer',
  closeAll: 'tout fermer',
  show: 'voir les détails',
  readyOn: 'ramasser le',
  loading: 'chargement de la commande...',
  error: "les détails de la commande n'ont pu être chargés",
};
