import { Channel } from '@ueat/poke';
import { Status } from '../orderDetail/OrderDetailTypes';
import { OrderChannelEnum } from '../pauseChannel/types/PauseTypes';

const ThirdPartyOrderChannels = [
  OrderChannelEnum.DOOR_DASH,
  OrderChannelEnum.SKIP_THE_DISHES,
  OrderChannelEnum.UBER_EATS,
  OrderChannelEnum.GOOGLE_FOOD_ORDERING,
];

export const shouldDisableCancelOrRefundButton = (orderStatus: Status, orderChannel: OrderChannelEnum) => {
  if (orderChannel === OrderChannelEnum.DOOR_DASH || orderChannel === OrderChannelEnum.SKIP_THE_DISHES) {
    return true;
  }

  return orderStatus === Status.REFUNDED || orderStatus === Status.VOIDED;
};

export const isThirdPartyOrderIntegrationCancellation = (orderChannel: OrderChannelEnum) => {
  return [OrderChannelEnum.DOOR_DASH, OrderChannelEnum.SKIP_THE_DISHES, OrderChannelEnum.UBER_EATS].includes(
    orderChannel
  );
};

export const isThirdPartyOrderCancellationSupported = (orderChannel: OrderChannelEnum) => {
  return orderChannel === OrderChannelEnum.UBER_EATS;
};

export const hasExternalOrderNumber = (
  orderChannel: OrderChannelEnum,
  thirdPartyOrderExternalReference: string | null
) => {
  return ThirdPartyOrderChannels.includes(orderChannel) && !!thirdPartyOrderExternalReference;
};

export const mapToChannel = (thirdPartyOrderIntegration?: string | null) => {
  return (thirdPartyOrderIntegration || '').replace(/[-_]/g, '').toLowerCase() as Channel;
};
