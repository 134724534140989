import React from 'react';
import { emitCustomEvent } from 'react-custom-events';
import { EventTypes } from '../../events';
import { LogEventsWrapper } from '../../events/log';

interface Props {
  children: React.ReactNode;
}

export class LoggingErrorBoundary extends React.Component<Props> {
  componentDidCatch(error: Error) {
    emitCustomEvent(EventTypes.LOG, { message: error.message, error });
    throw error; // allow global error boundary to catch the error
  }

  render() {
    return <LogEventsWrapper>{this.props.children}</LogEventsWrapper>;
  }
}
