import React, { useEffect, useRef, useState, useContext } from 'react';
import { useSettings, Settings } from './SettingsContext';
import { ModalCloseButtonVariant } from '../orderModal/EventModal';
import { emitCustomEvent } from 'react-custom-events';
import { EventTypes } from '../events';
import { getWebSocketURL } from '../utils/env';

enum Action {
  NewOrder = 'NewOrderAction',
  OrderChanged = 'OrderChangedAction',
  Authorized = 'Authorized',
}

interface Message {
  Action: string;
  OrderId: number;
  OrderNumber: number;
  SessionId: string;
}

export interface Alerts {
  list: Message[];
  removeAlerts: (howMany: ModalCloseButtonVariant) => void;
}

const alertList: Message[] = [];
const AlertContext = React.createContext<Alerts>({
  list: alertList,
  removeAlerts: (howMany) => {},
});

export function useAlerts(): [Message[], (howMany: ModalCloseButtonVariant) => void] {
  const alerts = useContext(AlertContext);

  return [alerts.list, alerts.removeAlerts];
}

interface Props {
  children: React.ReactElement;
}

export default function WebSocketClient({ children }: Props) {
  const settings = useSettings();
  const webSocket = useRef<WSConnection | null>(null);

  const [alerts, setAlerts] = useState<Alerts>({
    list: alertList,
    removeAlerts: (howMany: ModalCloseButtonVariant) => {
      setAlerts((state) => {
        if (howMany === ModalCloseButtonVariant.Single) {
          const newAlertsList = [...state.list];
          const message = newAlertsList.pop();
          if (message && webSocket.current) {
            webSocket.current.addAlertManaged(message.OrderId);
            webSocket.current.saveAlertManaged();
          }

          return {
            ...state,
            list: newAlertsList,
          };
        } else {
          state.list.forEach((message) => {
            if (webSocket.current) {
              webSocket.current.addAlertManaged(message.OrderId);
              webSocket.current.saveAlertManaged();
            }
          });

          const newAlertsList: Message[] = [];
          return {
            ...state,
            list: newAlertsList,
          };
        }
      });
    },
  });

  useEffect(() => {
    if (settings.currentBranchId && settings.authToken) {
      setAlerts((state) => {
        const newAlertsList: Message[] = [];
        return {
          ...state,
          list: newAlertsList,
        };
      });
      webSocket.current = new WSConnection(settings, setAlerts);
    }
    return () => {
      webSocket.current && webSocket.current.disconnect();
    };
  }, [settings.currentBranchId, settings.authToken]); //eslint-disable-line react-hooks/exhaustive-deps

  return <AlertContext.Provider value={alerts}>{children}</AlertContext.Provider>;
}

const RETRY_INTERVAL = 3000;
const PING_INTERVAL = 15000;

const MANAGED_ORDER_IDS = 'managed-order-ids';
const MAXIMUM_SAVED_MANAGED_ORDER_IDS = 1000;

class WSConnection {
  private url: string;
  private settings: Settings;
  private setAlerts: React.Dispatch<React.SetStateAction<Alerts>>;
  private isUserDisconnect: boolean = false;
  private pingsInterval?: NodeJS.Timeout;
  private reconnectTimeout?: NodeJS.Timeout;

  private isConnectionLost: boolean = false;

  private managedOrderIds: number[] = [];

  private webSocket?: WebSocket;

  constructor(settings: Settings, setAlerts: React.Dispatch<React.SetStateAction<Alerts>>) {
    this.url = `${getWebSocketURL()}/ManagerSocket/${settings.currentBranchId}`;
    this.settings = settings;
    this.setAlerts = setAlerts;

    this.loadAlertManaged();
    this.connect();
  }

  loadAlertManaged() {
    const managedOrderIds = window.localStorage.getItem(MANAGED_ORDER_IDS);
    if (managedOrderIds) {
      this.managedOrderIds = JSON.parse(managedOrderIds);
    }
  }

  isAlertManaged(id: number) {
    return this.managedOrderIds.indexOf(id) >= 0;
  }

  addAlertManaged(id: number) {
    this.managedOrderIds.push(id);
  }

  saveAlertManaged() {
    if (this.managedOrderIds.length > MAXIMUM_SAVED_MANAGED_ORDER_IDS) {
      this.managedOrderIds = this.managedOrderIds.slice(this.managedOrderIds.length - MAXIMUM_SAVED_MANAGED_ORDER_IDS);
    }

    window.localStorage.setItem(MANAGED_ORDER_IDS, JSON.stringify(this.managedOrderIds));
  }

  pushAlert(message: Message) {
    if (this.isAlertManaged(message.OrderId)) {
      return;
    }

    this.setAlerts((state) => {
      const currentList = state.list.filter((alert) => alert.OrderId !== message.OrderId);
      const newList = [message, ...currentList];
      return {
        ...state,
        list: newList,
      };
    });
  }

  private onOpen = () => {
    if (!this.settings.authToken) {
      return;
    }

    const authToken = {
      AccessToken: this.settings.authToken,
    };

    if (this.webSocket) {
      this.webSocket.send(JSON.stringify(authToken));
    }

    this.startPings();
  };

  private startPings() {
    this.pingsInterval = setInterval(() => {
      if (this.webSocket) {
        this.webSocket.send(JSON.stringify({}));
      }
    }, PING_INTERVAL);
  }

  private stopPings() {
    if (this.pingsInterval) {
      clearInterval(this.pingsInterval);
    }
  }

  private onUnauthorized = (exception?: string) => {
    emitCustomEvent(EventTypes.LOG, {
      message: 'Websocket.OnClose: Unauthorized',
      extras: {
        hasAuthToken: !!this.settings.authToken,
        exception,
      },
    });
    this.disconnect();
  };

  private onClose = (event: CloseEvent) => {
    this.stopPings();

    if (event.reason === 'unauthorized') {
      if (this.settings.loginSilent) {
        this.settings.loginSilent().catch((e) => this.onUnauthorized(e));
      } else {
        emitCustomEvent(EventTypes.LOG, {
          message: 'No loginSilent defined',
        });
        this.onUnauthorized();
      }
      return;
    }

    if (!this.isUserDisconnect && this.settings.authToken) {
      this.isConnectionLost = true;
      emitCustomEvent(EventTypes.CONNECTION_LOST);
      emitCustomEvent(EventTypes.LOG, {
        message: 'Websocket.OnClose: Unexpected disconnection',
        extras: {
          hasAuthToken: !!this.settings.authToken,
          code: event.code,
          reason: event.reason,
          wasClean: event.wasClean,
        },
      });
      this.reconnectTimeout = setTimeout(() => {
        this.connect();
      }, RETRY_INTERVAL);
    }
  };

  private cancelReconnect() {
    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
    }
  }

  private onError = (e: Event) => {
    if (!this.isConnectionLost) {
      const stringError = JSON.stringify(e, ['message', 'arguments', 'type', 'name']);
      console.log(`WebSocket Error : ${stringError}`);
      emitCustomEvent(EventTypes.LOG, {
        message: `WebSocket Error : ${stringError}`,
        error: stringError,
      });
    }
  };

  private onMessage = (e: MessageEvent) => {
    const message: Message = JSON.parse(e.data);
    switch (message.Action) {
      case Action.NewOrder:
        this.pushAlert(message);
        emitCustomEvent(EventTypes.NEW_ORDER);
        break;
      case Action.OrderChanged:
        this.pushAlert(message);
        emitCustomEvent(EventTypes.ORDER_CHANGED);
        break;
      case Action.Authorized:
        if (this.isConnectionLost) {
          this.isConnectionLost = false;
          emitCustomEvent(EventTypes.CONNECTION_BACK);
        }
        break;
      default:
        throw new Error('No corresponding action implemented for this websocket message');
    }
  };

  connect() {
    this.webSocket = new WebSocket(this.url);
    this.webSocket.onopen = this.onOpen;
    this.webSocket.onclose = this.onClose;
    this.webSocket.onerror = this.onError;
    this.webSocket.onmessage = this.onMessage;
  }

  disconnect() {
    this.cancelReconnect();

    emitCustomEvent(EventTypes.LOG, {
      message: 'Websocket.OnDisconnect',
      extras: {
        hasAuthToken: !!this.settings.authToken,
      },
    });
    this.isUserDisconnect = true;

    if (this.webSocket) {
      this.webSocket.close();
    }
  }
}
