import { List, Typography } from '@mui/material';
import { DefaultTheme } from '@mui/material/styles/createTheme';
import { makeStyles, createStyles } from '@mui/styles';
import { PauseChannelListItem, PauseChannelListItemLabel } from '../pauseChannelListItem';

import { rem } from '../../app/AppUtilities';

const useStyles = makeStyles((theme: DefaultTheme) =>
  createStyles({
    title: {
      fontWeight: '700',
      fontSize: rem(theme.generalStyles.bigTextSize),
      lineHeight: rem(27),
      color: theme.palette.text.default,
      '@media (max-height: 680px)': {
        paddingBottom: rem(10),
      },
    },
    list: {
      padding: '0px',
      '& .MuiListItem-root': {
        '@media (max-height: 680px)': {
          marginTop: rem(10),
        },
        marginTop: rem(20),
      },
    },
  })
);

export interface PauseChannelListLabel {
  title: string;
  pauseChannelListItem: PauseChannelListItemLabel;
}

export interface PauseChannelStatus {
  channel: string;
  channelName: string;
  pausedUntil?: string;
  selected?: boolean;
  pending?: boolean;
}

export interface PauseChannelListData {
  channelStatus: PauseChannelStatus[];
  disabled?: boolean;
}

export interface PauseChannelListProps {
  label: PauseChannelListLabel;
  data: PauseChannelListData;
  onSelected: (channelId: string) => void;
  onTimeDelayExpiry: (channelId: string) => void;
}

export const PauseChannelList = ({ label, data, onSelected, onTimeDelayExpiry }: PauseChannelListProps) => {
  const styles = useStyles();

  if (!data.channelStatus?.length) {
    return null;
  }

  return (
    <>
      <Typography className={styles.title}>{label?.title}</Typography>
      <List className={styles.list}>
        {data.channelStatus.map((item) => (
          <PauseChannelListItem
            key={item.channel}
            label={label.pauseChannelListItem}
            data={{
              channelId: item.channel,
              channelName: item.channelName,
              pausedUntil: item?.pausedUntil,
              selected: item.selected,
              pending: item.pending,
              disabled: data.disabled,
            }}
            onSelected={onSelected}
            onTimeDelayExpiry={onTimeDelayExpiry}
          />
        ))}
      </List>
    </>
  );
};
