import { Box } from '@mui/material';
import { useTraductions } from '../app/TraductionsContext';
import { Drawer, DrawerHeader } from '../components/drawer';
import { Order } from './OrderDetailTypes';

interface Props {
  orderData: Order;
  open: boolean;
  onClose: () => void;
}

export default function OrderDeliveryTrackingDrawer({ orderData, open, onClose }: Props) {
  const traductions = useTraductions();
  const title = traductions.orderDetailPage.trackDeliveryTitle.replace(
    '{{orderNumber}}',
    String(orderData.shortNumber)
  );

  return (
    <Drawer fullscreen open={open} onClose={onClose}>
      <DrawerHeader onClose={onClose}>{title}</DrawerHeader>
      <Box
        component="iframe"
        src={orderData.thirdPartyDeliveryTracking!}
        sx={(theme) => ({ height: '100%', backgroundColor: theme.palette.background.paper })}
        frameBorder={0}
      />
    </Drawer>
  );
}
