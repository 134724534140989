import { Box } from '@mui/material';
import React from 'react';
import { Order } from './OrderDetailTypes';
import { useTraductions } from '../app/TraductionsContext';
import SubTitle from './SubTitle';

interface Props {
  orderData: Order;
}

export default function ServiceQuestions({ orderData }: Props) {
  const traductions = useTraductions();

  if (orderData.serviceQuestions) {
    const answersText = orderData.serviceQuestions
      .sort((answer) => answer.level)
      .map((answer) => {
        if (answer.rangeValue) {
          return (
            (answer.prefixRange ? answer.prefixRange + ' ' : '') +
            answer.rangeValue +
            (answer.suffixRange ? ' ' + answer.suffixRange : '')
          );
        } else {
          return answer.text;
        }
      })
      .join(' - ');

    return (
      <Box>
        <SubTitle>{traductions.orderDetailPage.serviceQuestions}</SubTitle>
        <p>{answersText}</p>
      </Box>
    );
  }

  return null;
}
