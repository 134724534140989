import { Theme } from '@mui/material';
import { amber, blue, green, red } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { CustomContentProps, SnackbarContent } from 'notistack';
import React, { forwardRef } from 'react';
import { rem } from './AppUtilities';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 368,
    minHeight: 48,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    fontWeight: 500,
    fontSize: rem(theme.generalStyles.smallerTextSize),
    lineHeight: rem(20),
    padding: `${rem(theme.spacing(1))} ${rem(theme.spacing(2))}`,
    borderRadius: rem(4),
    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
  },
  default: {
    '--variant-color': '#000',
    backgroundColor: 'var(--variant-color)',
    color: '#fff',
  },
  success: {
    '--variant-color': green[500],
    backgroundColor: 'var(--variant-color)',
    color: '#fff',
  },
  error: {
    '--variant-color': red[700],
    backgroundColor: 'var(--variant-color)',
    color: '#fff',
  },
  warning: {
    '--variant-color': amber[700],
    backgroundColor: 'var(--variant-color)',
    color: '#000',
  },
  info: {
    '--variant-color': blue[500],
    backgroundColor: 'var(--variant-color)',
    color: '#fff',
  },
  icon: {
    flexShrink: 0,
    width: rem(18),
    height: rem(18),
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: rem(theme.spacing(2)),
    backgroundColor: 'currentcolor',
    borderRadius: rem(100),
    '& svg': {
      width: '1em',
      height: '1em',
      fontSize: rem(12),
      color: 'var(--variant-color)',
    },
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: rem(theme.spacing(2)),
    marginRight: rem(theme.spacing(-1)),
  },
}));

export type CustomVariantProps = {
  icon?: React.ReactNode;
};

export type SnackbarProps = CustomContentProps & CustomVariantProps;

export const Snackbar = forwardRef<HTMLDivElement, SnackbarProps>((props, forwardedRef) => {
  const classes = useStyles();
  const {
    id,
    message,
    variant,
    iconVariant,
    hideIconVariant,
    action: componentOrFunctionAction,
    icon: customIcon,
    style,
  } = props;

  const icon = customIcon ?? iconVariant[variant];
  const Icon = !hideIconVariant && icon ? <span className={classes.icon}>{icon}</span> : null;

  let action = componentOrFunctionAction;
  if (typeof action === 'function') action = action(id);
  const Action = action ? <div className={classes.action}>{action}</div> : null;

  return (
    <SnackbarContent
      ref={forwardedRef}
      role="alert"
      style={style}
      className={clsx(classes.root, classes[variant])}
      data-testid={`snackbar-${id}`}
    >
      <div className={classes.message}>
        {Icon}
        <span>{message}</span>
      </div>
      {Action}
    </SnackbarContent>
  );
});
