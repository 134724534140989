import React from 'react';
import { FormControl, InputLabel, Select, SelectChangeEvent, MenuItem, Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { rem } from '../app/AppUtilities';
import { DropdownValueType } from '../traductions/settingsPage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginBottom: rem(theme.spacing(2)),
      minWidth: 120,
      width: rem(theme.generalStyles.settingsInputContainerWidth),
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
    },
    select: {
      fontSize: rem(theme.generalStyles.baseTextSize + 4),
    },
  })
);

interface Props {
  id: string;
  label: string;
  value: string;
  name: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  options: DropdownValueType[];
}

export default function SettingsSelect({ id, label, value, name, onChange, options }: Props) {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="print-brand" variant="standard">
        {label}
      </InputLabel>
      <Select id={id} name={name} className={classes.select} value={value} onChange={onChange} variant="standard">
        {options.map((option: DropdownValueType) => (
          <MenuItem className={classes.select} key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
