import { faCirclePlay } from '@fortawesome/pro-regular-svg-icons';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Typography } from '@mui/material';

import { DelaySelection, DelaySelectionLabel } from '../delaySelection';
import { usePauseChannelDrawer } from '../hooks/usePauseChannelDrawer';
import { PauseChannelListContainer, PauseChannelListContainerLabel } from '../pauseChannelListContainer';
import { ChannelStates, OrderChannelEnum } from '../types/PauseTypes';
import { Drawer, DrawerHeader, DrawerContent, DrawerFooter } from '../../components/drawer';
import { getComponentVariantStyles } from '../../app/muiTheme';

export interface PauseChannelLabel {
  title: string;
  actionButtonNext: { primaryButton?: string; secondaryButton?: string };
  delaySelectionLabel: DelaySelectionLabel;
  listContainerLabel: PauseChannelListContainerLabel;
}

export interface PauseChannelData {
  branchId: number;
  channelList: ChannelStates[];
  open: boolean;
}

export interface PauseChannelProps {
  data: PauseChannelData;
  onCloseDrawer: () => void;
  onResumeChannel: (channelsToResume: OrderChannelEnum[]) => void;
  handlePauseSuccessNotification: (pauseStates: ChannelStates[]) => void;
  handlePauseErrorNotification: () => void;
}

export const PauseChannelDrawer = ({
  data,
  onCloseDrawer,
  onResumeChannel,
  handlePauseSuccessNotification,
  handlePauseErrorNotification,
}: PauseChannelProps) => {
  const {
    state,
    isNextButtonDisabled,
    isBackButtonDisabled,
    isPrimaryButtonStartIconDisplayed,
    isPrimaryButtonEndIconDisplayed,
    handleSelectDelay,
    handleSynchronizeDelay,
    handleSelectItem,
    handleNextAction,
    handleBackAction,
    handleTimeExpiry,
  } = usePauseChannelDrawer({
    data,
    onCloseDrawer,
    onResumeChannel,
    handlePauseSuccessNotification,
    handlePauseErrorNotification,
  });

  const { label } = state;
  const wizardComponentsMap = {
    channelsSelection: (
      <PauseChannelListContainer
        label={label.listContainerLabel}
        data={{
          activeChannelList: state.activeChannelList,
          activeListDisabled: state.isActiveListDisabled,
          pausedChannelList: state.pausedChannelList,
          pausedListDisabled: state.isPausedListDisabled,
        }}
        onSelected={handleSelectItem}
        onTimeDelayExpiry={handleTimeExpiry}
      />
    ),
    delaySelection: (
      <DelaySelection
        label={label.delaySelectionLabel}
        data={{
          delaysList: state.delaysList,
          selectedChannelsToPauseIdsList: state.selectedChannelsToPauseIdsList,
          showApplyToAllPausedChannelsCheckbox: state.showApplyToAllPausedChannelsCheckbox,
          synchronizeDelay: state.synchronizeDelay,
        }}
        onSelectedDelayChanged={handleSelectDelay}
        onSynchronizeDelayChange={handleSynchronizeDelay}
      />
    ),
  };

  return (
    <Drawer open={data.open} onClose={onCloseDrawer}>
      <DrawerHeader onClose={onCloseDrawer}>{label.title}</DrawerHeader>
      <DrawerContent>{wizardComponentsMap[state.currentStep]}</DrawerContent>
      <DrawerFooter>
        {!!label.actionButtonNext.secondaryButton && (
          <Button
            variant="outlined"
            disabled={isBackButtonDisabled}
            startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            data-testid="pause-back-button"
            onClick={handleBackAction}
            sx={(theme) => ({
              ...getComponentVariantStyles('MuiButton', 'bigContained', theme),
              border: '1px solid currentColor',
              '&:hover, &:active': {
                background: theme.palette.primary.contrastText,
              },
            })}
          >
            <Typography variant="button">{label.actionButtonNext.secondaryButton}</Typography>
          </Button>
        )}
        {!!label.actionButtonNext.primaryButton && (
          <Button
            variant="contained"
            disabled={isNextButtonDisabled}
            startIcon={isPrimaryButtonStartIconDisplayed && <FontAwesomeIcon icon={faCirclePlay} />}
            endIcon={isPrimaryButtonEndIconDisplayed && <FontAwesomeIcon icon={faArrowRight} />}
            data-testid="pause-next-button"
            onClick={handleNextAction}
            sx={(theme) => ({
              marginLeft: 'auto',
              ...getComponentVariantStyles('MuiButton', 'bigContained', theme),
              '&:hover': {
                boxShadow: theme.generalStyles.buttonHoverBoxShadow,
                filter: theme.generalStyles.buttonHoverFilter,
              },
            })}
          >
            <Typography variant="button">{label.actionButtonNext.primaryButton}</Typography>
          </Button>
        )}
      </DrawerFooter>
    </Drawer>
  );
};
