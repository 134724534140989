export enum SoundLabel {
  Alarm = 'alarm',
  Beep = 'beep',
  Buzzer = 'buzzer',
  Cloc = 'cloc',
  Coin = 'coin',
  HighPitchAlarm = 'high pitch alarm',
  Horn = 'horn',
  LoudCashier = 'loud cashier',
  OoooGurl = 'oooo gurl',
  PopUp = 'popup',
  Scheme = 'scheme',
  EightBit = '8 bit',
}

export enum SoundPath {
  Alarm = 'Alarm.wav',
  Beep = 'Beep.wav',
  Buzzer = 'Buzzer.wav',
  Cloc = 'Cloc.wav',
  Coin = 'Coin.wav',
  HighPitchAlarm = 'HighPitchAlarm.wav',
  Horn = 'Horn.wav',
  LoudCashier = 'LoudCashier.wav',
  OoooGurl = 'OoooGurl.wav',
  PopUp = 'PopUp.wav',
  Scheme = 'Scheme.wav',
  EightBit = '8Bit.wav',
}

export interface SoundType {
  id: string;
  label: SoundLabel;
  path: SoundPath;
}

export interface AlertProfile {
  alertsOn: boolean;
  soundOn: boolean;
  id: string;
}

export const AVAILABLE_SOUNDS: SoundType[] = [
  {
    id: SoundLabel.Alarm.replace(/\s/g, ''),
    label: SoundLabel.Alarm,
    path: SoundPath.Alarm,
  },
  {
    id: SoundLabel.Beep.replace(/\s/g, ''),
    label: SoundLabel.Beep,
    path: SoundPath.Beep,
  },
  {
    id: SoundLabel.Buzzer.replace(/\s/g, ''),
    label: SoundLabel.Buzzer,
    path: SoundPath.Buzzer,
  },
  {
    id: SoundLabel.Cloc.replace(/\s/g, ''),
    label: SoundLabel.Cloc,
    path: SoundPath.Cloc,
  },
  {
    id: SoundLabel.Coin.replace(/\s/g, ''),
    label: SoundLabel.Coin,
    path: SoundPath.Coin,
  },
  {
    id: SoundLabel.HighPitchAlarm.replace(/\s/g, ''),
    label: SoundLabel.HighPitchAlarm,
    path: SoundPath.HighPitchAlarm,
  },
  {
    id: SoundLabel.Horn.replace(/\s/g, ''),
    label: SoundLabel.Horn,
    path: SoundPath.Horn,
  },
  {
    id: SoundLabel.LoudCashier.replace(/\s/g, ''),
    label: SoundLabel.LoudCashier,
    path: SoundPath.LoudCashier,
  },
  {
    id: SoundLabel.OoooGurl.replace(/\s/g, ''),
    label: SoundLabel.OoooGurl,
    path: SoundPath.OoooGurl,
  },
  {
    id: SoundLabel.PopUp.replace(/\s/g, ''),
    label: SoundLabel.PopUp,
    path: SoundPath.PopUp,
  },
  {
    id: SoundLabel.Scheme.replace(/\s/g, ''),
    label: SoundLabel.Scheme,
    path: SoundPath.Scheme,
  },
  {
    id: SoundLabel.EightBit.replace(/\s/g, ''),
    label: SoundLabel.EightBit,
    path: SoundPath.EightBit,
  },
];
