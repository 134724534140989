import { PauseChannelListItemData, PauseChannelListItemLabel } from './PauseChannelListItem';
import { PauseChannelChipMock } from '../pauseChannelChip';
import moment from 'moment';

const label: PauseChannelListItemLabel = {
  pauseChannelChipLabel: PauseChannelChipMock.label,
};

const defaultData: PauseChannelListItemData = {
  selected: false,
  channelId: 'UBER_EATS',
  channelName: 'UberEats',
};

const selectedActive: PauseChannelListItemData = {
  ...defaultData,
  selected: true,
};

const selectedPaused: PauseChannelListItemData = {
  ...defaultData,
  pausedUntil: moment().add(35, 'minute').toISOString(),
  selected: true,
};

const disabled: PauseChannelListItemData = {
  ...defaultData,
  disabled: true,
};

const pending: PauseChannelListItemData = {
  ...defaultData,
  pending: true,
};

const doordash: PauseChannelListItemData = {
  ...defaultData,
  channelId: 'DOOR_DASH',
  channelName: 'Door Dash',
};

export const PauseChannelListItemMock = {
  label,
  defaultData,
  selectedActive,
  selectedPaused,
  disabled,
  pending,
  doordash,
};
