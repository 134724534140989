import { encode } from 'base-64';
import { Settings } from '../app/SettingsContext';
import { Order } from '../orderDetail/OrderDetailTypes';
import { ValidationError } from '../types/errors';
import { getBaseApiUrl } from '../utils/env';

export default async function generateReceipt(settings: Settings, order: Order): Promise<string> {
  const url = getBaseApiUrl();
  const headers = new Headers();
  headers.set('Authorization', settings.authToken!);
  headers.set('x-ueatsessionid', order.sessionId);

  const result = await fetch(
    `${url}/receipt?orderNumber=${order.shortNumber}&printFormat=${getPrintFormat(settings)}&culture=${
      settings.culture
    }&printerModel=${settings.printState.model}&branchId=${settings.currentBranchId}`,
    {
      method: 'GET',
      headers,
    }
  );

  if (!result.ok) {
    throw new Error('Unable to generate the receipt for the order');
  }

  const arrayBuffer = await result.arrayBuffer();

  if (!arrayBuffer || arrayBuffer.byteLength == 0) {
    throw new ValidationError();
  }

  return arrayBufferToBase64(arrayBuffer);
}

function arrayBufferToBase64(buffer: ArrayBuffer): string {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i += 1) {
    binary += String.fromCharCode(bytes[i]);
  }
  return encode(binary);
}

function getPrintFormat(settings: Settings): string {
  switch (settings.printState.model) {
    case 'SP700':
    case 'TSP143':
      return 'StarGraphic';

    case 'mC-Print':
      return 'StarPrnt';

    default:
      return 'EscPos';
  }
}
