import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Order } from './OrderDetailTypes';
import BillItem from './BillItem';
import FeesList from './FeesList';
import Taxes from './Taxes';
import TipAndTotal from './TipAndTotal';
import Promotions from './Promotions';
import { rem } from '../app/AppUtilities';
import SubTotal from './SubTotal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemList: {
      padding: 0,
      margin: `${rem(theme.spacing(3))} 0 ${rem(theme.spacing(1))}`,
      height: '100%',
      overflowY: 'auto',
    },
  })
);

interface Props {
  orderData: Order;
  currency: string;
}

export default function ItemList({ orderData, currency }: Props) {
  const classes = useStyles();
  const itemList = orderData.items;

  if (itemList) {
    return (
      <ul className={classes.itemList}>
        {itemList.map((item) => (
          <BillItem key={item.id} itemData={item} currency={currency} />
        ))}
        <FeesList orderData={orderData} currency={currency} />
        <Promotions promotionData={orderData.promotions} currency={currency} />
        <SubTotal orderData={orderData} currency={currency} />
        <Taxes orderData={orderData} currency={currency} />
        <TipAndTotal orderData={orderData} currency={currency} />
      </ul>
    );
  } else {
    return null;
  }
}
