import { faCheck, faExclamation, faInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import makeStyles from '@mui/styles/makeStyles';
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';
import React from 'react';
import { Snackbar } from './Snackbar';

const useStyles = makeStyles(() => ({
  container: {
    bottom: 20,
  },
}));

export const SnackbarProvider = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();
  return (
    <NotistackSnackbarProvider
      maxSnack={5}
      autoHideDuration={7000}
      preventDuplicate={true}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      classes={{
        containerAnchorOriginBottomLeft: classes.container,
      }}
      Components={{
        default: Snackbar,
        success: Snackbar,
        error: Snackbar,
        warning: Snackbar,
        info: Snackbar,
      }}
      iconVariant={{
        success: <FontAwesomeIcon icon={faCheck} />,
        error: <FontAwesomeIcon icon={faExclamation} />,
        warning: <FontAwesomeIcon icon={faExclamation} />,
        info: <FontAwesomeIcon icon={faInfo} />,
      }}
    >
      {children}
    </NotistackSnackbarProvider>
  );
};
