import { LogLevel } from './LogLevels';

export interface LogEvent {
  message: string;
  error?: Error;
  logLevel?: LogLevel;
  extras?: Record<string, string>;
}

export function getLogLevelFromEvent(event: LogEvent) {
  if (!!event.error) {
    return LogLevel.Error;
  }

  if (event.logLevel) {
    return event.logLevel;
  }

  return LogLevel.Information;
}
