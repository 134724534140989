import { useEffect, useRef } from 'react';
import { useSettings } from '../app/SettingsContext';
import { createSoundPlayer, getSoundPath, getSoundPathById, playAlertSound, stopAlertSound } from './SoundUtilities';

export function useSoundPlayer() {
  const settings = useSettings();
  const soundPath = getSoundPath(settings);
  const soundPlayer = useRef(soundPath && createSoundPlayer(soundPath));
  const nonLoopingPlayer = useRef<HTMLAudioElement>();

  useEffect(() => {
    if (soundPlayer.current && soundPath) {
      soundPlayer.current.src = soundPath;
    }
  }, [soundPath]);

  const stopSound = () => {
    const player = soundPlayer.current;
    if (player) {
      stopAlertSound(player);
    }

    const nonLooping = nonLoopingPlayer.current;
    if (nonLooping) {
      stopAlertSound(nonLooping);
    }
  };

  const playSound = () => {
    const player = soundPlayer.current;

    if (player && player.paused) {
      playAlertSound(player, settings.alertProfile.soundOn);
    }
  };

  const playOnce = (soundId: string) => {
    const path = getSoundPathById(soundId);
    const player = createSoundPlayer(path, false);
    nonLoopingPlayer.current = player;
    playAlertSound(player, settings.alertProfile.soundOn, false);
  };

  return {
    playOnce,
    playSound,
    stopSound,
  };
}
