import { useSettings } from './SettingsContext';
import * as traductions from '../traductions';

export function useTraductions(): traductions.TraductionsType {
  const settings = useSettings();

  switch (settings.culture) {
    case 'fr-CA':
      return traductions.frCa;
    default:
      return traductions.enCa;
  }
}
