export interface NotFoundType {
  title: string;
}

export const enCa: NotFoundType = {
  title: 'Page not found!',
};

export const frCa: NotFoundType = {
  title: 'Page introuvable!',
};
