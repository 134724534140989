export interface MenuItemsPageType {
  pageTitle: string;
  searchInput: string;
  emptyList: string;
  externalChannelsWarning: string;
  legend: string;
  on: string;
  temporaryOff: string;
  off: string;
  loading: string;
  error: string;
  availabilityReadonly: string;
  availabilitySaveSuccess: string;
}

export const enCa: MenuItemsPageType = {
  pageTitle: 'menu items',
  searchInput: 'search menu items',
  emptyList: 'no corresponding menu items found',
  externalChannelsWarning: 'Changes to the state of an item apply to all following channels.',
  legend: 'item states',
  on: 'Available',
  temporaryOff: 'Temporarily unavailable',
  off: 'Unavailable',
  loading: 'loading menu items...',
  error: 'an error has occured while loading menu items.',
  availabilityReadonly: 'Status defined by the headquarter',
  availabilitySaveSuccess: 'Availability saved',
};

export const frCa: MenuItemsPageType = {
  pageTitle: 'items de menu',
  searchInput: 'recherchez un item de menu',
  emptyList: 'aucun item correspondant trouvé',
  externalChannelsWarning: "Les changements à l'état d'un item s'appliquent pour tous ces canaux.",
  legend: 'états possibles',
  on: 'Disponible',
  temporaryOff: 'Temporairement indisponible',
  off: 'Indisponible',
  loading: 'chargement des items de menu...',
  error: 'une erreur est survenue pendant le chargement des items de menu.',
  availabilityReadonly: 'Statut défini par la maison mère',
  availabilitySaveSuccess: 'Disponibilité sauvegardée',
};
