export interface BottomNavType {
  new: string;
  preorders: string;
  processed: string;
}

export const enCa: BottomNavType = {
  new: 'New orders',
  preorders: 'Preordered',
  processed: 'Processed',
};

export const frCa: BottomNavType = {
  new: 'Nouvelles',
  preorders: 'Précommandées',
  processed: 'Traitées',
};
