import { makeStyles, createStyles } from '@mui/styles';
import { DefaultTheme } from '@mui/material/styles/createTheme';
import { Box } from '@mui/material';

import { rem } from '../../app/AppUtilities';
import { PauseChannelList, PauseChannelListLabel, PauseChannelStatus } from '../pauseChannelList';

const useStyles = makeStyles((theme: DefaultTheme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: rem(30),
    },
  })
);

export interface PauseChannelListContainerLabel {
  activeLabelList: PauseChannelListLabel;
  pausedLabelList: PauseChannelListLabel;
}

export interface PauseChannelListContainerData {
  activeChannelList: PauseChannelStatus[];
  activeListDisabled?: boolean;
  pausedChannelList: PauseChannelStatus[];
  pausedListDisabled?: boolean;
}

export interface PauseChannelListContainerProps {
  label: PauseChannelListContainerLabel;
  data: PauseChannelListContainerData;
  onSelected: (channelId: string) => void;
  onTimeDelayExpiry: (channelId: string) => void;
}

export const PauseChannelListContainer = ({
  label,
  data,
  onSelected,
  onTimeDelayExpiry,
}: PauseChannelListContainerProps) => {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <Box>
        <PauseChannelList
          label={label.pausedLabelList}
          data={{
            channelStatus: data.pausedChannelList,
            disabled: data.pausedListDisabled,
          }}
          onSelected={onSelected}
          onTimeDelayExpiry={onTimeDelayExpiry}
        />
      </Box>
      <Box>
        <PauseChannelList
          label={label.activeLabelList}
          data={{
            channelStatus: data.activeChannelList,
            disabled: data.activeListDisabled,
          }}
          onSelected={onSelected}
          onTimeDelayExpiry={onTimeDelayExpiry}
        />
      </Box>
    </Box>
  );
};
