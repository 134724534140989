import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { Theme, Typography } from '@mui/material';
import { useTraductions } from '../app/TraductionsContext';
import { createStyles, makeStyles } from '@mui/styles';
import { useSettings } from '../app/SettingsContext';
import DecorationLine, { DecorationLineVariant } from '../prepTime/DecorationLine';
import { rem } from '../app/AppUtilities';
import { THIRD_PARTY_DELIVERY_PARTNER_QUERY } from '../queries/queries';
import { Data, Variables } from './ThirdPartyDeliveryPartnerTypes';
import { Query } from '@apollo/client/react/components';
import EmptyListMessage from '../menuItems/EmptyListMessage';
import ThirdPartyDeliveryPartner from './ThirdPartyDeliveryPartner';
import PartnerStatesCaptions from './PartnerStatesCaptions';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: rem(theme.spacing(2)),
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
    line: {
      [theme.breakpoints.up('md')]: {
        width: `calc(70% - ${rem(theme.spacing(2))})`,
      },
    },
    partnersList: {
      height: '100%',
      marginBottom: rem(190),
      overflowY: 'auto',
      [theme.breakpoints.up('md')]: {
        marginBottom: rem(theme.spacing(1)),
      },
      paddingTop: '1.5rem',
    },
    queryMessage: {
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
  })
);

export default function ThirdPartyDeliveryPartnerPage() {
  const settings = useSettings();
  const traductions = useTraductions();
  const branchId = settings.currentBranchId;
  const classes = useStyles();
  const history = useHistory();

  function hasExternalDeliveryProvider() {
    return (
      settings.currentBranch &&
      settings.currentBranch.services.filter((x) => x.type === 'DELIVERY').length > 0 &&
      settings.currentBranch.services.filter((x) => x.type === 'DELIVERY')[0].hasExternalDeliveryProvider
    );
  }

  if (!branchId) {
    return null;
  }

  if (!hasExternalDeliveryProvider()) {
    history.push('/orders');
  }

  return (
    <>
      <header>
        <Typography variant="h4" className={classes.title}>
          {traductions.thirdPartyDeliveryPartnerPage.pageTitle}
        </Typography>
        <DecorationLine variant={DecorationLineVariant.Red} className={classes.line} />
      </header>
      <Query<Data, Variables>
        query={THIRD_PARTY_DELIVERY_PARTNER_QUERY}
        variables={{ branchId }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error, fetchMore }) => {
          if (loading)
            return <p className={classes.queryMessage}>{traductions.thirdPartyDeliveryPartnerPage.loading}</p>;
          if (error) return <p className={classes.queryMessage}>{traductions.thirdPartyDeliveryPartnerPage.error}</p>;

          if (data) {
            return (
              <div className={classes.partnersList}>
                {!!!data.thirdPartyDeliveryPartners.length && (
                  <EmptyListMessage message={traductions.thirdPartyDeliveryPartnerPage.emptyList} />
                )}
                {data.thirdPartyDeliveryPartners.map((thirdPartyDeliveryPartner) => (
                  <ThirdPartyDeliveryPartner
                    key={thirdPartyDeliveryPartner.id}
                    partnerData={thirdPartyDeliveryPartner}
                    setMutationError={(error) => {
                      enqueueSnackbar({
                        variant: 'error',
                        message: error,
                      });
                    }}
                  />
                ))}
              </div>
            );
          }

          return null;
        }}
      </Query>
      <PartnerStatesCaptions />
    </>
  );
}
