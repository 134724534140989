import { useQuery } from '@apollo/client';
import { Loading } from '@ueat/poke';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { BranchesQueryData, BranchesQueryVariables } from '../../app/RestaurantInfoQueryType';
import { useSettings } from '../../app/SettingsContext';
import { useTraductions } from '../../app/TraductionsContext';
import { RESTAURANT_BRANCHES_QUERY } from '../../queries/queries';
import usePause from '../hooks/usePause';
import { usePauseNotifications } from '../hooks/usePauseNotifications';
import { usePauseStates } from '../hooks/usePauseStates';
import { usePendingTimeout } from '../hooks/usePendingTimeout';
import { useRemainingTime } from '../hooks/useRemainingTime';
import { PauseChannelData, PauseChannelDrawer } from '../pauseChannelDrawer';
import PausedBanner, { PausedBannerData, PausedBannerLabel } from '../pausedBanner/PausedBanner';
import ResumeBanner, { ResumeBannerData, ResumeBannerLabel } from '../resumeBanner/ResumeBanner';
import { ResumeChannelDialog, ResumeChannelDialogData, ResumeChannelDialogLabel } from '../resumeChannelDialog';
import { ChannelStates, OrderChannelEnum } from '../types/PauseTypes';
import { convertToOrderChannelEnum, getTranslatedChannelName } from '../util/utils';

export interface PauseStatesQueryData {
  pauseStates: ChannelStates[];
}

const PauseContainer = () => {
  const isFirstLoading = useRef(true);
  const settings = useSettings();
  const { pauseBanner } = useTraductions();
  const branchId = settings.currentBranchId;

  const {
    handlePauseSuccessNotification,
    handlePauseErrorNotification,
    handleResumeSuccessNotification,
    handleResumeErrorNotification,
    handleNewExternalChannelDataNotification,
    handlePauseOrResumeTimedOutNotification,
  } = usePauseNotifications({ branchId });

  const {
    pauseState,
    branchIsPaused,
    timerEnabled,
    resumeRestaurantLoading,
    pausedChannels,
    updatePausedChannels,
    handleOpenDrawer,
    handleOpenDialog,
    handleCloseDrawer,
    handleCloseDialog,
    handleResumeRestaurant,
    handleEnableAnimation,
    handleDisableAnimation,
  } = usePause({
    handleResumeSuccessNotification,
    handleResumeErrorNotification,
  });

  const timerChannelText =
    pausedChannels.length > 1
      ? `${pausedChannels.length} ${pauseBanner.channelsText}`
      : getTranslatedChannelName(pausedChannels[0]?.channel, pauseBanner);

  const { data, error, loading } = usePauseStates({
    branchId: branchId as number,
    onCompleted: () => {
      isFirstLoading.current = false;
    },
    handleNewExternalChannelDataNotification,
  });

  const branches = useQuery<BranchesQueryData, BranchesQueryVariables>(RESTAURANT_BRANCHES_QUERY, {
    skip: !settings.currentRestaurantId,
    variables: { restaurantId: settings.currentRestaurantId! },
  });

  const branchExternalChannels = useMemo(() => {
    const orderChannelEnumKeys = Object.keys(OrderChannelEnum);
    return (
      branches.data?.branches
        ?.find((branch) => branch.id === branchId)
        ?.thirdPartyOrderIntegrations?.map((item) => convertToOrderChannelEnum(item))
        .sort((a, b) => {
          return orderChannelEnumKeys.indexOf(a) - orderChannelEnumKeys.indexOf(b);
        }) ?? []
    );
  }, [branches.data, branchId]);

  useEffect(() => {
    if (branchId && data && branchExternalChannels) {
      updatePausedChannels({
        branchId,
        pauseStates: data.pauseStates,
        branchExternalChannels,
      });
    }
  }, [branchId, data?.pauseStates, updatePausedChannels, branchExternalChannels]);

  const onRemainingTimeChanged = (durationSecs: number) => {
    if (durationSecs < 600) {
      handleEnableAnimation();
    } else {
      handleDisableAnimation();
    }
  };

  const [remainingTime] = useRemainingTime(pausedChannels[0]?.pausedUntil, undefined, onRemainingTimeChanged);

  const { pending, hasAnyPendingChannel } = usePendingTimeout({
    channelList: pauseState.channelList,
    handlePauseOrResumeTimedOutNotification,
  });

  const pauseChannelData: PauseChannelData = useMemo(
    () => ({
      branchId: pauseState.branchId!!,
      channelList: pauseState.channelList,
      open: pauseState.drawerOpen,
    }),
    [pauseState.branchId, pauseState.channelList, pauseState.drawerOpen]
  );

  const pausedBannerData: PausedBannerData = useMemo(
    () => ({
      pauseTimer: {
        timerEnabled,
        disabled: pauseState.pauseTimerDisabled,
        isAnimationEnabled: pauseState.isAnimationEnabled,
        pending,
      },
      pauseButton: {
        isPaused: branchIsPaused,
        pending: pending || hasAnyPendingChannel,
      },
    }),
    [
      timerEnabled,
      pauseState.pauseTimerDisabled,
      pauseState.isAnimationEnabled,
      branchIsPaused,
      pending,
      hasAnyPendingChannel,
    ]
  );

  const resumeChannelDialogData: ResumeChannelDialogData = useMemo(
    () => ({
      open: pauseState.resumeDialogOpen,
      cancelButtonDisabled: resumeRestaurantLoading,
      confirmButtonDisabled: resumeRestaurantLoading,
    }),
    [pauseState.resumeDialogOpen, resumeRestaurantLoading]
  );

  const resumeChannelDialogLabel: ResumeChannelDialogLabel = useMemo(
    () => ({
      title: pauseBanner.resumeChannelDialogTitle,
      confirmButton: pauseBanner.resumeChannelDialogConfirm,
      cancelButton: pauseBanner.resumeChannelDialogCancel,
    }),
    [
      pauseBanner.resumeChannelDialogTitle,
      pauseBanner.resumeChannelDialogConfirm,
      pauseBanner.resumeChannelDialogCancel,
    ]
  );

  const onConfirmResumeChannel = useCallback(() => {
    if (!pauseState.branchId) return;
    handleResumeRestaurant({
      branchId: pauseState.branchId,
      channels: pauseState.channelsToResume,
    });
  }, [pauseState.branchId, pauseState.channelsToResume]);

  if (!branchId) {
    return null;
  }

  if ((loading && isFirstLoading.current) || branches.loading) return <Loading inline={true} size={76} thickness={2} />;

  if (error) return <p>{pauseBanner.error}</p>;

  const pausedBannerLabel: PausedBannerLabel = {
    pauseTimer: {
      banner: pauseBanner.pausedBannerText,
      channel: timerChannelText,
      time: remainingTime,
    },
    pauseButton: {
      title: pauseBanner.buttonResumeText,
    },
  };

  const resumeBannerLabel: ResumeBannerLabel = {
    title: pauseBanner.resumeBannerText,
    pauseButton: {
      title: pauseBanner.buttonPauseText,
    },
  };

  const resumeBannerData: ResumeBannerData = {
    pauseButton: {
      isPaused: branchIsPaused,
      pending,
    },
  };

  const onOpenResumeDialog = (): void => {
    handleOpenDialog(pausedChannels.map((x) => x.channel));
  };

  return (
    <>
      {branchIsPaused ? (
        <PausedBanner
          label={pausedBannerLabel}
          data={pausedBannerData}
          onPauseButtonClick={onOpenResumeDialog}
          onPauseTimerButtonClick={handleOpenDrawer}
        />
      ) : (
        <ResumeBanner label={resumeBannerLabel} data={resumeBannerData} onPauseButtonClick={handleOpenDrawer} />
      )}

      <PauseChannelDrawer
        data={pauseChannelData}
        onCloseDrawer={handleCloseDrawer}
        onResumeChannel={handleOpenDialog}
        handlePauseSuccessNotification={handlePauseSuccessNotification}
        handlePauseErrorNotification={handlePauseErrorNotification}
      />

      <ResumeChannelDialog
        label={resumeChannelDialogLabel}
        data={resumeChannelDialogData}
        onCancelResumeChannel={handleCloseDialog}
        onConfirmResumeChannel={onConfirmResumeChannel}
      />
    </>
  );
};

export default PauseContainer;
