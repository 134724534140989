import { DefaultTheme } from '@mui/material/styles/createTheme';
import { Button, Dialog, DialogActions, DialogTitle, Typography } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

import { rem } from '../../app/AppUtilities';

const useStyles = makeStyles((theme: DefaultTheme) =>
  createStyles({
    root: {
      '& .MuiPaper-root': {
        padding: `${rem(40)} ${rem(40)} ${rem(32)}`,
        borderRadius: rem(8),

        '& h2': {
          color: theme.palette.text.default,
          fontSize: rem(24),
          fontStyle: 'normal',
          fontWeight: '700',
          padding: '0',
        },

        '& .MuiDialogActions-root': {
          marginTop: rem(48),
          padding: '0',
          gap: rem(40),
        },
      },
    },
  })
);

export interface ResumeChannelDialogLabel {
  title: string;
  confirmButton?: string;
  cancelButton?: string;
}

export interface ResumeChannelDialogData {
  open: boolean;
  cancelButtonDisabled?: boolean;
  confirmButtonDisabled?: boolean;
}

export interface ResumeChannelDialogProps {
  label: ResumeChannelDialogLabel;
  data: ResumeChannelDialogData;
  onCancelResumeChannel: () => void;
  onConfirmResumeChannel: () => void;
}

export const ResumeChannelDialog = ({
  data,
  label,
  onCancelResumeChannel,
  onConfirmResumeChannel,
}: ResumeChannelDialogProps) => {
  const styles = useStyles();
  return (
    <Dialog open={data.open} className={styles.root}>
      <DialogTitle>{label.title}</DialogTitle>
      <DialogActions>
        <Button variant="bigText" onClick={onCancelResumeChannel} disabled={data.cancelButtonDisabled}>
          <Typography noWrap variant="button">
            {label.cancelButton}
          </Typography>
        </Button>
        <Button
          variant="bigContained"
          color="primary"
          onClick={onConfirmResumeChannel}
          disabled={data?.confirmButtonDisabled}
        >
          <Typography noWrap variant="button">
            {label.confirmButton}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
