import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Button, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { rem } from '../../app/AppUtilities';
import EllipsisIcon from '../icons/EllipsisIcon';

export interface PauseButtonActions {
  onPauseButtonClick: () => void;
}
export interface PauseButtonLabel {
  title: string;
}
export interface PauseButtonData {
  isPaused: boolean;
  pending?: boolean;
}
export interface PauseButtonProps extends PauseButtonActions {
  data: PauseButtonData;
  label: PauseButtonLabel;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      borderRadius: rem(8),
      boxShadow: 'none',
      height: rem(45),
      '& .MuiButton-startIcon>*:nth-of-type(1)': {
        fontSize: '1.75rem',
      },
    },
    pending: {
      '&.Mui-disabled': {
        backgroundColor: theme.pauseComponent.button.background.tertiary,
        color: theme.pauseComponent.button.color.pending,
      },
    },
    buttonText: {
      fontWeight: 700,
      fontSize: rem(theme.generalStyles.biggerTextSize),
      lineHeight: rem(33),
      textTransform: 'none',
    },
    ellipsis: {
      height: rem(32),
      width: rem(32),
    },
  })
);
export const PauseButton = ({ label, data, onPauseButtonClick }: PauseButtonProps) => {
  const classes = useStyles();
  const { isPaused, pending } = data;
  const icon = pending ? (
    <EllipsisIcon className={classes.ellipsis} />
  ) : isPaused ? (
    <PlayCircleOutlineIcon />
  ) : (
    <PauseCircleOutlineIcon />
  );
  const clickAction = !pending ? onPauseButtonClick : undefined;

  return (
    <Button
      size="large"
      variant="contained"
      color="primary"
      startIcon={icon}
      onClick={clickAction}
      disabled={pending}
      className={classNames(classes.button, pending && classes.pending)}
      data-testid={isPaused ? 'pause-resume-button' : 'pause-button'}
    >
      <Typography variant="h4" className={classes.buttonText}>
        {label.title}
      </Typography>
    </Button>
  );
};
