import moment from 'moment';
import { PauseBannerType } from '../../traductions/pauseBanner';
import { ThirdPartyOrderIntegration } from '../../types/ThirdPartyOrderIntegrationTypes';
import { OrderChannelEnum } from '../types/PauseTypes';

export const calculateDifferenceFromNow = (pausedUntil?: string): number => {
  const now = new Date();
  const end = moment(pausedUntil);
  return end.diff(now);
};

export const formatRemainingTime = (pausedUntil?: string): string => {
  if (!pausedUntil) {
    return '';
  } else {
    const diffFromNow = calculateDifferenceFromNow(pausedUntil);
    if (diffFromNow <= 0) {
      return '';
    }

    const duration = moment.duration(diffFromNow);
    if (duration.asHours() < 1) {
      return moment.utc(diffFromNow).format('mm:ss');
    }

    return moment.utc(diffFromNow).format('HH:mm:ss');
  }
};

export const getTranslatedChannelName = (channelKey: OrderChannelEnum, pauseTranslations: PauseBannerType): string => {
  switch (channelKey) {
    case OrderChannelEnum.DOOR_DASH:
      return pauseTranslations.doordash;
    case OrderChannelEnum.SKIP_THE_DISHES:
      return pauseTranslations.skipTheDishes;
    case OrderChannelEnum.UBER_EATS:
      return pauseTranslations.uberEats;
    case OrderChannelEnum.WEB:
      return pauseTranslations.web;
    case OrderChannelEnum.UNKNOWN:
    default:
      return pauseTranslations.unknown;
  }
};

export const convertToOrderChannelEnum = (thirdPartyOrderIntegration: ThirdPartyOrderIntegration): OrderChannelEnum => {
  return OrderChannelEnum[ThirdPartyOrderIntegration[thirdPartyOrderIntegration] as keyof typeof OrderChannelEnum];
};
