export interface OrdersListPageType {
  orderNewTitle: string;
  orderPreorderedTitle: string;
  orderProcessedTitle: string;
  orderStatusPaid: string;
  orderStatusNotPaid: string;
  ordersLoading: string;
  ordersLoadingError: string;
  ordersShowMore: string;
  noMoreOrders: string;
  refunded: string;
}

export const enCa: OrdersListPageType = {
  orderNewTitle: 'new orders',
  orderPreorderedTitle: 'preorders',
  orderProcessedTitle: 'processed orders',
  orderStatusPaid: 'paid',
  orderStatusNotPaid: 'not paid',
  ordersLoading: 'loading orders...',
  ordersLoadingError: 'an error has occured',
  ordersShowMore: 'show more',
  noMoreOrders: 'no orders to show',
  refunded: 'refunded',
};

export const frCa: OrdersListPageType = {
  orderNewTitle: 'nouvelles commandes',
  orderPreorderedTitle: 'précommandes',
  orderProcessedTitle: 'commandes traitées',
  orderStatusPaid: 'payée',
  orderStatusNotPaid: 'à payer',
  ordersLoading: 'chargement des commandes...',
  ordersLoadingError: 'erreur de chargement',
  ordersShowMore: 'voir plus',
  noMoreOrders: 'aucune commande à afficher',
  refunded: 'remboursé',
};
