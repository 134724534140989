import { Settings } from '../app/SettingsContext';
import { EventTypes } from '../events';
import { sendNativeMessage } from '../useNativeEvent';
import { emitCustomEvent } from 'react-custom-events';

export default function print(settings: Settings, orderId: number, receiptData: string, sentAutomatically = false) {
  const message = {
    printer: settings.printState,
    orderId,
    receiptData,
  };
  sendNativeMessage('print', message);

  emitCustomEvent(EventTypes.LOG, {
    message: `Print message for order ${orderId} sent to native app`,
    extras: { eventName: 'print', sentAutomatically, ...message },
  });
}
