import React from 'react';
import OrdersBottomNavigation from './OrdersBotNav';
import { Switch, Route, Redirect } from 'react-router';
import { OrderStatus } from './OrderSummaryTypes';
import OrdersContent from './OrdersContent';
import { useIsPosIntegrated } from '../app/SettingsContext';

const renderOrders = (status: OrderStatus) => () => <OrdersContent status={status} />;

export default function OrdersListNewPage() {
  const hideNewOrdersTab = useIsPosIntegrated();
  return (
    <>
      <Switch>
        <Route path="/orders/preorders" render={renderOrders(OrderStatus.preordered)} />
        <Route path="/orders/processed" render={renderOrders(OrderStatus.processed)} />
        {hideNewOrdersTab ? (
          <Redirect to="/orders/preorders" />
        ) : (
          <>
            <Route path="/orders/pending" render={renderOrders(OrderStatus.pending)} />
            <Redirect to="/orders/pending" />
          </>
        )}
      </Switch>
      <OrdersBottomNavigation />
    </>
  );
}
