import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Button, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { RouteComponentProps, withRouter } from 'react-router';
import { rem } from '../app/AppUtilities';
import { useTraductions } from '../app/TraductionsContext';
import { Sections, SectionShortName } from '../appMenu/SectionsInfo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backButton: {
      flexBasis: `calc(47.5% - ${rem(12)})`,
      backgroundColor: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 'bold',
      fontSize: rem(theme.generalStyles.biggerTextSize),
    },
    backIcon: {
      fontSize: rem(theme.generalStyles.mediumIconSize),
      marginRight: rem(theme.spacing(1)),
    },
  })
);

interface Props extends RouteComponentProps {
  to: SectionShortName;
}

function OrderBackButton({ to, history }: Props) {
  const classes = useStyles();
  const traductions = useTraductions();
  const ordersSection = Sections().find((section) => section.name === to);

  const handleClick = () => {
    if (ordersSection) {
      history.push(ordersSection.path);
    }
  };

  return (
    <Button className={classes.backButton} onClick={handleClick} data-testid="order-detail-back-button">
      <KeyboardArrowLeftIcon className={classes.backIcon} />
      {traductions.orderDetailPage.back}
    </Button>
  );
}

export default withRouter(OrderBackButton);
