import React from 'react';
import { Button, Typography } from '@mui/material';

import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    background: '#000',
    color: '#fff',
    '&:hover, &:focus, &:active': {
      background: '#000',
      color: '#fff',
    },
  },
});

type Props = {
  children: React.ReactNode;
} & WithStyles<typeof styles>;

type State = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<Props, State> {
  state: State = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    const rollbar = (window as any).Rollbar;
    if (rollbar) {
      rollbar.error(error);
    }
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }

    const reload = () => {
      window.location.reload();
    };

    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <Typography variant="h4">Oops! An error has occured.</Typography>
        <Button variant="contained" color="primary" className={classes.button} onClick={reload}>
          Refresh
        </Button>
      </div>
    );
  }
}

export default withStyles(styles)(ErrorBoundary);
