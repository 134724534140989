import React from 'react';
import { useTraductions } from '../app/TraductionsContext';

export default function NotFoundPage() {
  const traductions = useTraductions();
  return (
    <React.Fragment>
      <h1 data-testid="page-title">{traductions.notFound.title}</h1>
    </React.Fragment>
  );
}
