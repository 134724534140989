import { Theme } from '@mui/material';
import { amber, blue, green } from '@mui/material/colors';
import { createStyles, makeStyles } from '@mui/styles';
import { ChannelTag } from '@ueat/poke';
import classNames from 'classnames';
import moment from 'moment';
import 'moment-timezone';
import { rem } from '../app/AppUtilities';
import { useSettings } from '../app/SettingsContext';
import { useTraductions } from '../app/TraductionsContext';
import FormattedPrice from '../formatted/FormattedPrice';
import FormattedService from '../formatted/FormattedService';
import { OrderNumberLabel, Status } from '../orderDetail/OrderDetailTypes';
import { hasExternalOrderNumber } from '../utils/ThirdPartyOrderUtils';
import { OrderResults } from './OrderSummaryTypes';

interface Props {
  orderDetails: OrderResults;
  currency: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    singleOrder: {
      width: '100%',
      padding: `${rem(15)} ${rem(40)}`,
      listStyle: 'none',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: 'white',
      borderRadius: rem(theme.generalStyles.borderRadius),
      boxShadow: `0px ${rem(4)} ${rem(4)} rgba(0, 0, 0, 0.25)`,
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      width: '28%',
    },
    firstColumn: {
      flexDirection: 'row',
      alignItems: 'center',
      width: '20%',
    },
    serviceLabel: {
      marginLeft: '16px',
    },
    labelContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      textAlign: 'left',
    },
    lastColumn: {
      alignItems: 'flex-end',
      textAlign: 'right',
      width: '18%',
    },
    orderElement: {
      width: '100%',
      margin: 0,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
    orderNumber: {
      fontWeight: 'bold',
    },
    firstRow: {
      margin: `0 0 ${rem(5)}`,
      fontSize: rem(theme.generalStyles.baseTextSize),
      fontWeight: 'bold',
    },
    secondRow: {
      fontSize: rem(theme.generalStyles.smallerTextSize),
    },
    statusPaid: {
      fontWeight: 'bold',
      color: green[500],
    },
    statusPending: {
      fontWeight: 'bold',
      color: amber[700],
    },
    refunded: {
      fontWeight: 'bold',
      color: blue[400],
    },
    borderPaid: {
      borderLeft: `${rem(6)} solid ${green[500]}`,
    },
    borderPending: {
      borderLeft: `${rem(6)} solid ${amber[700]}`,
    },
    borderRefund: {
      borderLeft: `${rem(6)} solid ${blue[400]}`,
    },
  })
);

export default function OrderSingle({ orderDetails, currency }: Props) {
  const settings = useSettings();
  const traductions = useTraductions();
  const classes = useStyles();
  const pickupMoment = moment(orderDetails.pickupDate).tz(settings.currentBranch!.timezone as string);
  const pickupDate = pickupMoment.format('LL');
  const pickupTime = pickupMoment.format('LT');

  const getStatusInfo = (): string => {
    if (!orderDetails.isPaid) {
      return traductions.ordersListPage.orderStatusNotPaid;
    }

    if (orderDetails.status === Status.REFUNDED) {
      return traductions.ordersListPage.refunded;
    }

    return traductions.ordersListPage.orderStatusPaid;
  };

  const getStatusLabelStyle = (): string => {
    if (!orderDetails.isPaid) {
      return classes.statusPending;
    }

    if (orderDetails.status === Status.REFUNDED) {
      return classes.refunded;
    }

    return classes.statusPaid;
  };

  const getStatusBorderStyle = (): string => {
    if (!orderDetails.isPaid) {
      return classes.borderPending;
    }

    if (orderDetails.status === Status.REFUNDED) {
      return classes.borderRefund;
    }

    return classes.borderPaid;
  };

  const hasExtOrderNumber = hasExternalOrderNumber(orderDetails.channel, orderDetails.thirdPartyOrderExternalReference);
  const externalOrderNumberLabel = OrderNumberLabel[orderDetails.channel];

  return (
    <div className={classNames(classes.singleOrder, getStatusBorderStyle())}>
      <div className={classNames(classes.column, classes.firstColumn)}>
        <ChannelTag channel={orderDetails.channel} showBorder={false} noPadding={true} />
        <FormattedService
          className={classNames(classes.serviceLabel, classes.orderElement, classes.firstRow)}
          serviceName={orderDetails.service.name}
        />
      </div>
      <div className={classNames(classes.column, classes.labelContainer)}>
        {hasExtOrderNumber && (
          <p className={classNames(classes.orderElement, classes.secondRow)}>
            {externalOrderNumberLabel}{' '}
            <span className={classes.orderNumber}>{`#${orderDetails.thirdPartyOrderExternalReference}`}</span>
          </p>
        )}
        <p className={classNames(classes.orderElement, classes.secondRow)}>
          {hasExtOrderNumber && OrderNumberLabel.WEB}{' '}
          <span className={classes.orderNumber}>{`#${orderDetails.shortNumber}`}</span>
        </p>
      </div>
      <div className={classes.column}>
        <p className={classNames(classes.orderElement, classes.firstRow)}>{orderDetails.customer.fullName}</p>
        <p className={classNames(classes.orderElement, classes.secondRow)}>{orderDetails.customer.phoneNumber}</p>
      </div>
      <div className={classes.column}>
        <p className={classNames(classes.orderElement, classes.firstRow)}>{pickupDate}</p>
        <p className={classNames(classes.orderElement, classes.secondRow)}>{pickupTime}</p>
      </div>
      <div className={classNames(classes.column, classes.lastColumn)}>
        <p className={classNames(classes.orderElement, classes.firstRow)}>
          <FormattedPrice value={orderDetails.totals.total} currency={currency} />
        </p>
        <p className={classNames(classes.orderElement, classes.secondRow, getStatusLabelStyle())}>{getStatusInfo()}</p>
      </div>
    </div>
  );
}
