import { Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { rem } from '../app/AppUtilities';
import { useTraductions } from '../app/TraductionsContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: rem(theme.spacing(6)),
      width: rem(theme.generalStyles.settingsInputContainerWidth),
      margin: `${rem(theme.spacing(15))} auto 0`,
      [theme.breakpoints.up('md')]: {
        margin: '0 0 0 auto',
      },
      padding: `${rem(theme.spacing(5))} ${rem(theme.spacing(4))}`,
      border: `${rem(2)} solid ${theme.palette.secondary.main}`,
      borderRadius: rem(8),
      textAlign: 'center',
    },
    title: {
      marginBottom: rem(theme.spacing(1)),
      color: theme.palette.text.default,
      fontWeight: 700,
    },
  })
);

export default function SettingsPrintDisabled() {
  const classes = useStyles();
  const traductions = useTraductions();
  return (
    <div className={classes.container}>
      <Typography variant="h5" classes={{ root: classes.title }}>
        {traductions.settingsPage.printDisabledTitle}
      </Typography>
      <Typography>{traductions.settingsPage.printDisabledDescription}</Typography>
    </div>
  );
}
