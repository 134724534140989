import styled from '@emotion/styled';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Box, Checkbox, FormControlLabel, Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { ChannelsList } from '@ueat/poke';
import { SelectableTileList, SelectableTileListData } from '../../app';
import { rem } from '../../app/AppUtilities';
import { mapToChannel } from '../../utils/ThirdPartyOrderUtils';
import useWindowDimensions from '../../utils/useWindowDimensions';
import { OrderChannelEnum, PauseAmount } from '../types/PauseTypes';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    applyToAllPausedChannelsContainer: {
      color: theme.palette.text.default,
      marginTop: rem(40),
    },
    label: {
      fontSize: rem(theme.generalStyles.mediumTextSize),
      fontWeight: 600,
    },
    checkbox: {
      color: theme.palette.text.default,
      fontSize: rem(theme.generalStyles.bigIconSize + 2),
    },
  });
});
type ChannelsListContainerProps = {
  iconSize: string;
  padding: string;
  marginBottom: string;
  background: string;
};

const channelsListContainerBorderRadius = rem(50);
const channelsListContainerMarginLeft = rem(56);
const ChannelListContainer = styled('div')((props: ChannelsListContainerProps) => ({
  alignItems: 'center',
  background: props.background,
  borderTopRightRadius: channelsListContainerBorderRadius,
  borderBottomRightRadius: channelsListContainerBorderRadius,
  display: 'inline-flex',
  marginBottom: props.marginBottom,
  marginLeft: `-${channelsListContainerMarginLeft}`,
  padding: `${props.padding} ${props.padding} ${props.padding} ${channelsListContainerMarginLeft}`,
  '& svg': {
    width: `${props.iconSize} !important`,
    minWidth: `${props.iconSize} !important`,
    height: `${props.iconSize} !important`,
  },
}));
export interface DelaySelectionLabel {
  applyToAllPausedChannels: string;
}

export interface DelaySelectionData {
  delaysList: SelectableTileListData;
  showApplyToAllPausedChannelsCheckbox: boolean;
  selectedChannelsToPauseIdsList: OrderChannelEnum[];
  synchronizeDelay: boolean;
}

export interface DelaySelectionProps {
  label: DelaySelectionLabel;
  data: DelaySelectionData;
  onSelectedDelayChanged: (delay: PauseAmount) => void;
  onSynchronizeDelayChange: (checked: boolean) => void;
}

export const DelaySelection = ({
  label,
  data,
  onSelectedDelayChanged,
  onSynchronizeDelayChange,
}: DelaySelectionProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const convertedChannelIds = data.selectedChannelsToPauseIdsList.map((item) => mapToChannel(item));
  const { height } = useWindowDimensions();
  const { lowDeviceHeight } = theme.generalStyles;
  const channelListContainerProps: ChannelsListContainerProps = {
    background: theme.palette.background.paper,
    marginBottom: height < lowDeviceHeight ? rem(32) : rem(60),
    padding: rem(20),
    iconSize: rem(44),
  };
  return (
    <Box>
      <ChannelListContainer {...channelListContainerProps}>
        <ChannelsList channels={convertedChannelIds} size="big" withOverlap={false} />
      </ChannelListContainer>
      <SelectableTileList data={data.delaysList} onChange={onSelectedDelayChanged} testId="pause-delay" />
      {data.showApplyToAllPausedChannelsCheckbox && (
        <FormControlLabel
          classes={{ root: classes.applyToAllPausedChannelsContainer, label: classes.label }}
          control={
            <Checkbox
              color="primary"
              icon={<CheckBoxOutlineBlankIcon className={classes.checkbox} />}
              checkedIcon={<CheckBoxIcon className={classes.checkbox} />}
              onChange={(e) => {
                onSynchronizeDelayChange(e.target.checked);
              }}
              checked={data.synchronizeDelay}
            />
          }
          label={label.applyToAllPausedChannels}
          data-testid="pause-delay-apply-to-all-checkbox"
        />
      )}
    </Box>
  );
};
