import { useQuery } from '@apollo/client';
import { Box, Button, Theme } from '@mui/material';
import { amber, green } from '@mui/material/colors';
import { createStyles, makeStyles } from '@mui/styles';
import classNames from 'classnames';
import moment from 'moment';
import 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { rem } from '../app/AppUtilities';
import { BranchesQueryData, BranchesQueryVariables } from '../app/RestaurantInfoQueryType';
import { isCurrentUserReadonly } from '../app/UserUtils';
import { useIsPosIntegrated, useSettings } from '../app/SettingsContext';
import { useTraductions } from '../app/TraductionsContext';
import { RESTAURANT_BRANCHES_QUERY } from '../queries/queries';
import { shouldDisableCancelOrRefundButton } from '../utils/ThirdPartyOrderUtils';
import { Address } from './Address';
import ManualPrintButton from './ManualPrintButton';
import { Order, Status } from './OrderDetailTypes';
import RefundOrCancelButton from './RefundOrCancelButton';
import RefundOrCancelModal from './RefundOrCancelModal';
import ServicePrecisions from './ServicePrecisions';
import ServiceQuestions from './ServiceQuestions';
import SubTitle from './SubTitle';
import Subsection from './Subsection';
import { getCultureText } from '../utils/i18n';
import OrderDeliveryTrackingDrawer from './OrderDeliveryTrackingDrawer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    status: {
      padding: `${rem(5)} ${rem(10)}`,
      borderRadius: rem(theme.generalStyles.borderRadius),
      fontSize: rem(theme.generalStyles.smallerTextSize),
      color: theme.palette.primary.contrastText,
      backgroundColor: green[500],
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
    paid: {
      backgroundColor: green[500],
    },
    pending: {
      backgroundColor: amber[700],
    },
    refunded: {
      backgroundColor: '#26b7ff',
    },
    informationContainer: {
      height: '100%',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: rem(theme.spacing(3)),
    },
    dateTime: {
      color: theme.palette.primary.main,
      fontSize: rem(theme.generalStyles.biggerTextSize),
      fontWeight: 'bold',
    },
    location: {
      color: theme.palette.primary.main,
      fontSize: rem(theme.generalStyles.biggerTextSize),
      fontWeight: 'bold',
    },
    fullName: {
      width: '68%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    additionnalInfo: {
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      gap: rem(theme.spacing(3)),
      padding: rem(15),
      flexBasis: '45%',
      marginRight: rem(theme.spacing(3)),
      borderRadius: rem(theme.generalStyles.borderRadius),
      backgroundColor: 'white',
      boxShadow: `0px ${rem(4)} ${rem(4)} rgba(0, 0, 0, 0.25)`,
      fontSize: rem(theme.generalStyles.mediumTextSize),
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: rem(theme.generalStyles.biggerTextSize),
      fontWeight: 'bold',
    },
  })
);

interface Props {
  orderData: Order;
}

export default function AdditionnalInfo({ orderData }: Props) {
  const settings = useSettings();
  const userRole = settings.userRole ? settings.userRole : '';
  const traductions = useTraductions();
  const classes = useStyles();
  const [orderStatus, setOrderStatus] = useState<Status>(orderData.status);
  const [buttonIsDisabled, disableButton] = useState<boolean>(
    shouldDisableCancelOrRefundButton(orderData.status, orderData.channel)
  );
  const isPosIntegrated = useIsPosIntegrated();

  const branches = useQuery<BranchesQueryData, BranchesQueryVariables>(RESTAURANT_BRANCHES_QUERY, {
    skip: !settings.currentRestaurantId,
    variables: {
      restaurantId: settings.currentRestaurantId!,
    },
  });

  const readyDate = moment(orderData.readyDate)
    .tz(settings.currentBranch?.timezone ?? '')
    .format('LLL');

  const [isRefundOrCancelModalOpen, setIsRefundOrCancelModalOpen] = React.useState(false);
  const [isDeliveryDrawerOpen, setIsDeliveryDrawerOpen] = React.useState(false);

  const pickupDate = moment(orderData.pickupDate)
    .tz(settings.currentBranch?.timezone ?? '')
    .format('LLL');

  const [showLocation, setShowLocation] = useState<boolean>(false);

  useEffect(() => {
    if (settings.restaurantData) {
      if (settings.restaurantData.length > 1) {
        setShowLocation(true);
      } else if (settings.restaurantData.length === 1 && branches.data) {
        setShowLocation(branches.data.branches.length > 1);
      }
    }
  }, [settings.restaurantData, branches.data]);

  const location =
    settings.currentBranch && settings.currentBranch.name
      ? getCultureText(settings.currentBranch.name, settings.culture)
      : '';

  const allowToCancelThirdPartyDeliveryOrder = orderData.isThirdPartyDelivery && !isCurrentUserReadonly(userRole);

  return (
    <section className={classes.additionnalInfo}>
      <header className={classes.header}>
        <p className={classes.fullName} data-testid="order-detail-customer-name">
          {orderData.customer.fullName}
        </p>
        <p
          className={classNames(
            classes.status,
            orderData.isPaid ? (orderStatus === Status.REFUNDED ? classes.refunded : classes.paid) : classes.pending
          )}
          data-testid="order-detail-status"
        >
          {orderData.isPaid
            ? orderStatus === Status.REFUNDED
              ? traductions.orderDetailPage.refunded
              : traductions.orderDetailPage.paid
            : traductions.orderDetailPage.notPaid}
        </p>
      </header>
      <div className={classes.informationContainer}>
        <Box>
          <SubTitle>{traductions.orderDetailPage.pickupDateTitle}</SubTitle>
          <p className={classes.dateTime} data-testid="order-detail-pickup-date">
            {pickupDate}
          </p>
        </Box>

        {pickupDate !== readyDate && (
          <Box>
            <SubTitle>{traductions.orderDetailPage.readyDateTitle}</SubTitle>
            <p className={classes.dateTime} data-testid="order-detail-ready-date">
              {readyDate}
            </p>
          </Box>
        )}

        {showLocation && location.length > 0 && (
          <Box>
            <SubTitle>{traductions.orderDetailPage.location}</SubTitle>
            <p className={classes.location} data-testid="order-detail-location-name">
              {location}
            </p>
          </Box>
        )}

        <Address orderData={orderData} />
        <ServicePrecisions orderData={orderData} />
        <ServiceQuestions orderData={orderData} />
        <Subsection title={traductions.orderDetailPage.remarks} text={orderData.customer.remarks} />
        <Subsection title={traductions.orderDetailPage.allergies} text={orderData.customer.allergies} />

        {(orderData.isRefundable || allowToCancelThirdPartyDeliveryOrder) && (
          <RefundOrCancelButton
            buttonIsDisabled={buttonIsDisabled}
            setOpen={setIsRefundOrCancelModalOpen}
            orderChannel={orderData.channel}
            isPaid={orderData.isPaid}
          />
        )}

        {!!orderData.thirdPartyDeliveryTracking && (
          <Button
            color="primary"
            variant="contained"
            onClick={() => setIsDeliveryDrawerOpen(true)}
            data-testid="order-detail-track-delivery-button"
            sx={{
              fontWeight: 'bold',
              fontSize: (theme) => rem(theme.generalStyles.mediumTextSize),
            }}
          >
            {traductions.orderDetailPage.trackDelivery}
          </Button>
        )}
      </div>

      {!isPosIntegrated && <ManualPrintButton order={orderData} />}

      <RefundOrCancelModal
        disableButton={disableButton}
        setOrderStatus={setOrderStatus}
        setOpen={setIsRefundOrCancelModalOpen}
        isOpen={isRefundOrCancelModalOpen}
        order={orderData}
        branchId={settings.currentBranchId!}
        orderChannel={orderData.channel}
        isPaid={orderData.isPaid}
        isThirdPartyDelivery={orderData.isThirdPartyDelivery}
      />

      <OrderDeliveryTrackingDrawer
        orderData={orderData}
        open={isDeliveryDrawerOpen}
        onClose={() => setIsDeliveryDrawerOpen(false)}
      />
    </section>
  );
}
