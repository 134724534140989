import { faHourglassClock, faLoader } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { enqueueSnackbar, closeSnackbar } from 'notistack';
import { useEffect } from 'react';
import { rem } from '../../app/AppUtilities';
import { snackbarCloseAction, snackbarConfirmAction } from '../../app/SnackbarActions';
import { useTraductions } from '../../app/TraductionsContext';
import { ChannelStates, OrderChannelEnum, PauseStateStatus } from '../types/PauseTypes';
import { getTranslatedChannelName } from '../util/utils';

interface Props {
  branchId?: number;
}

export function usePauseNotifications({ branchId }: Props) {
  const { pauseBanner } = useTraductions();

  // Whenever we switch restaurant or log out, clear all pause notifications
  useEffect(() => {
    if (!branchId) return;
    return () => {
      for (const [_key, value] of Object.entries(OrderChannelEnum)) {
        closeNotificationsForChannel(value);
      }
    };
  }, [branchId]);

  function interpolateChannel(message: string, channel: OrderChannelEnum) {
    const channelName = getTranslatedChannelName(channel, pauseBanner);
    return message.replace('{{channel}}', channelName);
  }

  function closeNotificationsForChannel(channel: OrderChannelEnum, keyToIgnore?: string) {
    const snackbarsToClose = [
      'pause-error',
      'resume-error',
      `${channel}-pause-success`,
      `${channel}-pause-error`,
      `${channel}-resume-success`,
      `${channel}-resume-error`,
      `${channel}-timeout`,
    ].filter((key) => key !== keyToIgnore);

    snackbarsToClose.forEach(closeSnackbar);
  }

  function displayPauseChannelSuccessNotification(channel: OrderChannelEnum) {
    const key = `${channel}-pause-success`;
    closeNotificationsForChannel(channel, key);
    enqueueSnackbar({
      key,
      variant: 'warning',
      message: interpolateChannel(pauseBanner.pauseChannelSuccessNotification, channel),
      icon: <FontAwesomeIcon icon={faHourglassClock} style={{ fontSize: rem(9) }} />,
      action: snackbarCloseAction,
    });
  }

  function displayPauseChannelErrorNotification(channel: OrderChannelEnum) {
    const key = `${channel}-pause-error`;
    closeNotificationsForChannel(channel, key);
    enqueueSnackbar({
      key,
      variant: 'error',
      message: interpolateChannel(pauseBanner.pauseChannelErrorNotification, channel),
      action: snackbarConfirmAction,
      persist: true,
    });
  }

  function displayPauseErrorNotification() {
    enqueueSnackbar({
      key: 'pause-error',
      variant: 'error',
      message: pauseBanner.pauseErrorNotification,
      action: snackbarConfirmAction,
      persist: true,
    });
  }

  function displayResumeChannelSuccessNotification(channel: OrderChannelEnum) {
    const key = `${channel}-resume-success`;
    closeNotificationsForChannel(channel, key);
    enqueueSnackbar({
      key,
      variant: 'success',
      message: interpolateChannel(pauseBanner.resumeChannelSuccessNotification, channel),
      action: snackbarCloseAction,
    });
  }

  function displayResumeChannelErrorNotification(channel: OrderChannelEnum) {
    const key = `${channel}-resume-error`;
    closeNotificationsForChannel(channel, key);
    enqueueSnackbar({
      key,
      variant: 'error',
      message: interpolateChannel(pauseBanner.resumeChannelErrorNotification, channel),
      action: snackbarConfirmAction,
      persist: true,
    });
  }

  function displayResumeErrorNotification() {
    enqueueSnackbar({
      key: 'resume-error',
      variant: 'error',
      message: pauseBanner.resumeErrorNotification,
      action: snackbarConfirmAction,
      persist: true,
    });
  }

  function displayPauseOrResumeChannelTimeoutNotification(channel: OrderChannelEnum) {
    const key = `${channel}-timeout`;
    closeNotificationsForChannel(channel, key);
    enqueueSnackbar({
      key,
      variant: 'default',
      message: interpolateChannel(pauseBanner.pauseOrResumeChannelTimeoutNotification, channel),
      icon: <FontAwesomeIcon icon={faLoader} />,
      action: snackbarConfirmAction,
      persist: true,
    });
  }

  function handlePauseSuccessNotification(pauseStates: ChannelStates[]) {
    const hasAnyInternalPauseState = pauseStates.find((pauseState) => pauseState.channel === OrderChannelEnum.WEB);

    if (hasAnyInternalPauseState) {
      displayPauseChannelSuccessNotification(OrderChannelEnum.WEB);
    }
  }

  function handlePauseErrorNotification() {
    displayPauseErrorNotification();
  }

  function handleResumeSuccessNotification(pauseStates: ChannelStates[]) {
    const hasAnyInternalPauseState = pauseStates.find((pauseState) => pauseState.channel === OrderChannelEnum.WEB);

    if (hasAnyInternalPauseState) {
      displayResumeChannelSuccessNotification(OrderChannelEnum.WEB);
    }
  }

  function handleResumeErrorNotification() {
    displayResumeErrorNotification();
  }

  function handleNewExternalChannelDataNotification({
    prevPauseStates,
    newPauseStates,
  }: {
    prevPauseStates: ChannelStates[];
    newPauseStates: ChannelStates[];
  }) {
    newPauseStates.forEach((newPauseState) => {
      if (newPauseState.status === PauseStateStatus.Success) {
        if (newPauseState.pausedUntil) {
          displayPauseChannelSuccessNotification(newPauseState.channel);
        } else {
          displayResumeChannelSuccessNotification(newPauseState.channel);
        }
      }

      if (newPauseState.status === PauseStateStatus.Error) {
        const prevPauseState = prevPauseStates.find((pauseState) => pauseState.channel === newPauseState.channel);

        if (!prevPauseState) return;

        if (prevPauseState.pausedUntil) {
          displayResumeChannelErrorNotification(newPauseState.channel);
        } else {
          displayPauseChannelErrorNotification(newPauseState.channel);
        }
      }
    });
  }

  function handlePauseOrResumeTimedOutNotification(pendingPauseStates: ChannelStates[]) {
    pendingPauseStates.forEach((pauseState) => {
      displayPauseOrResumeChannelTimeoutNotification(pauseState.channel);
    });
  }

  return {
    handlePauseSuccessNotification,
    handlePauseErrorNotification,
    handleResumeSuccessNotification,
    handleResumeErrorNotification,
    handleNewExternalChannelDataNotification,
    handlePauseOrResumeTimedOutNotification,
  };
}
