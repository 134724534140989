import { useMutation } from '@apollo/client';
import { useCallback, useReducer } from 'react';
import { RESUME_RESTAURANT } from '../../mutations/mutations';
import { PAUSE_STATES_QUERY } from '../../queries/queries';
import { PauseReducer, PauseReducerActionType } from '../context/PauseReducer';
import { ChannelStates, OrderChannelEnum, PauseState } from '../types/PauseTypes';
import { calculateDifferenceFromNow } from '../util/utils';

export const INITIAL_STATE: PauseState = {
  branchId: undefined,
  channelList: [],
  drawerOpen: false,
  resumeDialogOpen: false,
  channelsToResume: [],
  pauseTimerDisabled: false,
  isAnimationEnabled: false,
};

export interface UsePauseProps {
  pauseState: PauseState;
  pausedChannels: ChannelStates[];
  branchIsPaused: boolean;
  timerEnabled: boolean;
  resumeRestaurantLoading: boolean;
  handleResumeRestaurant: ({ branchId, channels }: ResumeRestaurantVariables) => void;
  handleOpenDrawer: () => void;
  handleCloseDrawer: () => void;
  handleOpenDialog: (channelToResume: OrderChannelEnum[]) => void;
  handleCloseDialog: () => void;
  updatePausedChannels: (pauseState: SetPauseStateProps) => void;
  handleEnableAnimation: () => void;
  handleDisableAnimation: () => void;
}

export interface SetPauseStateProps {
  branchId: number;
  pauseStates: ChannelStates[];
  branchExternalChannels: OrderChannelEnum[];
}

interface ResumeRestaurantData {
  resumeRestaurant: ChannelStates[];
}

interface ResumeRestaurantVariables {
  branchId: number;
  channels: OrderChannelEnum[];
}

interface Props {
  handleResumeSuccessNotification: (pauseStates: ChannelStates[]) => void;
  handleResumeErrorNotification: () => void;
}

const usePause = ({ handleResumeSuccessNotification, handleResumeErrorNotification }: Props): UsePauseProps => {
  const [pauseState, dispatch] = useReducer(PauseReducer, INITIAL_STATE);
  const { channelList } = pauseState;

  const pausedChannels =
    channelList && channelList.length > 0
      ? channelList.filter((item) => item.pausedUntil && calculateDifferenceFromNow(item.pausedUntil) > 0)
      : [];
  const uniqueChannelPausedUntilDates = new Set(pausedChannels.map((v) => v.pausedUntil));
  const branchIsPaused = pausedChannels.length > 0;
  const timerEnabled = branchIsPaused && uniqueChannelPausedUntilDates.size === 1;

  const [resumeRestaurant, { loading: resumeRestaurantLoading }] = useMutation<
    ResumeRestaurantData,
    ResumeRestaurantVariables
  >(RESUME_RESTAURANT, {
    onCompleted: (data) => {
      handleResumeSuccessNotification(data.resumeRestaurant);
      handleCloseDialog();
      handleCloseDrawer();
    },
    onError: () => {
      handleResumeErrorNotification();
    },
    refetchQueries: [
      {
        query: PAUSE_STATES_QUERY,
        variables: { branchId: pauseState.branchId },
      },
    ],
  });

  const handleResumeRestaurant = useCallback(({ branchId, channels }: ResumeRestaurantVariables) => {
    resumeRestaurant({
      variables: {
        branchId,
        channels,
      },
    });
  }, []);

  const handleOpenDrawer = useCallback(() => {
    dispatch({ type: PauseReducerActionType.OPEN_DRAWER });
  }, []);

  const handleCloseDrawer = useCallback(() => {
    dispatch({ type: PauseReducerActionType.CLOSE_DRAWER });
  }, []);
  const handleOpenDialog = useCallback((channelsToResume: OrderChannelEnum[]) => {
    dispatch({
      type: PauseReducerActionType.OPEN_RESUME_DIALOG,
      payload: {
        channelsToResume,
      },
    });
  }, []);
  const handleCloseDialog = useCallback(() => {
    dispatch({ type: PauseReducerActionType.CLOSE_RESUME_DIALOG });
  }, []);

  const updatePausedChannels = useCallback(({ branchId, pauseStates, branchExternalChannels }: SetPauseStateProps) => {
    dispatch({
      type: PauseReducerActionType.SET_PAUSE_STATE,
      payload: { branchId, pauseStates, branchExternalChannels },
    });
  }, []);
  const handleEnableAnimation = useCallback(() => {
    dispatch({ type: PauseReducerActionType.ENABLE_ANIMATION });
  }, []);

  const handleDisableAnimation = useCallback(() => {
    dispatch({ type: PauseReducerActionType.DISABLE_ANIMATION });
  }, []);

  return {
    pauseState,
    pausedChannels,
    branchIsPaused,
    timerEnabled,
    resumeRestaurantLoading,
    handleResumeRestaurant,
    handleOpenDrawer,
    handleCloseDrawer,
    handleOpenDialog,
    handleCloseDialog,
    updatePausedChannels,
    handleEnableAnimation,
    handleDisableAnimation,
  };
};
export default usePause;
