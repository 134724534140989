import React from 'react';
import { useLogEvents } from './useLogEvents';

interface Props {
  children: React.ReactNode;
}

export function LogEventsWrapper({ children }: Props) {
  useLogEvents();

  return <>{children}</>;
}
