import { DelaySelectionData, DelaySelectionLabel } from './';
import { OrderChannelEnum } from '../types/PauseTypes';

const label: DelaySelectionLabel = {
  applyToAllPausedChannels: 'Apply this delay to the channels already on pause',
};

const defaultData: DelaySelectionData = {
  delaysList: {
    elements: [
      { label: '30 min.', value: 'HALF_HOUR', selected: false },
      { label: '60 min.', value: 'HOUR', selected: false },
      { label: '120 min.', value: 'TWO_HOURS', selected: false },
      { label: 'Until tomorrow', value: 'TOMORROW', selected: false },
    ],
  },
  showApplyToAllPausedChannelsCheckbox: false,
  selectedChannelsToPauseIdsList: [
    OrderChannelEnum.UBER_EATS,
    OrderChannelEnum.DOOR_DASH,
    OrderChannelEnum.SKIP_THE_DISHES,
    OrderChannelEnum.WEB,
  ],
  synchronizeDelay: false,
};

const selectedData: DelaySelectionData = {
  ...defaultData,
  delaysList: {
    ...defaultData.delaysList,
    elements: [
      ...[...defaultData.delaysList.elements].slice(0, 3),
      { label: 'Until tomorrow', value: null, selected: true },
    ],
  },
};

const withApplyToAllPausedChannelsCheckboxData = {
  ...defaultData,
  showApplyToAllPausedChannelsCheckbox: true,
};

export const DelaySelectionMock = {
  label,
  defaultData,
  selectedData,
  withApplyToAllPausedChannelsCheckboxData,
};
