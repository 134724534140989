import React from 'react';
import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    line: {
      width: '100%',
    },
  })
);

export default function SeparationLine({ ...props }) {
  const classes = useStyles();

  return <hr className={classes.line} {...props} />;
}
