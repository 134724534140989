import React from 'react';
import { Theme } from '@mui/material';
import OrdersContainer from './OrdersContainer';
import { OrderStatus } from './OrderSummaryTypes';
import { makeStyles, createStyles } from '@mui/styles';

interface Props {
  status: OrderStatus;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ordersList: {
      width: '100%',
      padding: 0,
      listStyle: 'none',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  })
);

export default function OrdersContent({ status }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.ordersList}>
      <OrdersContainer status={status} />
    </div>
  );
}
