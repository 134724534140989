import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import classNames from 'classnames';
import { rem } from '../app/AppUtilities';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    red: {
      height: rem(theme.generalStyles.largeDecorationHeight),
      backgroundColor: theme.palette.primary.main,
    },
    gray: {
      height: rem(theme.generalStyles.smallDecorationHeight),
      backgroundColor: theme.palette.secondary.main,
    },
    lightGray: {
      backgroundColor: theme.palette.secondary.light,
    },
  })
);

export enum DecorationLineVariant {
  Red = 'red',
  Gray = 'gray',
  LightGray = 'lightGray',
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  variant: DecorationLineVariant;
}

export default function DecorationLine({ variant, className }: Props) {
  const classes = useStyles();
  const lineClass = getLineClass(variant, classes);

  return <div className={classNames(className, lineClass)} />;
}

function getLineClass(variant: DecorationLineVariant, styles: Record<'gray' | 'red' | 'lightGray', string>) {
  const classes = styles;

  switch (variant) {
    case DecorationLineVariant.Red:
      return classes.red;
    case DecorationLineVariant.Gray:
      return classes.gray;
    case DecorationLineVariant.LightGray:
      return classes.lightGray;
    default:
      return classes.gray;
  }
}
