import { GraphQLFormattedError } from 'graphql/error/GraphQLError';

export function parseGraphQLErrorMessage(error: string | null) {
  const CUSTOM_ERROR_PREFIX = 'GraphQL.ExecutionError: ';
  const CUSTOM_ERROR_PREFIX_LENGTH = CUSTOM_ERROR_PREFIX.length;

  if (error == null) return error;
  let message = error;
  if (error.indexOf(CUSTOM_ERROR_PREFIX) === 0) {
    message = error.substr(CUSTOM_ERROR_PREFIX_LENGTH);
  }
  return message;
}

export function parseGraphQLThirdPartyDeliveryErrorMessage(errors: ReadonlyArray<GraphQLFormattedError>) {
  return errors.find(
    (value) => value.extensions?.code === 'cancellation_error' || value.extensions?.code === 'cancel_validation_error'
  );
}
