import { Grid, Theme } from '@mui/material';
import React from 'react';
import { PauseButton, PauseButtonData, PauseButtonLabel } from '../pauseButton';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { rem } from '../../app/AppUtilities';
import PauseTimerButton, { PauseTimerData, PauseTimerLabel } from '../pauseTimerButton/PauseTimerButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.pauseComponent.banner.background.paused,
      alignItems: 'stretch',
      margin: '0',
      borderRadius: `${rem(39)} 0 0 ${rem(39)}`,
      marginRight: `${rem(-24)}`,
      paddingRight: rem(24),
      gap: rem(24),
    },
  })
);
export interface PausedBannerLabel {
  pauseTimer: PauseTimerLabel;
  pauseButton: PauseButtonLabel;
}
export interface PausedBannerData {
  pauseTimer: PauseTimerData;
  pauseButton: PauseButtonData;
}
export interface PausedBannerProps {
  label: PausedBannerLabel;
  data: PausedBannerData;
  onPauseButtonClick: () => void;
  onPauseTimerButtonClick: () => void;
}
const PausedBanner = ({ label, data, onPauseButtonClick, onPauseTimerButtonClick }: PausedBannerProps) => {
  const classes = useStyles();
  return (
    <Grid
      container
      classes={{
        root: classes.container,
      }}
    >
      <Grid item>
        <PauseTimerButton
          label={label.pauseTimer}
          data={data.pauseTimer}
          onPauseTimerButtonClick={onPauseTimerButtonClick}
        />
      </Grid>
      <Grid alignSelf="center" item>
        <PauseButton label={label.pauseButton} data={data.pauseButton} onPauseButtonClick={onPauseButtonClick} />
      </Grid>
    </Grid>
  );
};

export default PausedBanner;
