import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Item } from './OrderDetailTypes';
import FormattedPrice from '../formatted/FormattedPrice';
import ItemSubList from './ItemSubList';
import ItemElement, { ElementType } from './ItemElement';
import ItemNote from './ItemNote';
import { rem } from '../app/AppUtilities';
import { useTraductions } from '../app/TraductionsContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    quantity: {
      color: theme.palette.primary.main,
      marginLeft: rem(theme.spacing(0.5)),
    },
    itemContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flexShrink: 0,
      flexBasis: 'auto',
    },
  })
);

interface Props {
  itemData: Item;
  currency: string;
}

export default function BillItem({ itemData, currency }: Props) {
  const traductions = useTraductions();
  const classes = useStyles();

  if (itemData) {
    const titleId = `item-${itemData.id}`;
    return (
      <li className={classes.itemContainer} aria-labelledby={titleId}>
        <ItemElement type={ElementType.Name} id={titleId}>
          {itemData.name}
          {itemData.quantity > 1 && (
            <span className={classes.quantity} aria-label={traductions.orderDetailPage.quantity}>
              X{itemData.quantity}
            </span>
          )}
        </ItemElement>
        <ItemElement type={ElementType.Price}>
          <FormattedPrice value={itemData.regularUnitPrice * itemData.quantity} currency={currency} />
        </ItemElement>
        <ItemNote note={itemData.note} />
        <ItemSubList listData={itemData.extras} currency={currency} />
        <ItemSubList listData={itemData.options} currency={currency} />
      </li>
    );
  } else {
    return null;
  }
}
