import { NetworkStatus, useQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useCustomEventListener } from 'react-custom-events';
import { Link } from 'react-router-dom';
import { rem } from '../app/AppUtilities';
import { useQueryMessageStyle } from '../app/RestaurantManager';
import { useSettings } from '../app/SettingsContext';
import { useTraductions } from '../app/TraductionsContext';
import { EventTypes } from '../events';
import { ORDERS_QUERY } from '../queries/queries';
import OrderSingle from './OrderSingle';
import { Data, OrderStatus, Variables } from './OrderSummaryTypes';

interface Props {
  status: OrderStatus;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    singleOrderContainer: {
      display: 'block',
      width: '100%',
      textDecoration: 'none',
      color: theme.palette.secondary.dark,
      '&:not(:first-child)': {
        marginTop: rem(theme.spacing(2)),
      },
    },
    showMoreContainer: {
      textAlign: 'center',
      alignSelf: 'stretch',
      marginTop: rem(theme.spacing(4)),
    },
    showMoreButton: {
      width: '50%',
      fontSize: rem(theme.generalStyles.mediumTextSize),
      letterSpacing: rem(1),
      wordSpacing: rem(2),
    },
    noMoreOrders: {
      fontSize: rem(theme.generalStyles.biggerTextSize),
      fontWeight: 'bold',
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
    bottomEmpty: {
      width: '100%',
      height: rem(theme.generalStyles.ordersBottomNavigationHeight + 50),
      flexShrink: 0,
    },
  })
);

export default function OrdersContainer({ status }: Props) {
  const settings = useSettings();
  const traductions = useTraductions();
  const classes = useStyles();
  const queryMessageClass = useQueryMessageStyle();
  const filter = getFilter(status);

  const { data, loading, error, refetch, fetchMore, networkStatus } = useQuery<Data, Variables>(ORDERS_QUERY, {
    skip: !settings.currentBranchId || !settings.currentRestaurantId,
    variables: { branchId: settings.currentBranchId!, restaurantId: settings.currentRestaurantId, filter, offset: 0 },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useCustomEventListener(
    EventTypes.NEW_ORDER,
    () => {
      refetch();
    },
    [refetch]
  );

  useCustomEventListener(
    EventTypes.ORDER_CHANGED,
    () => {
      refetch();
    },
    [refetch]
  );

  useCustomEventListener(
    EventTypes.CONNECTION_BACK,
    () => {
      refetch();
    },
    [refetch]
  );

  if (!settings.currentBranchId || !settings.currentBranch) {
    return null;
  }

  if (loading && !data) return <p className={queryMessageClass.message}>{traductions.ordersListPage.ordersLoading}</p>;
  if (error) return <p className={queryMessageClass.message}>{traductions.ordersListPage.ordersLoadingError}</p>;

  if (!data) return null;

  return (
    <>
      {data.orders.results.length ? (
        data.orders.results.map((orderDetails) => (
          <Link
            to={`/order/${orderDetails.id}`}
            key={orderDetails.id}
            className={classes.singleOrderContainer}
            data-testid={`order-${orderDetails.service.type}-${orderDetails.id}`}
          >
            <OrderSingle orderDetails={orderDetails} currency={settings.currentBranch!.currency} />
          </Link>
        ))
      ) : (
        <p className={classes.noMoreOrders}>{traductions.ordersListPage.noMoreOrders}</p>
      )}

      {data.orders.hasMore && (
        <div className={classes.showMoreContainer}>
          <LoadingButton
            classes={{ root: classes.showMoreButton }}
            color="primary"
            variant="contained"
            onClick={() =>
              fetchMore({
                variables: {
                  branchId: settings.currentBranchId,
                  restaurantId: settings.currentRestaurantId,
                  filter,
                  offset: data.orders.results.length,
                },
                updateQuery: (prev: Data, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  return Object.assign({}, prev, {
                    orders: {
                      ...fetchMoreResult.orders,
                      results: [...prev.orders.results, ...fetchMoreResult.orders.results],
                    },
                  });
                },
              })
            }
            loading={networkStatus === NetworkStatus.fetchMore}
            loadingPosition="end"
            endIcon={<span />}
            data-testid="orders-load-more-button"
          >
            {traductions.ordersListPage.ordersShowMore}
          </LoadingButton>
        </div>
      )}
      <div className={classes.bottomEmpty} />
    </>
  );
}

function getFilter(status: OrderStatus | undefined) {
  if (status) {
    return status;
  } else {
    return OrderStatus.all;
  }
}
