import React from 'react';
import { useTraductions } from '../app/TraductionsContext';
import { Typography, Theme, FormControl } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import SettingsCheckBox, { CheckBoxType } from './SettingsCheckbox';
import SoundSelection from './SoundSelection';
import { rem } from '../app/AppUtilities';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      marginBottom: rem(theme.spacing(4)),
    },
    formTitle: {
      marginBottom: rem(theme.spacing(2)),
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
    formControl: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      justifyContent: 'space-between',
      minWidth: rem(120),
      width: rem(theme.generalStyles.settingsInputContainerWidth),
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
    },
  })
);

export default function SettingsAlertProfile() {
  const traductions = useTraductions();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography
        variant="h4"
        classes={{
          root: classes.formTitle,
        }}
        align="center"
      >
        {traductions.settingsPage.alertsTitle}
      </Typography>
      <FormControl className={classes.formControl}>
        <SettingsCheckBox type={CheckBoxType.Alert} />
        <SettingsCheckBox type={CheckBoxType.Sound} />
        <SoundSelection />
      </FormControl>
    </div>
  );
}
