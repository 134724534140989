import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ueatLogo from './ueat-logo.svg';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { useTraductions } from '../app/TraductionsContext';
import { useSettings } from '../app/SettingsContext';
import { rem } from '../app/AppUtilities';
import { useFlag } from '@unleash/proxy-client-react';
import { FEATURE_FLAGS } from '../featureFlags/FEATURE_FLAGS';

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    body: {
      backgroundColor: 'white',
    },
  },
  mainapp: {
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: rem(theme.spacing(1)),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: rem(theme.spacing(3)),
  },
  autoFillBgColor: {
    WebkitBoxShadow: `0 0 0 1000px white inset`,
  },
  submit: {
    margin: `${rem(theme.spacing(3))} 0 ${rem(theme.spacing(2))}`,
    fontSize: rem(theme.generalStyles.mediumTextSize),
    letterSpacing: rem(1),
    wordSpacing: rem(2),
  },
  logo: {
    width: '100%',
    marginBottom: rem(theme.spacing(3)),
  },
  textField: {
    fontSize: rem(theme.generalStyles.baseTextSize + 4),
  },
  inputLabel: {
    fontSize: rem(theme.generalStyles.baseTextSize + 4),
  },
}));

enum LoginFieldType {
  Email = 'email',
  Password = 'password',
}

function LoginPage({ history }: RouteComponentProps) {
  const isIdentityNewUIEnabled = useFlag(FEATURE_FLAGS.IDENTITY_NEW_UI);
  const classes = useStyles();
  const settings = useSettings();
  const traductions = useTraductions();
  const [emailInput, setEmailInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'email') {
      setEmailInput(event.target.value);
    } else {
      setPasswordInput(event.target.value);
    }
  };

  const handleSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    fetch(`${settings.mainApiUrl}/token`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:
        'grant_type=password&username=' +
        encodeURIComponent(`main.${emailInput}`) +
        '&password=' +
        encodeURIComponent(passwordInput),
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 400) {
            enqueueSnackbar({
              variant: 'error',
              message: traductions.loginPage.error400,
            });
          } else {
            enqueueSnackbar({
              variant: 'error',
              message: traductions.loginPage.errorU,
            });
          }
        }

        return response.json();
      })
      .then((response) => {
        settings.login(response.access_token);
      });
  };

  useEffect(() => {
    if (settings.authToken) {
      history.push('/orders');
    }
  }, [settings.authToken, settings.culture, history]);

  /**
   * This is only relevant in case someone tries accessing the login page manually through the URL
   *
   * Once the UboardIdentityNewLoginUI feature flag is GA, we can remove this page entirely
   */
  useEffect(() => {
    if (!settings.authToken && isIdentityNewUIEnabled) {
      settings.login();
    }
  }, [settings.authToken, isIdentityNewUIEnabled]);

  if (isIdentityNewUIEnabled) {
    return null;
  }

  return (
    <Container className={classes.mainapp} component="main" maxWidth="xs">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img className={classes.logo} src={ueatLogo} alt="UEAT" />
        </Grid>
      </Grid>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {traductions.loginPage.title}
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                InputProps={{
                  className: classes.textField,
                  classes: {
                    input: classes.autoFillBgColor,
                  },
                }}
                InputLabelProps={{
                  className: classes.inputLabel,
                }}
                variant="outlined"
                required
                fullWidth
                id={LoginFieldType.Email}
                type={LoginFieldType.Email}
                label={traductions.loginPage.placeholderEmail}
                name={LoginFieldType.Email}
                autoComplete={LoginFieldType.Email}
                onChange={handleChange}
                value={emailInput}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                inputProps={{
                  className: classes.textField,
                }}
                InputLabelProps={{
                  className: classes.inputLabel,
                }}
                variant="outlined"
                required
                fullWidth
                name={LoginFieldType.Password}
                label={traductions.loginPage.placeholderPW}
                type={LoginFieldType.Password}
                id={LoginFieldType.Password}
                autoComplete="current-password"
                onChange={handleChange}
                value={passwordInput}
              />
            </Grid>
          </Grid>
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            {traductions.loginPage.btnSubmit}
          </Button>
        </form>
      </div>
    </Container>
  );
}

const LoginWithRouter = withRouter(LoginPage);
export default LoginWithRouter;
