import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { ChannelTag } from '@ueat/poke';
import classNames from 'classnames';
import { rem } from '../app/AppUtilities';
import { useSettings } from '../app/SettingsContext';
import FormattedService from '../formatted/FormattedService';
import { hasExternalOrderNumber } from '../utils/ThirdPartyOrderUtils';
import ItemList from './ItemList';
import { Order, OrderNumberLabel } from './OrderDetailTypes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    serviceName: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      textAlign: 'right',
      '&:first-letter': {
        textTransform: 'capitalize',
      },
      paddingLeft: '8px',
    },
    orderBill: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      padding: rem(theme.generalStyles.containerPadding),
      flexBasis: '55%',
      borderRadius: rem(theme.generalStyles.borderRadius),
      backgroundColor: 'white',
      boxShadow: `0px ${rem(4)} ${rem(4)} rgba(0, 0, 0, 0.25)`,
      fontSize: rem(theme.generalStyles.mediumTextSize),
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: rem(theme.generalStyles.biggerTextSize),
      fontWeight: 'bold',
    },
    headerParagraph: {
      margin: 0,
    },
    tagContainer: {
      display: 'flex',
      alignItems: 'center',
      minWidth: 0,
    },
    refNumberLabelContainer: {
      display: 'flex',
    },
    refNumberContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'center',
      padding: '0 16px',
      borderRight: '1px solid #D6D6D6',
    },
    lastRefNumberContainer: {
      paddingRight: 0,
      borderRight: 'none',
      marginRight: '2px',
    },
    refNumberLabel: {
      fontSize: rem(theme.generalStyles.baseTextSize),
      marginBottom: '4px',
      marginTop: '0 !important',
    },
    refNumber: {
      fontSize: rem(theme.generalStyles.bigTextSize),
      lineHeight: rem(18),
      color: '#858585',
      margin: '0',
    },
  })
);

interface Props {
  orderData: Order;
}

export default function OrderBill({ orderData }: Props) {
  const classes = useStyles();
  const settings = useSettings();
  const currency = settings.currentBranch!.currency;

  const hasExtOrderNumber = hasExternalOrderNumber(orderData.channel, orderData.thirdPartyOrderExternalReference);
  const externalOrderNumberLabel = OrderNumberLabel[orderData.channel];

  return (
    <section className={classes.orderBill}>
      <header className={classes.header}>
        <div className={classes.tagContainer}>
          <ChannelTag channel={orderData.channel || ''} showBorder={false} noPadding={true} />
          <FormattedService
            className={classNames(classes.serviceName, classes.headerParagraph)}
            serviceName={orderData.service.name}
            testId="order-detail-service-name"
          />
        </div>
        <div className={classes.refNumberLabelContainer}>
          {hasExtOrderNumber && (
            <div className={classes.refNumberContainer}>
              <p className={classes.refNumberLabel}>{externalOrderNumberLabel}</p>
              <p className={classes.refNumber} data-testid="order-detail-external-ref-number">
                {`#${orderData.thirdPartyOrderExternalReference}`}
              </p>
            </div>
          )}
          <div className={classNames(classes.refNumberContainer, classes.lastRefNumberContainer)}>
            {hasExtOrderNumber && <p className={classes.refNumberLabel}>{OrderNumberLabel.WEB}</p>}
            <p className={classes.refNumber} data-testid="order-detail-ref-number">{`#${orderData.shortNumber}`}</p>
          </div>
        </div>
      </header>
      <ItemList orderData={orderData} currency={currency} />
    </section>
  );
}
