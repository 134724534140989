import semverSatisfies from 'semver/functions/satisfies';
import { Settings } from './SettingsContext';

function isSupported(requirement: string) {
  const currentVersion = (window as any).__UEAT_NATIVE_VERSION__;
  if (!currentVersion) {
    return false;
  }

  return semverSatisfies(currentVersion, requirement);
}

export function isAndroid() {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('android') > -1;
}

export function canPrint() {
  return (window as any).UEAT_ENVIRONMENT === 'staging' || isSupported('>=1.1.0');
}

export function canPrintWith(brand: string) {
  if (!isSupported('>=1.2.0') || !isAndroid()) {
    if (brand === 'hprt') {
      return false;
    }
  }

  return true;
}

export function isPrinterEnabled(settings: Settings) {
  return canPrint() && settings.printState.brand && settings.printState.brand !== 'none';
}

export function canPlayNativeSound() {
  return isSupported('>=1.1.1');
}
