import { MutationFunction } from '@apollo/client/react';
import { Mutation } from '@apollo/client/react/components';
import { Button, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { enqueueSnackbar } from 'notistack';
import { RouteComponentProps, withRouter } from 'react-router';
import { rem } from '../app/AppUtilities';
import { useIsPosIntegrated } from '../app/SettingsContext';
import { useTraductions } from '../app/TraductionsContext';
import { ORDER_PROCESSED_MUTATION } from '../mutations/mutations';

interface Data {
  completeOrder: {
    id: number;
  };
}

interface Variables {
  id: number;
}

interface Props extends RouteComponentProps {
  orderId: number;
  disabled: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    processButton: {
      flexBasis: `calc(57.5% - ${rem(12)})`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 'bold',
      fontSize: rem(theme.generalStyles.biggerTextSize),
    },
    note: {
      fontWeight: 700,
      fontStyle: 'italic',
      marginLeft: rem(theme.spacing(0.5)),
    },
  })
);

function ProcessButton({ orderId, history, location, disabled }: Props) {
  const traductions = useTraductions();
  const classes = useStyles();
  const isPosIntegrated = useIsPosIntegrated();
  const id = orderId;

  const handleClick = async (processOrder: MutationFunction<Data, Variables>) => {
    if (isPosIntegrated) return;
    try {
      await processOrder({
        variables: { id },
      });
      history.push('/orders/');
    } catch {
      enqueueSnackbar({
        message: traductions.orderDetailPage.mutationError,
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Mutation<Data, Variables> mutation={ORDER_PROCESSED_MUTATION}>
        {(processOrder, { loading, error }) => (
          <Button
            disabled={disabled || isPosIntegrated}
            className={classes.processButton}
            color="primary"
            variant="contained"
            onClick={(e) => {
              handleClick(processOrder);
            }}
            data-testid="order-detail-process-button"
          >
            {traductions.orderDetailPage.process}
            {isPosIntegrated && (
              <Typography classes={{ root: classes.note }}>
                {traductions.orderDetailPage.processPosIntegrated}
              </Typography>
            )}
          </Button>
        )}
      </Mutation>
    </>
  );
}

export default withRouter(ProcessButton);
