import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { useApolloClient } from './apollo/useApolloClient';

interface Props {
  children: React.ReactNode;
}

export default function ApiClient({ children }: Props) {
  const client = useApolloClient();
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
