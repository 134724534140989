import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { rem } from '../app/AppUtilities';

interface Props {
  text: string;
  icon: ReactElement;
  name: string;
  path: string;
  handleClick: (event: React.MouseEvent<HTMLElement>, section: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  sectionTitle: {
    '&:first-letter': {
      textTransform: 'capitalize',
    },
    '& span': {
      fontWeight: 'bold',
      fontSize: rem(theme.generalStyles.mediumTextSize),
    },
  },
}));

export default function Menu({ name, path, text, icon, handleClick }: Props) {
  const classes = useStyles();

  return (
    <ListItem
      component={Link}
      to={path}
      button
      key={name}
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        handleClick(e, name);
      }}
      data-testid={`menu-item-${name}`}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        primary={text}
        classes={{
          root: classes.sectionTitle,
        }}
      />
    </ListItem>
  );
}
