import { ApolloQueryResult } from '@apollo/client';
import { Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { rem } from '../app/AppUtilities';
import { useSettings } from '../app/SettingsContext';
import { useTraductions } from '../app/TraductionsContext';
import SubTitle from '../orderDetail/SubTitle';
import { TraductionsType } from '../traductions';
import DecorationLine, { DecorationLineVariant } from './DecorationLine';
import DelaySelection from './DelaySelection';
import { Data, Variables } from './PrepTimeTypes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      marginBottom: rem(40),
      display: 'grid',
      flexBasis: '95%',
      fontSize: rem(theme.generalStyles.biggerTextSize),
      height: rem(325),
      gridTemplateColumns: '5% 55% 20% 20%',
      gridTemplateRows: '1fr 28% 28% 25%',
      [theme.breakpoints.up('md')]: {
        '&:first-child': {
          marginRight: 'auto',
        },
        gridTemplateColumns: '5% 45% 25% 25%',
        flexBasis: '46%',
      },
    },
    invisibleDelivery: {
      display: 'none',
    },
    isCentered: {
      marginLeft: 'auto',
    },
    initialRow: {
      margin: `0 0 ${rem(theme.spacing(2))}`,
      alignSelf: 'end',
      color: theme.palette.secondary.light,
      fontWeight: 400,
    },
    temporaryRow: {
      margin: `${rem(theme.spacing(2))} 0 0`,
      fontWeight: 400,
    },
    totalRow: {
      fontWeight: 'bold',
    },
    sectionTitle: {
      gridColumn: '1/5',
      gridRowStart: 1,
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
    redLinePosition: {
      gridColumn: '1/5',
      gridRow: '1/2',
      alignSelf: 'end',
    },
    grayLinePosition: {
      gridColumn: '2/5',
      gridRow: '4/5',
      alignSelf: 'start',
    },
    lightGrayLinePosition: {
      gridColumn: '1/2',
      gridRow: '2/5',
      alignSelf: 'start',
      width: '60%',
      height: '90%',
    },
    subTitleInitial: {
      fontSize: rem(theme.generalStyles.biggerTextSize),
      paddingLeft: rem(theme.spacing(2)),
      gridColumn: '2/3',
      gridRow: '2/3',
    },
    subTitleTemporary: {
      fontSize: rem(theme.generalStyles.biggerTextSize),
      paddingLeft: rem(theme.spacing(2)),
      gridColumn: '2/3',
      gridRow: '3/4',
    },
    subTitleTotal: {
      fontSize: rem(theme.generalStyles.biggerTextSize),
      paddingLeft: rem(theme.spacing(2)),
      gridColumn: '2/3',
      gridRow: '4/5',
    },
    valueInitial: {
      gridColumn: '3/4',
      gridRow: '2/3',
    },
    valueTemporary: {
      gridColumn: '3/4',
      gridRow: '3/4',
    },
    valueTotal: {
      gridColumn: '3/4',
      gridRow: '4/5',
    },
    unitsInitial: {
      gridColumn: '4/5',
      gridRow: '2/3',
    },
    unitsTemporary: {
      gridColumn: '4/5',
      gridRow: '3/4',
    },
    unitsTotal: {
      gridColumn: '4/5',
      gridRow: '4/5',
    },
    valueColumn: {
      justifySelf: 'center',
    },
    unitsColumn: {
      justifySelf: 'end',
      textTransform: 'uppercase',
      [theme.breakpoints.up('sm')]: {
        paddingRight: rem(theme.spacing(2)),
      },
    },
  })
);

export enum DelayType {
  Base = 'base',
  Delivery = 'delivery',
}

const initialSubTitle = 'Initial';
const totalSubTitle = 'Total';
const units = 'minutes';

interface Props {
  variant: DelayType;
  delaysData: Data;
  refetchDelays: (variables?: Variables | undefined) => Promise<ApolloQueryResult<Data>>;
  setMutationError: (mutationError: string) => void;
}

export default function DelayContainer({ variant, delaysData, refetchDelays, setMutationError }: Props) {
  const settings = useSettings();
  const traductions = useTraductions();
  const classes = useStyles();
  const containerTitle = getContainerTitle(variant, traductions);

  const hasDelivery =
    settings.currentBranch?.services && !!settings.currentBranch.services.find((s) => s.type === 'DELIVERY');
  const displayData =
    variant === DelayType.Base ? delaysData.preparationTime.base : delaysData.preparationTime.delivery;
  const isInvisible = !hasDelivery && variant === DelayType.Delivery;
  const isCentered = !hasDelivery && variant === DelayType.Base;

  return (
    <div
      className={classNames(
        classes.gridContainer,
        isInvisible && classes.invisibleDelivery,
        isCentered && classes.isCentered
      )}
    >
      <Typography variant="h4" className={classes.sectionTitle}>
        {containerTitle}
      </Typography>
      <DecorationLine variant={DecorationLineVariant.Red} className={classes.redLinePosition} />
      <h3 className={classNames(classes.initialRow, classes.subTitleInitial)}>{initialSubTitle}</h3>
      <p
        className={classNames(classes.initialRow, classes.valueInitial, classes.valueColumn)}
        data-testid={`prep-time-initial-${variant}-delay`}
      >
        {displayData.permanentMinutes}
      </p>
      <p className={classNames(classes.initialRow, classes.unitsInitial, classes.unitsColumn)}>{units}</p>
      <h3 className={classNames(classes.temporaryRow, classes.subTitleTemporary)}>
        {traductions.preparationTimePage.temporary}
      </h3>
      <DelaySelection
        className={classNames(classes.valueTemporary, classes.valueColumn)}
        variant={variant}
        initialValue={displayData.temporaryMinutes}
        delaysData={delaysData}
        refetchDelays={refetchDelays}
        setMutationError={setMutationError}
      />
      <p className={classNames(classes.unitsTemporary, classes.unitsColumn, classes.temporaryRow)}>{units}</p>
      <DecorationLine variant={DecorationLineVariant.Gray} className={classes.grayLinePosition} />
      <h3 className={classNames(classes.totalRow, classes.subTitleTotal)}>{totalSubTitle}</h3>
      <p
        className={classNames(classes.totalRow, classes.valueTotal, classes.valueColumn)}
        data-testid={`prep-time-total-${variant}-delay`}
      >
        {displayData.totalMinutes}
      </p>
      <p className={classNames(classes.totalRow, classes.unitsTotal, classes.unitsColumn)}>{units}</p>
      <DecorationLine variant={DecorationLineVariant.LightGray} className={classes.lightGrayLinePosition} />
    </div>
  );
}

function getContainerTitle(variant: DelayType, traductions: TraductionsType) {
  switch (variant) {
    case DelayType.Base:
      return traductions.preparationTimePage.baseTitle;
    case DelayType.Delivery:
      return traductions.preparationTimePage.deliveryTitle;
    default:
      return traductions.preparationTimePage.baseTitle;
  }
}
