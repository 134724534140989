import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import React, { useState } from 'react';
import { rem } from '../app/AppUtilities';
import { useTraductions } from '../app/TraductionsContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: `${rem(2)} ${rem(4)}`,
      margin: `${rem(theme.spacing(3))} 0`,
      display: 'flex',
      alignItems: 'center',
      width: rem(400),
      color: theme.palette.secondary.main,
      border: `${rem(2)} solid transparent`,
      transition: 'border-color 0.3s',
    },
    input: {
      marginLeft: rem(theme.spacing(1)),
      flex: 1,
      fontSize: rem(theme.generalStyles.mediumTextSize),
    },
    iconButton: {
      padding: rem(10),
    },
    focus: {
      border: `${rem(2)} solid ${theme.palette.primary.main}`,
    },
  })
);

const debounce = (func: Function, delay: number) => {
  let inDebounce: NodeJS.Timeout;
  return function (this: (func: Function, delay: number) => Function) {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    args[0].persist();
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};

interface Props {
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}

export default function SearchBar({ setSearch }: Props) {
  const classes = useStyles();
  const traductions = useTraductions();
  const capitalizedPlaceholder = capitalizePlaceholder(traductions.menuItemsPage.searchInput);
  const [focusState, setFocusState] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleFocus = () => {
    setFocusState((prevState) => !prevState);
  };

  return (
    <Paper className={classNames(classes.root, focusState && classes.focus)}>
      <InputBase
        className={classes.input}
        placeholder={capitalizedPlaceholder}
        onChange={debounce(handleChange, 500)}
        onFocus={handleFocus}
        onBlur={handleFocus}
        inputProps={{
          'data-testid': 'menu-items-input',
        }}
      />
      <SearchIcon />
    </Paper>
  );
}

function capitalizePlaceholder(placeholder: string) {
  return placeholder.charAt(0).toUpperCase() + placeholder.slice(1);
}
