import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { rem } from '../../app/AppUtilities';

export interface DrawerContentProps {
  children?: ReactNode;
}

export default function DrawerContent({ children }: DrawerContentProps) {
  return (
    <Box
      sx={{
        height: '100%',
        padding: `0 ${rem(56)}`,
        overflowY: 'auto',
      }}
    >
      {children}
    </Box>
  );
}
