import { Culture } from '../app/SettingsContext';
import { I18NName, Language } from '../types';

export const getCultureText = (i18nString: string | I18NName, culture: Culture): string => {
  const language = parseCultureCode(culture);
  const values = parseData(i18nString);
  const text = values[language];

  if (text) {
    return text;
  }

  for (const lang of Object.keys(values)) {
    const text = values[lang as Language];
    if (text) {
      return text;
    }
  }

  return '';
};

function parseCultureCode(culture: Culture): Language {
  return culture.substring(0, 2).toLocaleLowerCase() as Language;
}

function parseData(i18nString?: string | I18NName): I18NName {
  if (!i18nString) {
    return {};
  }

  if (typeof i18nString === 'string') {
    try {
      return JSON.parse(i18nString) as I18NName;
    } catch {
      return { fr: i18nString };
    }
  }

  return i18nString;
}
