export interface PreparationTimePageType {
  baseTitle: string;
  deliveryTitle: string;
  totalDelayTitle: string;
  initial: string;
  temporary: string;
  loading: string;
  error: string;
  mutationError: string;
  expiration: string;
  minutes: string;
}

export const enCa: PreparationTimePageType = {
  baseTitle: 'base delay',
  deliveryTitle: 'delivery delay',
  totalDelayTitle: 'expected total delay',
  initial: 'initial',
  temporary: 'Temporary',
  loading: 'loading preparation delays...',
  error: 'Error: could not retrieve preparation time.',
  mutationError: 'temporary preparation time could not be changed',
  expiration: 'temporary delays will expire on :',
  minutes: 'minutes',
};

export const frCa: PreparationTimePageType = {
  baseTitle: 'délais de base',
  deliveryTitle: 'délais de livraison',
  totalDelayTitle: 'délai total prévu',
  initial: 'initial',
  temporary: 'Temporaire',
  loading: 'chargement des délais de préparation...',
  error: "erreur: incapable d'obtenir les temps de préparation.",
  mutationError: "le temps de préparation temporaire n'a pu être changé",
  expiration: 'les délais temporaires expireront le :',
  minutes: 'minutes',
};
