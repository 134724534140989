import moment from 'moment';
import { PauseBannerType } from '../../traductions/pauseBanner';
import { PauseChannelLabel } from '../pauseChannelDrawer';
import { calculateDifferenceFromNow, getTranslatedChannelName } from '../util/utils';
import { SelectableTileListData } from '../../app';
import { ChannelStates, OrderChannelEnum, PauseStateStatus } from '../types/PauseTypes';
import { SelectableTileListItemElement } from '../../app/selectableTileList/selectableTileListItem/SelectableTileListItem';

export type PauseChannelDrawerStep = 'channelsSelection' | 'delaySelection';

interface PauseChannelDrawerReducerParams {
  channelList: ChannelStates[];
  traductions: PauseBannerType;
}

export interface ChannelStatusItem extends ChannelStates {
  channelName: string;
  selected: boolean;
  pending: boolean;
}

export interface PauseChannelDrawerState {
  channelList: ChannelStates[];
  currentStep: PauseChannelDrawerStep;
  delaysList: SelectableTileListData;
  activeChannelList: ChannelStatusItem[];
  pausedChannelList: ChannelStatusItem[];
  selectedChannelsToPauseIdsList: OrderChannelEnum[];
  selectedChannelsToResumeIdsList: OrderChannelEnum[];
  isActiveListDisabled: boolean;
  isPausedListDisabled: boolean;
  label: PauseChannelLabel;
  showApplyToAllPausedChannelsCheckbox: boolean;
  traductions: PauseBannerType;
  synchronizeDelay: boolean;
}

export enum PauseChannelDrawerReducerType {
  CHANNEL_SELECTED = 'pauseChannelDrawer/onChannelSelected',
  DELAY_SELECTED = 'pauseChannelDrawer/onDelaySelected',
  SYNCHRONIZE_DELAY_CHANGED = 'pauseChannelDrawer/onSynchronizeDelayChanged',
  TIME_EXPIRY = 'pauseChannelDrawer/onTimeExpiry',
  NEXT_ACTION = 'pauseChannelDrawer/onNextAction',
  BACK_ACTION = 'pauseChannelDrawer/onBackAction',
  INITIALIZE = 'pauseChannelDrawer/initialize',
}

export type PauseChannelDrawerAction =
  | {
      type: PauseChannelDrawerReducerType.CHANNEL_SELECTED;
      payload: {
        channelId: string;
      };
    }
  | {
      type: PauseChannelDrawerReducerType.DELAY_SELECTED;
      payload: {
        delay: string;
      };
    }
  | {
      type: PauseChannelDrawerReducerType.SYNCHRONIZE_DELAY_CHANGED;
      payload: {
        synchronizeDelay: boolean;
      };
    }
  | {
      type: PauseChannelDrawerReducerType.TIME_EXPIRY;
      payload: {
        channelId: string;
      };
    }
  | {
      type: PauseChannelDrawerReducerType.NEXT_ACTION;
    }
  | {
      type: PauseChannelDrawerReducerType.BACK_ACTION;
    }
  | {
      type: PauseChannelDrawerReducerType.INITIALIZE;
      payload: PauseChannelDrawerReducerParams;
    };

// Note: This variable exist only because of TypeScript does not understand filter correctly
const dateInThePast = moment().subtract(15, 'second').toISOString();

const filterPausedList = (channelList: ChannelStates[], traductions: PauseBannerType): ChannelStatusItem[] => {
  const pausedChannels = channelList
    .filter((channel) => !!channel.pausedUntil)
    .filter((channel) => calculateDifferenceFromNow(channel.pausedUntil || dateInThePast) > 0)
    .map((item) => {
      return {
        ...item,
        channelName: getTranslatedChannelName(item.channel, traductions),
        selected: false,
        pending: item.status === PauseStateStatus.Pending,
      };
    });

  return pausedChannels;
};

const filterActiveList = (
  channelList: ChannelStates[],
  traductions: PauseBannerType,
  pausedList: ChannelStates[]
): ChannelStatusItem[] => {
  const activeChannels = channelList
    .filter((channel) => !pausedList.find((item) => item.channel === channel.channel))
    .map((item) => {
      return {
        ...item,
        channelName: getTranslatedChannelName(item.channel, traductions),
        selected: false,
        pending: item.status === PauseStateStatus.Pending,
      };
    });

  return activeChannels;
};

const pausedChannelsCheckboxEnabled = (
  pausedChannelList: ChannelStatusItem[],
  delaysListElements: SelectableTileListItemElement[]
) => {
  return pausedChannelList?.length > 0 && delaysListElements.some((delay) => delay.selected);
};
export const pauseChannelDrawerReducer = (
  state: PauseChannelDrawerState,
  action: PauseChannelDrawerAction
): PauseChannelDrawerState => {
  switch (action.type) {
    case PauseChannelDrawerReducerType.CHANNEL_SELECTED: {
      const newActiveList = state.activeChannelList.map((item) =>
        item.channel !== action.payload.channelId
          ? item
          : {
              ...item,
              selected: !item.selected,
            }
      );
      const newPausedList = state.pausedChannelList.map((item) =>
        item.channel !== action.payload.channelId
          ? item
          : {
              ...item,
              selected: !item.selected,
            }
      );
      const activeSelectedChannels = newActiveList
        .filter((channel) => channel.selected)
        .map((channel) => channel.channel);
      const pausedSelectedChannels = newPausedList
        .filter((channel) => channel.selected)
        .map((channel) => channel.channel);

      const isActiveAnySelected = activeSelectedChannels.length > 0;
      const isPausedAnySelected = pausedSelectedChannels.length > 0;

      const actionButtonNext = {
        ...state.label.actionButtonNext,
        primaryButton: isPausedAnySelected
          ? state.traductions.pauseChannelActionButtonResume
          : isActiveAnySelected
          ? state.traductions.pauseChannelActionButtonNext
          : undefined,
      };

      return {
        ...state,
        label: {
          ...state.label,
          actionButtonNext,
        },
        activeChannelList: newActiveList,
        pausedChannelList: newPausedList,
        selectedChannelsToPauseIdsList: activeSelectedChannels,
        selectedChannelsToResumeIdsList: pausedSelectedChannels,
        isActiveListDisabled: isPausedAnySelected,
        isPausedListDisabled: isActiveAnySelected,
      };
    }

    case PauseChannelDrawerReducerType.DELAY_SELECTED: {
      const { delay } = action.payload;
      const delaysListElements = state.delaysList.elements.map((currentDelay) =>
        currentDelay.value === delay ? { ...currentDelay, selected: true } : { ...currentDelay, selected: false }
      );

      return {
        ...state,
        label: {
          ...state.label,
          actionButtonNext: {
            ...state.label.actionButtonNext,
            primaryButton: state.traductions.pauseChannelDelayActionButtonNext,
          },
        },
        delaysList: {
          ...state.delaysList,
          elements: delaysListElements,
        },
        showApplyToAllPausedChannelsCheckbox: pausedChannelsCheckboxEnabled(
          state.pausedChannelList,
          delaysListElements
        ),
      };
    }

    case PauseChannelDrawerReducerType.SYNCHRONIZE_DELAY_CHANGED: {
      const { synchronizeDelay } = action.payload;

      return {
        ...state,
        synchronizeDelay,
      };
    }

    case PauseChannelDrawerReducerType.BACK_ACTION: {
      const currentStep = 'channelsSelection';
      const title = state.traductions.pauseChannelTitle;
      const actionButtonNext = {
        ...state.label.actionButtonNext,
        primaryButton: state.traductions.pauseChannelActionButtonNext,
        secondaryButton: undefined,
      };
      return {
        ...state,
        label: {
          ...state.label,
          title,
          actionButtonNext,
        },
        currentStep,
      };
    }

    case PauseChannelDrawerReducerType.NEXT_ACTION: {
      let currentStep = state.currentStep;
      let title = state.label.title;
      let actionButtonNext = state.label.actionButtonNext;
      if (state.currentStep === 'channelsSelection') {
        const delaySelected = state.delaysList.elements.some((delay) => delay.selected);
        currentStep = 'delaySelection';
        title = state.traductions.pauseChannelDelayTitle;
        actionButtonNext = {
          ...state.label.actionButtonNext,
          primaryButton: delaySelected ? state.traductions.pauseChannelDelayActionButtonNext : undefined,
          secondaryButton: state.traductions.pauseChannelActionButtonBack,
        };
      }

      return {
        ...state,
        label: {
          ...state.label,
          title,
          actionButtonNext,
        },
        currentStep,
      };
    }

    case PauseChannelDrawerReducerType.TIME_EXPIRY: {
      const newPausedList = filterPausedList(state.channelList, state.traductions);
      const newActiveList = filterActiveList(state.channelList, state.traductions, newPausedList);

      return {
        ...state,
        label: {
          ...state.label,
          actionButtonNext: {
            ...state.label.actionButtonNext,
            primaryButton: undefined,
            secondaryButton: undefined,
          },
        },
        activeChannelList: newActiveList,
        pausedChannelList: newPausedList,
        isActiveListDisabled: false,
        isPausedListDisabled: false,
        selectedChannelsToPauseIdsList: [],
        selectedChannelsToResumeIdsList: [],
      };
    }

    case PauseChannelDrawerReducerType.INITIALIZE: {
      return {
        ...state,
        ...initializeState(action.payload),
      };
    }

    default:
      return state;
  }
};

export const initializeState = ({
  channelList,
  traductions,
}: PauseChannelDrawerReducerParams): PauseChannelDrawerState => {
  const pausedList = filterPausedList(channelList, traductions);
  const activeList = filterActiveList(channelList, traductions, pausedList);
  const delaysList = {
    elements: [
      { label: traductions.pauseChannelDelay30min, value: 'HALF_HOUR', selected: false },
      { label: traductions.pauseChannelDelay60min, value: 'HOUR', selected: false },
      { label: traductions.pauseChannelDelay120min, value: 'TWO_HOURS', selected: false },
      { label: traductions.pauseChannelDelayUntilTomorrow, value: 'TOMORROW', selected: false },
    ],
  };
  const label: PauseChannelLabel = {
    title: traductions.pauseChannelTitle,
    actionButtonNext: {
      primaryButton: undefined,
      secondaryButton: undefined,
    },
    delaySelectionLabel: {
      applyToAllPausedChannels: traductions.pauseChannelDelayApplyToAllPausedChannels,
    },
    listContainerLabel: {
      activeLabelList: {
        title: traductions.pauseChannelListActiveTitle,
        pauseChannelListItem: {
          pauseChannelChipLabel: {
            active: traductions.pauseChannelActive,
          },
        },
      },
      pausedLabelList: {
        title: traductions.pauseChannelListPausedTitle,
        pauseChannelListItem: {
          pauseChannelChipLabel: {
            active: traductions.pauseChannelActive,
          },
        },
      },
    },
  };

  let currentStep: PauseChannelDrawerStep = 'channelsSelection';
  let selectedChannelsToPauseIdsList: OrderChannelEnum[] = [];
  if (channelList.length === 1 && activeList.length === 1) {
    currentStep = 'delaySelection';
    selectedChannelsToPauseIdsList = [channelList[0].channel];
  }

  return {
    channelList,
    currentStep,
    delaysList,
    activeChannelList: activeList,
    pausedChannelList: pausedList,
    selectedChannelsToPauseIdsList,
    selectedChannelsToResumeIdsList: [],
    isActiveListDisabled: false,
    isPausedListDisabled: false,
    label,
    showApplyToAllPausedChannelsCheckbox: pausedChannelsCheckboxEnabled(pausedList, delaysList.elements),
    traductions,
    synchronizeDelay: false,
  };
};
