import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { rem } from '../../app/AppUtilities';

export interface DrawerHeaderProps {
  children?: ReactNode;
  onClose: () => void;
}

export default function DrawerHeader({ children, onClose }: DrawerHeaderProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        gap: rem(20),
        padding: `${rem(20)} ${rem(56)}`,
      }}
    >
      <Typography
        variant="h4"
        sx={(theme) => ({
          fontWeight: '700',
          fontSize: rem(30),
          lineHeight: rem(41),
          color: theme.palette.text.default,
        })}
      >
        {children}
      </Typography>
      <IconButton
        onClick={onClose}
        sx={(theme) => ({
          color: theme.palette.text.default,
          fontSize: theme.generalStyles.baseTextSize,
          fontWeight: 900,
          padding: rem(10),
          backgroundColor: theme.palette.background.paper,
          borderRadius: rem(8),
          height: rem(40),
          width: rem(40),
        })}
      >
        <FontAwesomeIcon icon={faXmark} />
      </IconButton>
    </Box>
  );
}
