import { PauseChannelListContainerData, PauseChannelListContainerLabel } from './PauseChannelListContainer';
import { PauseChannelListMock } from '../pauseChannelList';
import moment from 'moment';

export const label: PauseChannelListContainerLabel = {
  activeLabelList: PauseChannelListMock.activeLabel,
  pausedLabelList: PauseChannelListMock.pausedLabel,
};

const defaultData: PauseChannelListContainerData = {
  activeChannelList: PauseChannelListMock.activeListData.channelStatus,
  activeListDisabled: false,
  pausedChannelList: PauseChannelListMock.pausedListData.channelStatus,
  pausedListDisabled: false,
};

const activeListDisabled: PauseChannelListContainerData = {
  ...defaultData,
  activeListDisabled: true,
};

const pausedListDisabled: PauseChannelListContainerData = {
  ...defaultData,
  pausedListDisabled: true,
};

const pendingData: PauseChannelListContainerData = {
  activeChannelList: [
    {
      channel: 'UBER_EATS',
      channelName: 'UberEats',
      pending: true,
    },
    {
      channel: 'DOOR_DASH',
      channelName: 'DoorDash',
    },
  ],
  activeListDisabled: false,
  pausedChannelList: [
    {
      channel: 'SKIP_THE_DISHES',
      channelName: 'SkipTheDishes',
      pausedUntil: moment().add(35, 'minute').toISOString(),
      pending: true,
    },
    {
      channel: 'DOOR_DASH',
      channelName: 'DoorDash',
      pausedUntil: moment().add(30, 'minute').toISOString(),
    },
  ],
  pausedListDisabled: false,
};

export const PauseChannelListContainerMock = {
  label,
  defaultData,
  activeListDisabled,
  pausedListDisabled,
  pendingData,
};
