import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';
import { rem } from '../../app/AppUtilities';

export default function DrawerFooter({ children }: PropsWithChildren<{}>) {
  return (
    <Box
      sx={{
        padding: `${rem(28)} ${rem(56)} ${rem(32)}`,
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      {children}
    </Box>
  );
}
