import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import { amber } from '@mui/material/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { rem } from '../../app/AppUtilities';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editButton: {
      borderRadius: rem(8),
      padding: rem(7),
      background: amber[700],
      width: rem(36),
      height: rem(36),
    },
    editIcon: {
      left: rem(10),
      top: rem(10),
      width: rem(16),
      height: rem(16),
    },
  })
);
export const EditButton = () => {
  const classes = useStyles();
  return (
    <div className={classes.editButton}>
      <FontAwesomeIcon icon={faPenToSquare as IconProp} className={classes.editIcon} />
    </div>
  );
};
