import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, Theme, Button } from '@mui/material';

import { useTraductions } from '../app/TraductionsContext';
import { useSettings } from '../app/SettingsContext';

import { rem } from '../app/AppUtilities';

type Props = {
  setOpen: (value: boolean) => void;
  isOpen: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      paddingBottom: theme.spacing(1),
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: 1.43,
      marginLeft: rem(theme.spacing(3)),
      fontSize: rem(theme.generalStyles.biggerTextSize),
      fontWeight: 'bold',
      padding: rem(theme.generalStyles.containerPadding),
      borderLeft: `${rem(10)} solid ${theme.palette.primary.main}`,
    },
    titleText: {
      paddingLeft: rem(theme.generalStyles.containerPadding),
      display: 'inline-block',
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
    button: {
      fontSize: rem(theme.generalStyles.mediumTextSize),
      fontWeight: 'bold',
      padding: theme.generalStyles.modalButtonPadding,
    },
  })
);

// Welcome message will make sure one user interaction will be done and we can play sounds
// Otherwise, the notification sound will be unreliable, even with `mediaPlaybackRequiresUserAction={false}` on the WebView
// In latest version, we use naitve sounds so it does not need the welcome screen
export default function WelcomeModal({ setOpen, isOpen }: Props) {
  const settings = useSettings();
  const traductions = useTraductions();
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  if (!settings.authToken) {
    return null;
  }

  return (
    <Dialog open={isOpen} maxWidth="sm" fullWidth>
      <DialogTitle
        classes={{
          root: classes.title,
        }}
      >
        <span className={classes.titleText}>{traductions.welcomeModal.title}</span>
      </DialogTitle>
      <DialogContent
        classes={{
          root: classes.content,
        }}
      >
        {traductions.welcomeModal.message}
      </DialogContent>
      <DialogActions>
        <Button
          classes={{
            root: classes.button,
          }}
          variant="contained"
          onClick={handleClose}
          color="secondary"
        >
          {traductions.welcomeModal.start}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
