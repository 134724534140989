import React from 'react';
import { useTraductions } from '../app/TraductionsContext';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TimerIcon from '@mui/icons-material/TimerOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import DescriptionIcon from '@mui/icons-material/Description';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import { rem } from '../app/AppUtilities';
import { useSettings } from '../app/SettingsContext';

const useStyles = makeStyles((theme: Theme) => ({
  sectionTitle: {
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  menuIcons: {
    height: rem(theme.generalStyles?.mediumIconSize),
    width: rem(theme.generalStyles?.mediumIconSize),
  },
}));

export enum SectionShortName {
  NewOrders = 'orders',
  PreOrders = 'preorders',
  ProcessedOrders = 'processedorders',
  MenuItems = 'menuitems',
  DeliveryPartners = 'deliveryitems',
  PreparationTime = 'preptime',
  Support = 'support',
  Settings = 'settings',
  OrderDetail = 'detail',
}

export interface SectionObjectType {
  name: string;
  path: string;
  headerTitle: string;
  menuTitle: string;
  icon: React.ReactElement;
  inMenu: boolean;
}

export function Sections(): SectionObjectType[] {
  const traductions = useTraductions();
  const classes = useStyles();
  const settings = useSettings();

  function hasExternalDeliveryProvider() {
    return (
      settings.currentBranch &&
      settings.currentBranch.services &&
      settings.currentBranch.services.filter((x) => x.type === 'DELIVERY').length > 0 &&
      settings.currentBranch.services.filter((x) => x.type === 'DELIVERY')[0].hasExternalDeliveryProvider
    );
  }

  function isAdminUser() {
    return settings.userRole && (settings.userRole === 'admin' || settings.userRole === 'global-admin');
  }

  let sections = ReadOnlySections();
  sections.push({
    name: SectionShortName.MenuItems,
    path: '/menuitems',
    headerTitle: traductions.menuItems.menuTitle,
    menuTitle: traductions.menuItems.menuTitle,
    icon: (
      <ListAltIcon
        classes={{
          root: classes.menuIcons,
        }}
      />
    ),
    inMenu: true,
  });

  if (isAdminUser() && hasExternalDeliveryProvider()) {
    sections.push({
      name: SectionShortName.DeliveryPartners,
      path: '/deliverypartners',
      headerTitle: traductions.menuItems.deliveryPartnerTitle,
      menuTitle: traductions.menuItems.deliveryPartnerTitle,
      icon: (
        <LocalShippingIcon
          classes={{
            root: classes.menuIcons,
          }}
        />
      ),
      inMenu: true,
    });
  }

  sections.push({
    name: SectionShortName.PreparationTime,
    path: '/preptime',
    headerTitle: traductions.menuItems.timeTitle,
    menuTitle: traductions.menuItems.timeTitle,
    icon: (
      <TimerIcon
        classes={{
          root: classes.menuIcons,
        }}
      />
    ),
    inMenu: true,
  });
  return sections;
}

export function ReadOnlySections(): SectionObjectType[] {
  const traductions = useTraductions();
  const classes = useStyles();

  return [
    {
      name: SectionShortName.NewOrders,
      path: '/orders/pending',
      headerTitle: traductions.ordersListPage.orderNewTitle,
      menuTitle: traductions.menuItems.ordersTitle,
      icon: (
        <AttachMoneyIcon
          classes={{
            root: classes.menuIcons,
          }}
        />
      ),
      inMenu: true,
    },
    {
      name: SectionShortName.PreOrders,
      path: '/orders/preorders',
      headerTitle: traductions.ordersListPage.orderPreorderedTitle,
      menuTitle: traductions.menuItems.preordersTitle,
      icon: (
        <AttachMoneyIcon
          classes={{
            root: classes.menuIcons,
          }}
        />
      ),
      inMenu: false,
    },
    {
      name: SectionShortName.ProcessedOrders,
      path: '/orders/processed',
      headerTitle: traductions.ordersListPage.orderProcessedTitle,
      menuTitle: traductions.menuItems.processedOrdersTitle,
      icon: (
        <AttachMoneyIcon
          classes={{
            root: classes.menuIcons,
          }}
        />
      ),
      inMenu: false,
    },
    {
      name: SectionShortName.OrderDetail,
      path: '/order/',
      headerTitle: traductions.menuItems.detailTitle,
      menuTitle: traductions.menuItems.detailTitle,
      icon: (
        <DescriptionIcon
          classes={{
            root: classes.menuIcons,
          }}
        />
      ),
      inMenu: false,
    },
    {
      name: SectionShortName.Settings,
      path: '/settings',
      headerTitle: traductions.menuItems.settingsTitle,
      menuTitle: traductions.menuItems.settingsTitle,
      icon: (
        <SettingsIcon
          classes={{
            root: classes.menuIcons,
          }}
        />
      ),
      inMenu: false,
    },
    {
      name: SectionShortName.Support,
      path: '/support',
      headerTitle: traductions.menuItems.supportTitle,
      menuTitle: traductions.menuItems.supportTitle,
      icon: (
        <HelpOutlineIcon
          classes={{
            root: classes.menuIcons,
          }}
        />
      ),
      inMenu: true,
    },
  ];
}
