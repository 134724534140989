import { PauseState, ChannelStates, OrderChannelEnum } from '../types/PauseTypes';

export enum PauseReducerActionType {
  SET_PAUSE_STATE = 'pause/onSetPauseState',
  OPEN_RESUME_DIALOG = 'pause/onOpenResumeDialog',
  CLOSE_RESUME_DIALOG = 'pause/onCloseResumeDialog',
  OPEN_DRAWER = 'pause/onOpenDrawer',
  CLOSE_DRAWER = 'pause/onCloseDrawer',
  ENABLE_ANIMATION = 'pause/enableAnimation',
  DISABLE_ANIMATION = 'pause/disableAnimation',
}
export type PauseReducerAction =
  | {
      type: PauseReducerActionType.SET_PAUSE_STATE;
      payload: {
        branchId: number | undefined;
        pauseStates: ChannelStates[];
        branchExternalChannels?: OrderChannelEnum[];
      };
    }
  | {
      type: PauseReducerActionType.OPEN_DRAWER;
    }
  | {
      type: PauseReducerActionType.CLOSE_DRAWER;
    }
  | {
      type: PauseReducerActionType.OPEN_RESUME_DIALOG;
      payload: {
        channelsToResume: OrderChannelEnum[];
      };
    }
  | {
      type: PauseReducerActionType.CLOSE_RESUME_DIALOG;
    }
  | {
      type: PauseReducerActionType.ENABLE_ANIMATION;
    }
  | {
      type: PauseReducerActionType.DISABLE_ANIMATION;
    };
export const PauseReducer = (state: PauseState, action: PauseReducerAction): PauseState => {
  switch (action.type) {
    case PauseReducerActionType.SET_PAUSE_STATE:
      const {
        payload: { branchId, pauseStates, branchExternalChannels },
      } = action;
      const allChannelKeys = [...(branchExternalChannels ?? []), OrderChannelEnum.WEB];
      const finalChannelList = allChannelKeys
        .map((channel) => {
          const pauseState = pauseStates.find((item) => item.channel == channel);
          return {
            channel,
            pausedUntil: pauseState?.pausedUntil,
            status: pauseState?.status,
            statusCreatedAt: pauseState?.statusCreatedAt,
          };
        })
        .sort(function (a, b) {
          if (a.pausedUntil && b.pausedUntil)
            return new Date(a.pausedUntil).getTime() - new Date(b.pausedUntil).getTime();
          else return 0;
        });
      return {
        ...state,
        branchId,
        channelList: finalChannelList,
        pauseTimerDisabled: !!(finalChannelList.length === 1),
      };
    case PauseReducerActionType.OPEN_DRAWER:
      return {
        ...state,
        drawerOpen: true,
      };
    case PauseReducerActionType.CLOSE_DRAWER:
      return {
        ...state,
        drawerOpen: false,
      };
    case PauseReducerActionType.OPEN_RESUME_DIALOG: {
      const { channelsToResume } = action.payload;
      return {
        ...state,
        resumeDialogOpen: true,
        channelsToResume,
      };
    }
    case PauseReducerActionType.CLOSE_RESUME_DIALOG:
      return {
        ...state,
        resumeDialogOpen: false,
        channelsToResume: [],
      };
    case PauseReducerActionType.ENABLE_ANIMATION:
      return {
        ...state,
        isAnimationEnabled: true,
      };
    case PauseReducerActionType.DISABLE_ANIMATION:
      return {
        ...state,
        isAnimationEnabled: false,
      };
    default:
      return state;
  }
};
