import { useCustomEventListener } from 'react-custom-events';
import { useDeviceData } from 'react-device-detect';
import useNativeEvent from '../../useNativeEvent';
import { EventTypes } from '../EventTypes';
import { getLogLevelFromEvent, LogEvent } from './LogEvent';
import { getBaseApiUrl } from '../../utils/env';

export function useLogEvents() {
  const deviceInfo = useDeviceData(window?.navigator?.userAgent);
  const apiUrl = getBaseApiUrl();

  useCustomEventListener<LogEvent>(
    EventTypes.LOG,
    (event) => {
      saveLog(event, apiUrl, deviceInfo);
    },
    [apiUrl, deviceInfo]
  );

  useNativeEvent(EventTypes.LOG, (event) => {
    saveLog(event as LogEvent, apiUrl, deviceInfo);
  });
}

async function saveLog(event: LogEvent, apiUrl: string, deviceInfo: any) {
  const settingsStr = localStorage.getItem('uboard-settings');
  const settings = settingsStr ? JSON.parse(settingsStr) : {};
  const currentBranchId = settings.currentBranchId;

  const logLevel = getLogLevelFromEvent(event);
  const url = `${apiUrl}/log`;
  try {
    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        companyId: currentBranchId,
        logLevel,
        message: event.message,
        extras: {
          error: event.error,
          browser: deviceInfo.browser?.name,
          browserVersion: deviceInfo.browser?.version,
          os: deviceInfo.os.name,
          osVersion: deviceInfo.os?.version,
          vendor: deviceInfo.device?.vendor,
          model: deviceInfo.device?.model,
          ...event.extras,
        },
      }),
    });
  } catch (error) {
    console.log(error);
  }
}
