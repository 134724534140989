import { withAuthenticationRequired } from 'react-oidc-context';
import { Redirect, Route, RouteProps } from 'react-router';
import { useSettings } from './SettingsContext';

interface Props extends RouteProps {
  component: any;
}

export default function PrivateRoute({ component: Component, ...rest }: Props) {
  const settings = useSettings();

  return (
    <Route
      {...rest}
      render={(props) =>
        settings.authToken ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export const PrivateRouteNewUI = withAuthenticationRequired(Route);
