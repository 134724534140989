import React from 'react';
import { Order, Fees, BagType, FeeType } from './OrderDetailTypes';
import { useTraductions } from '../app/TraductionsContext';
import FormattedPrice from '../formatted/FormattedPrice';
import SeparationLine from './SeparationLine';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ItemElement, { ElementType } from './ItemElement';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flexShrink: 0,
      flexBasis: 'auto',
    },
  })
);

export enum Fee {
  Application = 'application',
  Delivery = 'delivery',
  Allergy = 'allergy',
}

interface Props {
  orderData: Order;
  currency: string;
}

export default function FeesList({ orderData, currency }: Props) {
  const classes = useStyles();
  const traductions = useTraductions();

  if (!orderData.fees) {
    return null;
  }

  const fees: Fees = orderData.fees;
  const feesArray = [];

  if (fees.applicationFees) {
    feesArray.push(
      <li className={classes.itemContainer} key={Fee.Application}>
        <ItemElement type={ElementType.Name}>{traductions.orderDetailPage.applicationFees}</ItemElement>
        <ItemElement type={ElementType.Price}>
          <FormattedPrice value={fees.applicationFees} currency={currency} />
        </ItemElement>
      </li>
    );
  }

  if (fees.deliveryFees) {
    feesArray.push(
      <li className={classes.itemContainer} key={Fee.Delivery}>
        <ItemElement type={ElementType.Name}>{traductions.orderDetailPage.deliveryFees}</ItemElement>
        <ItemElement type={ElementType.Price}>
          <FormattedPrice value={fees.deliveryFees} currency={currency} />
        </ItemElement>
      </li>
    );
  }

  if (fees.allergyFees) {
    feesArray.push(
      <li className={classes.itemContainer} key={Fee.Allergy}>
        <ItemElement type={ElementType.Name}>{traductions.orderDetailPage.allergyFees}</ItemElement>
        <ItemElement type={ElementType.Price}>
          <FormattedPrice value={fees.allergyFees} currency={currency} />
        </ItemElement>
      </li>
    );
  }

  if (fees.orderBagFees?.length > 0) {
    fees.orderBagFees.forEach((bagFee) =>
      feesArray.push(
        <li className={classes.itemContainer} key={bagFee.bagType}>
          <ItemElement type={ElementType.Name}>
            {traductions.orderDetailPage[bagFee.type]}
            {' ('}
            {traductions.orderDetailPage[bagFee.bagType]}
            {bagFee.appliedQuantity > 1 ? ` x${bagFee.appliedQuantity}` : ''}
            {')'}
          </ItemElement>
          <ItemElement type={ElementType.Price}>
            <FormattedPrice value={bagFee.totalChargedAmount} currency={currency} />
          </ItemElement>
        </li>
      )
    );
  }

  return feesArray.length > 0 ? (
    <>
      <SeparationLine />
      {feesArray}
    </>
  ) : null;
}
