import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { closeSnackbar, SnackbarKey } from 'notistack';
import { useTraductions } from './TraductionsContext';

const useStyles = makeStyles(() => ({
  confirmButton: {
    fontWeight: 700,
  },
}));

export function snackbarConfirmAction(snackbarId: SnackbarKey) {
  const classes = useStyles();
  const traductions = useTraductions();
  return (
    <Button
      color="inherit"
      size="small"
      onClick={() => closeSnackbar(snackbarId)}
      className={classes.confirmButton}
      data-testid={`snackbar-${snackbarId}-confirm-button`}
    >
      {traductions.main.ok}
    </Button>
  );
}

export function snackbarCloseAction(snackbarId: SnackbarKey) {
  const traductions = useTraductions();
  return (
    <IconButton
      color="inherit"
      size="small"
      onClick={() => closeSnackbar(snackbarId)}
      aria-label={traductions.main.close}
      data-testid={`snackbar-${snackbarId}-close-button`}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
}
