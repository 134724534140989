export interface PauseState {
  branchId: number | undefined;
  channelList: ChannelStates[];
  drawerOpen: boolean;
  resumeDialogOpen: boolean;
  channelsToResume: OrderChannelEnum[];
  pauseTimerDisabled: boolean;
  isAnimationEnabled: boolean;
}
export interface ChannelStates {
  channel: OrderChannelEnum;
  pausedUntil?: string;
  status?: PauseStateStatus;
  statusCreatedAt?: string;
}
export enum PauseStateStatus {
  Error = 'ERROR',
  Pending = 'PENDING',
  Success = 'SUCCESS',
  Timeout = 'TIMEOUT',
}
export type PauseAmount = 'HALF_HOUR' | 'HOUR' | 'TWO_HOURS' | 'TOMORROW';
export interface PausedRestaurants {
  pauseRestaurant: ChannelStates[];
}
export interface ResumedRestaurants {
  resumeRestaurant: ChannelStates[];
}
// Keep synced with "/src/types/ThirdPartyOrderIntegrationTypes/ThirdPartyOrderIntegration" enum
export enum OrderChannelEnum {
  UBER_EATS = 'UBER_EATS',
  DOOR_DASH = 'DOOR_DASH',
  SKIP_THE_DISHES = 'SKIP_THE_DISHES',
  GOOGLE_FOOD_ORDERING = 'GOOGLE_FOOD_ORDERING',
  KIOSK = 'KIOSK',
  WEB = 'WEB',
  UNKNOWN = 'UNKNOWN',
}
