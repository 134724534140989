import React from 'react';
import List from '@mui/material/List';
import MenuSection from './MenuSection';
import { Sections, ReadOnlySections } from './SectionsInfo';
import { isCurrentUserReadonly } from '../app/UserUtils';
import { useSettings } from '../app/SettingsContext';

interface Props {
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
}

export default function Menu({ handleClick }: Props) {
  const settings = useSettings();
  const userRole = settings.userRole ? settings.userRole : '';
  let sections;
  if (isCurrentUserReadonly(userRole)) {
    sections = ReadOnlySections();
  } else {
    sections = Sections();
  }

  return (
    <List>
      {sections
        .filter((section) => section.inMenu === true)
        .map((section) => (
          <MenuSection
            key={section.name}
            name={section.name}
            path={section.path}
            text={section.menuTitle}
            icon={section.icon}
            handleClick={handleClick}
          />
        ))}
    </List>
  );
}
