import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import uniqBy from 'lodash/uniqBy';

import { PAUSE_STATES_QUERY } from '../../queries/queries';
import { PAUSE_STATES_SUBSCRIPTION } from '../../subscriptions/subscriptions';
import { PauseStatesQueryData } from '../pauseContainer';
import { ChannelStates } from '../types/PauseTypes';

export interface Variables {
  branchId: number;
}

interface Props {
  branchId: number;
  onCompleted: () => void;
  handleNewExternalChannelDataNotification: (data: {
    prevPauseStates: ChannelStates[];
    newPauseStates: ChannelStates[];
  }) => void;
}

export const usePauseStates = ({ branchId, onCompleted, handleNewExternalChannelDataNotification }: Props) => {
  const { subscribeToMore, data, error, loading } = useQuery<PauseStatesQueryData, Variables>(PAUSE_STATES_QUERY, {
    skip: !branchId,
    variables: { branchId },
    fetchPolicy: 'network-only',
    onCompleted,
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: PAUSE_STATES_SUBSCRIPTION,
      variables: { branchId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;

        const prevPauseStates = prev.pauseStates;
        const newPauseStates = subscriptionData.data.pauseStates;
        const nextPauseStates = uniqBy([...newPauseStates, ...prevPauseStates], 'channel');

        handleNewExternalChannelDataNotification({
          prevPauseStates,
          newPauseStates,
        });

        return {
          pauseStates: nextPauseStates,
        };
      },
    });

    return () => {
      unsubscribe();
    };
  }, [branchId]);

  return {
    data,
    error,
    loading,
  };
};
