import { ApolloClient } from '@apollo/client';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { FormControlLabel, Radio, RadioGroup, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { rem } from '../app/AppUtilities';
import { Culture, useSettings } from '../app/SettingsContext';
import { useTraductions } from '../app/TraductionsContext';

interface Props {
  client: ApolloClient<unknown>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      marginBottom: rem(theme.spacing(4)),
    },
    languageInput: {
      display: 'flex',
      alignItems: 'center',
    },
    group: {
      width: rem(300),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    labelTypography: {
      fontSize: rem(theme.generalStyles.baseTextSize + 4),
    },
    radioStyles: {
      marginRight: rem(5),
    },
    radioIcon: {
      fontSize: '2.2rem',
    },
    formTitle: {
      marginBottom: rem(theme.spacing(2)),
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
  })
);

export default function SettingsLanguageRadio({ client }: Props) {
  const settings = useSettings();
  const traductions = useTraductions();
  const classes = useStyles();
  const [language, setLanguage] = React.useState(settings.culture as string);

  function handleChange(event: React.ChangeEvent<unknown>) {
    setLanguage((event.target as HTMLInputElement).value);
    settings.changeCulture((event.target as HTMLInputElement).value as Culture);
  }

  return (
    <div className={classes.container}>
      <Typography
        classes={{
          root: classes.formTitle,
        }}
        variant="h4"
        align="center"
      >
        {traductions.settingsPage.languageTitle}
      </Typography>
      <RadioGroup
        aria-label="culture"
        name="culture"
        className={classes.group}
        value={language}
        onChange={handleChange}
      >
        <FormControlLabel
          classes={{
            label: classes.labelTypography,
            root: classes.languageInput,
          }}
          value="fr-CA"
          control={
            <Radio
              classes={{
                root: classes.radioStyles,
              }}
              color="primary"
              icon={
                <RadioButtonUncheckedIcon
                  classes={{
                    root: classes.radioIcon,
                  }}
                />
              }
              checkedIcon={
                <RadioButtonCheckedIcon
                  classes={{
                    root: classes.radioIcon,
                  }}
                />
              }
            />
          }
          label="Français"
          data-testid="settings-language-radio-fr"
        />
        <FormControlLabel
          classes={{ label: classes.labelTypography }}
          value="en-CA"
          control={
            <Radio
              classes={{
                root: classes.radioStyles,
              }}
              color="primary"
              icon={
                <RadioButtonUncheckedIcon
                  classes={{
                    root: classes.radioIcon,
                  }}
                />
              }
              checkedIcon={
                <RadioButtonCheckedIcon
                  classes={{
                    root: classes.radioIcon,
                  }}
                />
              }
            />
          }
          label="English"
          data-testid="settings-language-radio-en"
        />
      </RadioGroup>
    </div>
  );
}
