export interface MenuItemsType {
  openMenu: string;
  ordersTitle: string;
  preordersTitle: string;
  processedOrdersTitle: string;
  menuTitle: string;
  timeTitle: string;
  supportTitle: string;
  settingsTitle: string;
  detailTitle: string;
  deliveryPartnerTitle: string;
}

export const enCa: MenuItemsType = {
  openMenu: 'Open menu',
  ordersTitle: 'orders',
  preordersTitle: 'preorders',
  processedOrdersTitle: 'processed orders',
  menuTitle: 'menu items',
  timeTitle: 'preparation time',
  supportTitle: 'UEAT support',
  settingsTitle: 'settings',
  detailTitle: 'order detail',
  deliveryPartnerTitle: 'Third-party delivery partners',
};

export const frCa: MenuItemsType = {
  openMenu: 'Ouvrir le menu',
  ordersTitle: 'commandes',
  preordersTitle: 'précommandes',
  processedOrdersTitle: 'commandes traitées',
  menuTitle: 'items de menu',
  timeTitle: 'temps de préparation',
  supportTitle: 'support UEAT',
  settingsTitle: 'paramètres',
  detailTitle: 'détails de la commande',
  deliveryPartnerTitle: 'Partenaires de livraison tiers',
};
