import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { THIRD_PARTY_DELIVERY_PARTNER_STATE_MUTATION } from '../mutations/mutations';
import { Mutation } from '@apollo/client/react/components';
import { MutationFunction } from '@apollo/client/react';
import { useTraductions } from '../app/TraductionsContext';
import { useSettings } from '../app/SettingsContext';
import { rem } from '../app/AppUtilities';
import { ThirdPartyDeliveryPartnerState, ThirdPartyDeliveryPartnerType } from './ThirdPartyDeliveryPartnerTypes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    providerName: {
      marginLeft: rem(theme.spacing(3)),
      fontSize: rem(theme.generalStyles.baseTextSize + 4),
      fontWeight: 'bold',
    },
    partnerContainer: {
      marginBottom: rem(theme.spacing(3)),
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    icon: {
      fontSize: rem(theme.generalStyles.bigIconSize),
    },
    selected: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.primary.contrastText} !important`,
    },
    toggle: {
      height: rem(75),
      minWidth: rem(74),
    },
  })
);

interface Data {
  updateThirdPartyDeliveryPartnerStatus: {
    id: number;
  };
}

interface Variables {
  partnerId: number;
  branchId: number;
  isDisabled: Boolean;
}

interface Props {
  partnerData: ThirdPartyDeliveryPartnerType;
  setMutationError: (error: string) => void;
}

export default function ThirdPartyDeliveryPartner({ partnerData, setMutationError }: Props) {
  const settings = useSettings();
  const traductions = useTraductions();
  const classes = useStyles();
  const [thirdPartyDeliveryPartnerStatus, setPartnerStatus] = React.useState(partnerData.isDisabled ? 1 : 0);
  const partnerId = partnerData.id;
  const branchId = settings.currentBranchId;
  const handleChange =
    (updatePartnerStatus: MutationFunction<Data, Variables>) =>
    async (event: React.MouseEvent<HTMLElement, MouseEvent>, newState: ThirdPartyDeliveryPartnerState) => {
      if (branchId) {
        try {
          await updatePartnerStatus({
            variables: { partnerId, branchId, isDisabled: Boolean(newState) },
          });
          setPartnerStatus(newState);
        } catch {
          setMutationError(traductions.thirdPartyDeliveryPartnerPage.mutationError);
        }
      }
    };

  return (
    <div className={classes.partnerContainer}>
      <div>
        <Mutation<Data, Variables> mutation={THIRD_PARTY_DELIVERY_PARTNER_STATE_MUTATION}>
          {(updatePartnerStatus) => (
            <ToggleButtonGroup
              value={thirdPartyDeliveryPartnerStatus}
              exclusive
              onChange={handleChange(updatePartnerStatus)}
            >
              <ToggleButton
                value={ThirdPartyDeliveryPartnerState.Active}
                classes={{ root: classes.toggle, selected: classes.selected }}
              >
                <CheckCircleOutlineIcon classes={{ root: classes.icon }} />
              </ToggleButton>
              <ToggleButton
                value={ThirdPartyDeliveryPartnerState.Disabled}
                classes={{ root: classes.toggle, selected: classes.selected }}
              >
                <HighlightOffOutlinedIcon classes={{ root: classes.icon }} />
              </ToggleButton>
            </ToggleButtonGroup>
          )}
        </Mutation>
      </div>
      <p className={classes.providerName}>{partnerData.thirdPartyDeliveryProviderName}</p>
    </div>
  );
}
