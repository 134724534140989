export interface WelcomeModalType {
  title: string;
  message: string;
  start: string;
}

export const enCa: WelcomeModalType = {
  title: 'Welcome to the UBoard',
  message: 'Receive orders in real time at your restaurant.',
  start: 'Start',
};

export const frCa: WelcomeModalType = {
  title: 'Bienvenue sur le UBoard',
  message: 'Recevez les commandes en temps réel à votre restaurant.',
  start: 'Commencer',
};
