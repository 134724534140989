import { ApolloClient, from, HttpLink, InMemoryCache, ServerError, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { useMemo } from 'react';
import { useSettings } from '../SettingsContext';
import { getApiUrl, getUboardWebSocketURL } from '../../utils/env';

const UNAUTHORIZED_STATUS_CODE = 401;
const UNAUTHORIZED_STATUS_MESSAGE = 'UNAUTHENTICATED';

const cache = new InMemoryCache();

export function useApolloClient() {
  const settings = useSettings();

  const client = useMemo(() => {
    const httpLink = new HttpLink({
      uri: getApiUrl(),
      headers: {
        Authorization: settings.authToken ? `bearer ${settings.authToken}` : '',
        'Accept-Language': settings.culture,
        'company-id': (settings.currentBranchId ?? '').toString(),
      },
    });

    const uboardWsUrl = getUboardWebSocketURL();
    const graphqlWsUrl = `${uboardWsUrl}/graphql`;
    const wsLink = new GraphQLWsLink(
      createClient({
        url: graphqlWsUrl,
        connectionParams: {
          authToken: settings.authToken,
        },
      })
    );

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        const isSubscription = definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
        return !isSubscription;
      },
      httpLink,
      wsLink
    );

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        for (let err of graphQLErrors) {
          if (err.extensions?.code === UNAUTHORIZED_STATUS_MESSAGE) {
            settings.logout();
          }
        }
      }
      if (networkError) {
        const error = networkError as ServerError;
        if (error.statusCode === UNAUTHORIZED_STATUS_CODE) {
          settings.logout();
        }
      }
    });

    return new ApolloClient({
      link: from([errorLink, splitLink]),
      cache,
    });
  }, [settings.authToken, settings.culture]);

  return client;
}
