import { Box } from '@mui/material';
import { WithTestId } from '@ueat/poke';
import SubTitle from './SubTitle';

interface Props extends WithTestId {
  title: string;
  text: string | null;
}

export default function Subsection({ title, text, testId }: Props) {
  if (!text) {
    return null;
  }

  return (
    <Box>
      <SubTitle>{title}</SubTitle>
      <p data-testid={testId}>{text}</p>
    </Box>
  );
}
