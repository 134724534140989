import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { Typography, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { green, orange, red } from '@mui/material/colors';
import classNames from 'classnames';
import { Alert, Channel, ChannelsList, ThemeProvider } from '@ueat/poke';
import { useTraductions } from '../app/TraductionsContext';
import { rem } from '../app/AppUtilities';

interface Props {
  externalChannels?: Channel[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      width: '100%',
      textAlign: 'center',
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
    legend: {
      padding: rem(theme.spacing(3)),
      paddingTop: rem(theme.spacing(5)),
      paddingBottom: rem(theme.spacing(7)),
      position: 'fixed',
      bottom: 0,
      right: 0,
      left: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      gap: rem(theme.spacing(2)),
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.secondary.main,
      boxShadow: `0px ${rem(-4)} ${rem(4)} rgba(0, 0, 0, 0.25)`,
      '&::after, &::before': {
        position: 'absolute',
        zIndex: 1000,
        content: `""`,
        height: rem(50),
        width: rem(75),
      },
      '&::after': {
        top: rem(theme.spacing(3)),
        borderTop: `${rem(2)} solid ${theme.palette.primary.contrastText}`,
        borderLeft: `${rem(2)} solid ${theme.palette.primary.contrastText}`,
      },
      '&::before': {
        borderBottom: `${rem(2)} solid ${theme.palette.primary.contrastText}`,
        borderRight: `${rem(2)} solid ${theme.palette.primary.contrastText}`,
        bottom: rem(theme.spacing(3)),
        right: rem(theme.spacing(3)),
      },
      [theme.breakpoints.up('md')]: {
        padding: rem(theme.spacing(3)),
        width: '30%',
        top: rem(theme.generalStyles.appBarLargeScreenHeight),
        left: 'auto',
        justifyContent: 'center',
        boxShadow: `${rem(-4)} 0px ${rem(4)} rgba(0, 0, 0, 0.25)`,
      },
    },
    legendWithoutBeforeBorder: {
      paddingBottom: rem(theme.spacing(3)),
      '&::before': {
        borderColor: 'transparent',
      },
    },
    legendItems: {
      margin: `0 ${rem(theme.spacing(5))}`,
      padding: `${rem(theme.spacing(1.5))} ${rem(theme.spacing(2))}`,
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      gap: rem(theme.spacing(2)),
      backgroundColor: theme.palette.background.default,
      border: `${rem(1)} solid #DDDDDD`,
      borderRadius: rem(4),
      [theme.breakpoints.up('md')]: {
        flexDirection: 'column',
        paddingTop: rem(theme.spacing(2.5)),
        paddingRight: rem(theme.spacing(3)),
        paddingBottom: rem(theme.spacing(2.5)),
        paddingLeft: rem(theme.spacing(2)),
        gap: rem(theme.spacing(3)),
      },
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      gap: rem(theme.spacing(2)),
      fontSize: rem(theme.generalStyles.baseTextSize),
    },
    itemText: {
      margin: 0,
      color: theme.palette.text.default,
      fontSize: rem(theme.generalStyles.baseTextSize),
      fontWeight: 600,
    },
    icon: {
      flexShrink: 0,
      height: rem(32),
      width: rem(32),
      borderRadius: rem(4),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    on: {
      backgroundColor: green[800],
    },
    temporaryOff: {
      backgroundColor: orange[800],
    },
    off: {
      backgroundColor: red[800],
    },
    warning: {
      [theme.breakpoints.up('md')]: {
        position: 'absolute',
        bottom: rem(theme.spacing(3)),
        left: rem(theme.spacing(3)),
        right: rem(theme.spacing(3)),
      },
      '& .MuiAlert-standard': {
        alignItems: 'flex-start',
      },
    },
    warningText: { flex: 1 },
  })
);

export default function ItemStatesCaptions({ externalChannels }: Props) {
  const traductions = useTraductions();
  const classes = useStyles();
  const showExternalChannelsWarning = !!externalChannels?.length;

  return (
    <div className={classNames(classes.legend, showExternalChannelsWarning && classes.legendWithoutBeforeBorder)}>
      <Typography variant="h4" className={classes.title}>
        {traductions.menuItemsPage.legend}
      </Typography>
      <div className={classes.legendItems}>
        <div className={classes.item}>
          <div className={classNames(classes.icon, classes.on)}>
            <CheckCircleOutlineIcon />
          </div>
          <p className={classes.itemText}>{traductions.menuItemsPage.on}</p>
        </div>
        <div className={classes.item}>
          <div className={classNames(classes.icon, classes.temporaryOff)}>
            <PauseCircleOutlineIcon />
          </div>
          <p className={classes.itemText}>{traductions.menuItemsPage.temporaryOff}</p>
        </div>
        <div className={classes.item}>
          <div className={classNames(classes.icon, classes.off)}>
            <HighlightOffOutlinedIcon />
          </div>
          <p className={classes.itemText}>{traductions.menuItemsPage.off}</p>
        </div>
      </div>
      {showExternalChannelsWarning && (
        <div className={classes.warning}>
          <ThemeProvider noPadding>
            <Alert type="warning">
              <span className={classes.warningText}>{traductions.menuItemsPage.externalChannelsWarning}</span>
              <ChannelsList channels={['web', ...externalChannels]} />
            </Alert>
          </ThemeProvider>
        </div>
      )}
    </div>
  );
}
