import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { DefaultTheme } from '@mui/material/styles/createTheme';
import { createStyles, makeStyles } from '@mui/styles';
import { ChannelIcon } from '@ueat/poke';
import classNames from 'classnames';
import { useRemainingTime } from '../hooks/useRemainingTime';
import { PauseChannelChip, PauseChannelChipData, PauseChannelChipLabel } from '../pauseChannelChip';

import { rem } from '../../app/AppUtilities';

export interface PauseChannelListItemLabel {
  pauseChannelChipLabel: PauseChannelChipLabel;
}

export interface PauseChannelListItemData {
  channelId: string;
  channelName: string;
  disabled?: boolean;
  pausedUntil?: string;
  selected?: boolean;
  pending?: boolean;
}

export interface PauseChannelListItemProps {
  label: PauseChannelListItemLabel;
  data: PauseChannelListItemData;
  onSelected: (channelId: string) => void;
  onTimeDelayExpiry: (channelId: string) => void;
}

const activeBoxShadow = `0px ${rem(6)} ${rem(24)} rgba(0, 0, 0, 0.05)`;

const useStyles = makeStyles((theme: DefaultTheme) =>
  createStyles({
    root: {
      padding: '0px',
      gap: rem(32),
      background: theme.palette.background.paper,
      borderRadius: rem(72),
    },
    button: {
      padding: `${rem(14)} ${rem(16)}`,
      borderRadius: rem(72),
      height: rem(72),

      '&:hover': {
        background: theme.palette.background.paper,
        boxShadow: theme.generalStyles.buttonHoverBoxShadow,
        filter: theme.generalStyles.buttonHoverFilter,
        '@supports (-webkit-backdrop-filter: blur(1px))': {
          //media query only for safari since it cuts the shadow.
          boxShadow: '0px 0px 15px -4px rgba(0,0,0,0.20)',
          filter: '',
        },
      },
      '&:active': {
        background: theme.pauseComponent.button.background.secondary,
        boxShadow: activeBoxShadow,
      },
      '& .MuiListItemText-primary': {
        fontWeight: '700',
        fontSize: '1.4rem',
        lineHeight: rem(24),
        color: theme.palette.text.default,
      },

      '&.Mui-selected': {
        background: theme.palette.primary.main,

        '& .MuiListItemText-primary': {
          color: theme.palette.background.paper,
        },
      },
      '&.Mui-selected:hover': {
        backgroundColor: theme.palette.primary.main,
        boxShadow: 'none',
        filter: 'none',
      },

      '&.Mui-selected:active': {
        backgroundColor: theme.pauseComponent.button.background.tertiary,
        boxShadow: activeBoxShadow,
      },
    },
    icon: {
      minWidth: 0,
      paddingRight: theme.spacing(2),
      '& svg': {
        width: `${rem(44)} !important`,
        minWidth: `${rem(44)} !important`,
        height: `${rem(44)} !important`,
      },
    },
    text: {
      alignSelf: 'center',
    },
  })
);

export const PauseChannelListItem = ({ label, data, onSelected, onTimeDelayExpiry }: PauseChannelListItemProps) => {
  const styles = useStyles();

  const handledTimeDelayExpiry = () => {
    onTimeDelayExpiry(data.channelId);
  };

  const [remainingTime] = useRemainingTime(data.pausedUntil, handledTimeDelayExpiry);

  const chipData: PauseChannelChipData = {
    remainingTime: remainingTime,
    selected: data.selected,
    disabled: data.disabled,
    pending: data.pending,
  };

  const handleOnSelected = () => {
    onSelected(data.channelId);
  };

  return (
    <ListItem
      secondaryAction={
        <PauseChannelChip label={label?.pauseChannelChipLabel} data={chipData} onClick={handleOnSelected} />
      }
      disablePadding
      className={classNames(styles.root)}
    >
      <ListItemButton
        selected={data.selected}
        disabled={data.disabled || data.pending}
        className={styles.button}
        onClick={handleOnSelected}
        data-testid={`pause-channel-button-${data.channelId}`}
      >
        <ListItemIcon className={styles.icon}>
          <ChannelIcon channel={data.channelId} noPadding />
        </ListItemIcon>
        <ListItemText primary={data.channelName} className={styles.text} />
      </ListItemButton>
    </ListItem>
  );
};
