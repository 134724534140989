import moment from 'moment';
import { useEffect, useState } from 'react';
import { calculateDifferenceFromNow, formatRemainingTime } from '../util/utils';

export const useRemainingTime = (
  pausedUntil: string | undefined,
  handledTimeDelayExpiry: (() => void) | undefined,
  onRemainingTimeChanged?: (durationSecs: number) => void
) => {
  const [remainingTime, setRemainingTime] = useState(formatRemainingTime(pausedUntil));

  function updateRemainingTime(diffFromNow: number) {
    setRemainingTime(formatRemainingTime(pausedUntil));
    if (onRemainingTimeChanged) {
      const duration = moment.duration(diffFromNow);
      onRemainingTimeChanged(duration.asSeconds());
    }
  }

  useEffect(() => {
    let interval = 0;
    if (pausedUntil) {
      const diffFromNow = calculateDifferenceFromNow(pausedUntil);
      if (diffFromNow > 0) {
        updateRemainingTime(diffFromNow);
        interval = window.setInterval(() => updateRemainingTime(diffFromNow), 1000);
      } else {
        clearInterval(interval);
        if (handledTimeDelayExpiry) {
          handledTimeDelayExpiry();
        }
      }
    }

    return () => {
      clearInterval(interval);
    };
  }, [pausedUntil]);

  return [remainingTime] as const;
};
