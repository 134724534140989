import { ApolloClient } from '@apollo/client';
import { Button, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { rem } from '../app/AppUtilities';
import { useSettings } from '../app/SettingsContext';
import { useTraductions } from '../app/TraductionsContext';

interface Props {
  client: ApolloClient<unknown>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: rem(theme.spacing(6)),
    },
    button: {
      fontSize: rem(theme.generalStyles.mediumTextSize),
      letterSpacing: rem(1),
      wordSpacing: rem(2),
    },
  })
);

export default function SettingsLogOutButton({ client }: Props) {
  const settings = useSettings();
  const classes = useStyles();
  const traductions = useTraductions();

  const handleLogOut = () => {
    settings.logout();
    client.resetStore();
  };

  return (
    <div className={classes.root}>
      <Button
        color="inherit"
        variant="contained"
        className={classes.button}
        onClick={handleLogOut}
        data-testid="settings-logout-button"
      >
        {traductions.settingsPage.logOut}
      </Button>
    </div>
  );
}
