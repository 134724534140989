import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client';
import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { BranchesQueryData, BranchesQueryVariables, QueryData } from '../app/RestaurantInfoQueryType';
import { Settings, useIsPosIntegrated, useSettings } from '../app/SettingsContext';
import { useTraductions } from '../app/TraductionsContext';
import { isCurrentUserReadonly } from '../app/UserUtils';
import { useAlerts } from '../app/WebSocketClient';
import { ModalCloseButtonVariant } from '../orderModal/EventModal';
import { RESTAURANTMANAGER_QUERY, RESTAURANT_BRANCHES_QUERY } from '../queries/queries';
import { TraductionsType } from '../traductions';
import SettingsPrintDisabled from './SettingsPrintDisabled';
import SettingsAlertProfile from './SettingsAlertProfile';
import SettingsLanguageRadio from './SettingsLanguageRadio';
import SettingsLogOutButton from './SettingsLogOutButton';
import SettingsPrint from './SettingsPrint';
import SettingsSelection, { Choices } from './SettingsSelection';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageContainer: {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
    },
    settingsBlock: {
      [theme.breakpoints.up('md')]: {
        flexBasis: '45%',
      },
    },
  })
);

function SettingsPage() {
  const client = useApolloClient();
  const classes = useStyles();
  const settings = useSettings();
  const userRole = settings.userRole ? settings.userRole : '';
  const traductions = useTraductions();
  const [alerts, removeAlerts] = useAlerts();
  const isPosIntegrated = useIsPosIntegrated();

  const restaurantManager = useQuery<QueryData>(RESTAURANTMANAGER_QUERY);
  const [selectedRestaurantId, setSelectedRestaurantId] = useState<number | undefined>(settings.currentRestaurantId);

  const [fireBranchesQuery, branches] = useLazyQuery<BranchesQueryData, BranchesQueryVariables>(
    RESTAURANT_BRANCHES_QUERY
  );

  useEffect(() => {
    if (selectedRestaurantId) {
      fireBranchesQuery({ variables: { restaurantId: selectedRestaurantId } });
    }
  }, [selectedRestaurantId]);

  const handleSelectRestaurant = (restaurantId: number) => {
    setSelectedRestaurantId(restaurantId);
  };

  useEffect(() => {
    if (restaurantManager.data?.myRestaurants?.length && !selectedRestaurantId) {
      handleSelectRestaurant(restaurantManager.data.myRestaurants[0].id);
    }
  }, [restaurantManager.data]);

  const handleSelectBranch = (branchId: number) => {
    if (selectedRestaurantId && branches.data && restaurantManager.data) {
      removeAlerts(ModalCloseButtonVariant.All);
      settings.setRestaurant(
        selectedRestaurantId,
        branchId,
        restaurantManager.data?.myRestaurants,
        branches.data?.branches
      );
    }
  };

  useEffect(() => {
    const currentRestaurantHasBranchSelectedPreviously = branches.data?.branches.find(
      ({ id }) => id === settings.currentBranchId
    );
    if (currentRestaurantHasBranchSelectedPreviously) {
      handleSelectBranch(currentRestaurantHasBranchSelectedPreviously.id);
    } else if (branches.data?.branches && branches.data?.branches?.length >= 1) {
      handleSelectBranch(branches.data.branches[0].id);
    }
  }, [branches.data, settings.currentBranchId]);

  const RestaurantSelection = () => {
    const info = getSelectionInfo(Choices.Restaurant, traductions, settings);

    return (
      <SettingsSelection
        type={Choices.Restaurant}
        itemsList={restaurantManager.data?.myRestaurants || []}
        selectedValue={selectedRestaurantId}
        setSelectedValue={handleSelectRestaurant}
        selectionType={traductions.settingsPage.restaurant}
        title={info.title ?? ''}
      />
    );
  };

  const BranchSelection = () => {
    const info = getSelectionInfo(Choices.Branch, traductions, settings);

    if (!selectedRestaurantId) return null;

    if (branches.loading) {
      return <div style={{ textAlign: 'center' }}>{traductions.settingsPage.loading}...</div>;
    }
    if (branches.error) {
      return <div style={{ textAlign: 'center' }}>{traductions.settingsPage.error}</div>;
    }

    if (!branches.data?.branches.length || branches.data.branches.length === 1) {
      return null;
    }

    return (
      <SettingsSelection
        type={Choices.Branch}
        itemsList={branches.data?.branches || []}
        selectedValue={settings.currentBranchId}
        setSelectedValue={handleSelectBranch}
        selectionType={traductions.settingsPage.branch}
        title={info.title ?? ''}
      />
    );
  };

  return (
    <div className={classes.pageContainer}>
      <SettingsLogOutButton client={client} />

      <div className={classes.settingsBlock}>
        <SettingsLanguageRadio client={client} />
        <RestaurantSelection />
        <BranchSelection />
      </div>
      {!!!isCurrentUserReadonly(userRole) && (
        <div className={classes.settingsBlock}>
          <SettingsAlertProfile />
          {!isPosIntegrated ? <SettingsPrint /> : <SettingsPrintDisabled />}
        </div>
      )}
    </div>
  );
}

export default SettingsPage;

enum SelectionInputId {
  Restaurant = 'restaurant-selection',
  Branch = 'branch-selection',
}

interface SelectionInfoType {
  name?: Choices;
  id?: SelectionInputId;
  title?: string;
}

function getSelectionInfo(type: string, traductions: TraductionsType, settings: Settings): SelectionInfoType {
  if (settings.restaurantData) {
    switch (type) {
      case Choices.Restaurant:
        return {
          name: Choices.Restaurant,
          id: SelectionInputId.Restaurant,
          title: traductions.settingsPage.restaurantTitle,
        };
      case Choices.Branch:
        return {
          name: Choices.Branch,
          id: SelectionInputId.Branch,
          title: traductions.settingsPage.branchTitle,
        };
    }
  }
  return {};
}
