import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { rem } from './AppUtilities';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      height: 30,
      lineHeight: '30px',
      background: 'red',
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '1.2rem',
      color: '#fff',
      marginBottom: rem(theme.spacing(3)),
    },
  })
);

export default function EnvironmentBanner() {
  const classes = useStyles();

  let environmentName = (window as any).UEAT_ENVIRONMENT;
  if (environmentName === '{{UEAT_ENVIRONMENT}}') {
    environmentName = 'development';
  }

  if (environmentName === 'production') {
    return null;
  }

  return <div className={classes.container}>You are on the {environmentName} environment</div>;
}
