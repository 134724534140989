import React from 'react';
import { Button, Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { useTraductions } from '../app/TraductionsContext';
import { useAlerts } from '../app/WebSocketClient';
import { withRouter, RouteComponentProps } from 'react-router';
import { ModalCloseButtonVariant } from './EventModal';
import { rem } from '../app/AppUtilities';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      fontSize: rem(theme.generalStyles.mediumTextSize),
      fontWeight: 'bold',
      padding: theme.generalStyles.modalButtonPadding,
    },
  })
);

interface Props extends RouteComponentProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function DialogButtons({ history, setOpen }: Props) {
  const classes = useStyles();
  const traductions = useTraductions();
  const [alerts, removeAlerts] = useAlerts();

  const handleClose = (type: ModalCloseButtonVariant) => (e: React.MouseEvent<HTMLElement>) => {
    setOpen(false);
    removeAlerts(type);
  };

  const handleShow = () => {
    let orderId = 0;
    if (alerts.length > 0) {
      orderId = alerts[alerts.length - 1].OrderId;
    }

    setOpen(false);
    removeAlerts(ModalCloseButtonVariant.Single);

    if (orderId > 0) {
      history.push(`/order/${orderId}`);
    }
  };

  return (
    <>
      <Button
        classes={{
          root: classes.button,
        }}
        variant="outlined"
        onClick={handleClose(ModalCloseButtonVariant.Single)}
        color="secondary"
      >
        {traductions.eventModal.close}
      </Button>
      <Button
        classes={{
          root: classes.button,
        }}
        variant="outlined"
        onClick={handleClose(ModalCloseButtonVariant.All)}
        color="secondary"
      >
        {traductions.eventModal.closeAll}
      </Button>
      <Button
        classes={{
          root: classes.button,
        }}
        variant="contained"
        onClick={handleShow}
        color="primary"
      >
        {traductions.eventModal.show}
      </Button>
    </>
  );
}

export default withRouter(DialogButtons);
