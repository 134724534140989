export interface MainType {
  ok: string;
  close: string;
  genericError: string;
}

export const enCa: MainType = {
  ok: 'Ok',
  close: 'Close',
  genericError: 'An error has occurred',
};

export const frCa: MainType = {
  ok: 'Ok',
  close: 'Fermer',
  genericError: 'Une erreur est survenue',
};
