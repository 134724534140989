import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useSettings } from '../../app/SettingsContext';
import { ChannelStates, PauseStateStatus } from '../types/PauseTypes';
import { calculateDifferenceFromNow } from '../util/utils';

const BASE_TIMEOUT = 10000;

export const usePendingTimeout = ({
  channelList,
  handlePauseOrResumeTimedOutNotification,
}: {
  channelList: ChannelStates[];
  handlePauseOrResumeTimedOutNotification: (channelList: ChannelStates[]) => void;
}) => {
  const settings = useSettings();
  const [pending, setPending] = useState(false);

  function handleTimedOut(pendingPauseStates: ChannelStates[]) {
    setPending(false);
    handlePauseOrResumeTimedOutNotification(pendingPauseStates);
  }

  useEffect(() => {
    let timeout = 0;
    let timeoutModifier = 0;

    const pendingPauseStates = channelList.filter((pauseState) => pauseState.status === PauseStateStatus.Pending);

    pendingPauseStates.forEach((pauseState) => {
      const diffFromNow = Math.abs(calculateDifferenceFromNow(pauseState.statusCreatedAt));

      // We should only set the pending timeout if a status was created less than 10s ago
      if (diffFromNow > 0 && diffFromNow <= BASE_TIMEOUT) {
        // And we should use the most recently created status as reference
        if (!timeoutModifier || diffFromNow < timeoutModifier) timeoutModifier = diffFromNow;
      }
    });

    if (!!timeoutModifier) {
      setPending(true);
      timeout = window.setTimeout(() => handleTimedOut(pendingPauseStates), BASE_TIMEOUT - timeoutModifier);
    } else {
      setPending(false);
    }

    return () => {
      !!timeout && window.clearTimeout(timeout);
    };
  }, [channelList, settings.currentBranch]);

  const hasAnyPendingChannel = useMemo(
    () => channelList.some((pauseState) => pauseState.status === PauseStateStatus.Pending),
    [channelList]
  );

  return { pending, hasAnyPendingChannel };
};
