import React from 'react';
import { FormattedNumber } from 'react-intl';

interface Props {
  value: number;
  currency: string;
}

export default function FormattedPrice({ value, currency }: Props) {
  return (
    <FormattedNumber
      value={value}
      currency={currency}
      currencyDisplay="symbol"
      style="currency" // eslint-disable-line
    />
  );
}
