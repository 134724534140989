import React from 'react';
import { Sections } from '../appMenu/SectionsInfo';
import { Typography } from '@mui/material';
import { withRouter, RouteComponentProps } from 'react-router';

interface Props extends RouteComponentProps {}

function HeaderTitle({ location }: Props) {
  const sections = Sections();
  const activeSection = sections.find(
    (section) => section.path === location.pathname || location.pathname.startsWith(section.path)
  );

  if (activeSection) {
    return (
      <Typography variant="h6" noWrap>
        {activeSection.headerTitle}
      </Typography>
    );
  } else {
    return <Typography variant="h6" noWrap />;
  }
}

export default withRouter(HeaderTitle);
