export interface ConnectionLostModalType {
  title: string;
  text: string;
}

export const enCa: ConnectionLostModalType = {
  title: 'Connection lost',
  text: 'The connection has been lost with UEAT. Check if your Internet connection is working properly.',
};

export const frCa: ConnectionLostModalType = {
  title: 'Connexion perdue',
  text: 'La connexion a été perdue avec UEAT. Veuillez vérifier si votre connexion Internet fonctionne correctement.',
};
