import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';
import { WebStorageStateStore } from 'oidc-client-ts';
import { PropsWithChildren, useEffect, useRef } from 'react';
import { AuthProviderProps, AuthProvider as OidcAuthProvider, hasAuthParams, useAuth } from 'react-oidc-context';
import { FEATURE_FLAGS } from '../featureFlags/FEATURE_FLAGS';
import { getAppUrl, getIdentityProviderClientId, getIdentityProviderUrl } from '../utils/env';

const oidcConfig: AuthProviderProps = {
  authority: getIdentityProviderUrl(),
  client_id: getIdentityProviderClientId(),
  redirect_uri: getAppUrl(),
  post_logout_redirect_uri: getAppUrl(),
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  onSigninCallback: () => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
};

export function AuthProvider({ children }: PropsWithChildren<{}>) {
  const { flagsReady } = useFlagsStatus();
  const isIdentityNewUIEnabled = useFlag(FEATURE_FLAGS.IDENTITY_NEW_UI);

  if (!flagsReady) {
    return null;
  }

  if (isIdentityNewUIEnabled) {
    return (
      <OidcAuthProvider {...oidcConfig}>
        <AuthProviderContent>{children}</AuthProviderContent>
      </OidcAuthProvider>
    );
  }

  return <>{children}</>;
}

function AuthProviderContent({ children }: PropsWithChildren<{}>) {
  const auth = useAuth();
  const hasTriedSignin = useRef(false);

  /**
   * @see https://github.com/authts/react-oidc-context#automatic-sign-in
   */
  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.activeNavigator &&
      !auth.isAuthenticated &&
      !auth.isLoading &&
      !hasTriedSignin.current
    ) {
      hasTriedSignin.current = true;
      auth.signinRedirect();
    }
  }, [auth.activeNavigator, auth.isAuthenticated, auth.isLoading]);

  if (auth.activeNavigator || auth.isLoading || !auth.isAuthenticated) {
    return null;
  }

  return <>{children}</>;
}
