import { ApolloQueryResult } from '@apollo/client';
import { MutationFunction } from '@apollo/client/react';
import { Mutation } from '@apollo/client/react/components';
import { FormControl, MenuItem, Select, SelectChangeEvent, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useState } from 'react';
import { rem } from '../app/AppUtilities';
import { useSettings } from '../app/SettingsContext';
import { useTraductions } from '../app/TraductionsContext';
import { TEMPORARY_DELAY_MUTATION } from '../mutations/mutations';
import { DelayType } from './DelayContainer';
import { Data, TimeDelay, Variables } from './PrepTimeTypes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selection: {
      margin: `${rem(theme.spacing(2))} 0 0 ${rem(theme.spacing(4))}`,
      position: 'relative',
    },
    text: {
      fontSize: rem(theme.generalStyles.biggerTextSize),
      color: theme.palette.secondary.main,
    },
    plusSign: {
      margin: 0,
      position: 'absolute',
      top: rem(-4),
      right: '120%',
    },
  })
);

const SELECTION_ID = 'delay-select';
const SELECTION_NAME = 'delay';
const PLUS_SIGN = '+';
const MINIMUM_DELAY = 0;
const MAXIMUM_DELAY = 25;
const DELAY_INCREMENTS = 5;

interface MutationData {
  changeTemporaryDelay: {
    base: TimeDelay;
    delivery: TimeDelay;
  };
}

interface MutationVariables {
  branchId: number;
  service: string;
  minutes: number;
  expiration: string;
}

interface Props extends React.HTMLAttributes<HTMLFormControlsCollection> {
  variant: DelayType;
  delaysData: Data;
  initialValue: number;
  refetchDelays: (variables?: Variables | undefined) => Promise<ApolloQueryResult<Data>>;
  setMutationError: (error: string) => void;
}

export default function DelaySelection({
  className,
  variant,
  initialValue,
  delaysData,
  refetchDelays,
  setMutationError,
}: Props) {
  const settings = useSettings();
  const traductions = useTraductions();
  const classes = useStyles();
  const [delay, setDelay] = useState({
    name: SELECTION_NAME,
    id: initialValue,
  });
  const selectionItems = getSelectionItems(MINIMUM_DELAY, MAXIMUM_DELAY, DELAY_INCREMENTS, classes.text, variant);

  const handleChange = async (
    event: SelectChangeEvent<number>,
    updateTemporaryDelay: MutationFunction<MutationData, MutationVariables>
  ) => {
    try {
      const expiration = new Date();
      expiration.setHours(expiration.getHours() + 1);
      if (settings.currentBranchId) {
        await updateTemporaryDelay({
          variables: {
            branchId: settings.currentBranchId,
            service: variant === DelayType.Delivery ? 'DELIVERY' : 'ALL',
            minutes: event.target.value as number,
            expiration: expiration.toISOString(),
          },
        });
      }
      setDelay((oldDelay) => ({
        ...oldDelay,
        id: event.target.value as number,
      }));
      refetchDelays();
    } catch {
      setMutationError(traductions.preparationTimePage.mutationError);
    }
  };

  return (
    <Mutation<MutationData, MutationVariables> mutation={TEMPORARY_DELAY_MUTATION}>
      {(updateTemporaryDelay) => (
        <FormControl className={classNames(classes.selection, className)}>
          <Select
            classes={{
              select: classes.text,
            }}
            value={delay.id}
            onChange={(e) => {
              handleChange(e, updateTemporaryDelay);
            }}
            inputProps={{
              name: SELECTION_NAME,
              id: SELECTION_ID,
              'data-testid': `prep-time-temporary-${variant}-delay-input`,
            }}
            variant="standard"
            data-testid={`prep-time-temporary-${variant}-delay-dropdown`}
          >
            {!!selectionItems && selectionItems}
          </Select>
          <p className={classes.plusSign}>{PLUS_SIGN}</p>
        </FormControl>
      )}
    </Mutation>
  );
}

function getSelectionItems(
  minimumDelay: number,
  maximumDelay: number,
  increments: number,
  textClass: string,
  variant: DelayType
) {
  const items = [];

  for (let time = minimumDelay; time <= maximumDelay; time += increments) {
    items.push(
      <MenuItem
        key={`${time}`}
        classes={{ root: textClass }}
        value={time}
        data-testid={`prep-time-temporary-${variant}-delay-option-${time}`}
      >
        {time}
      </MenuItem>
    );
  }

  return items;
}
