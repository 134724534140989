import React from 'react';
import { Route, Switch } from 'react-router';
import { useFlag } from '@unleash/proxy-client-react';
import PrivateRoute, { PrivateRouteNewUI } from './PrivateRoutes';
import LoginPage from '../auth/LoginPage';
import NotFoundPage from '../notFound/NotFoundPage';
import OrdersListPage from '../orders/OrdersListPage';
import OrderDetailPage from '../orderDetail/OrderDetailPage';
import MenuItemsPage from '../menuItems/MenuItemsPage';
import PrepTimePage from '../prepTime/PrepTimePage';
import SupportPage from '../support/SupportPage';
import SettingsPage from '../settings/SettingsPage';
import ThirdPartyDeliveryPartnerPage from '../thirdpartydeliverypartners/ThirdPartyDeliveryPartnerPage';
import { FEATURE_FLAGS } from '../featureFlags/FEATURE_FLAGS';

export default function Routes() {
  const isIdentityNewUIEnabled = useFlag(FEATURE_FLAGS.IDENTITY_NEW_UI);
  const PrivateRouteComponent = isIdentityNewUIEnabled ? PrivateRouteNewUI : PrivateRoute;

  return (
    <Switch>
      <PrivateRouteComponent exact path="/" component={OrdersListPage} />
      <PrivateRouteComponent path="/orders" component={OrdersListPage} />
      <PrivateRouteComponent exact path="/order/:id" component={OrderDetailPage} />
      <PrivateRouteComponent exact path="/menuitems" component={MenuItemsPage} />
      <PrivateRouteComponent exact path="/deliverypartners" component={ThirdPartyDeliveryPartnerPage} />
      <PrivateRouteComponent exact path="/preptime" component={PrepTimePage} />
      <PrivateRouteComponent exact path="/support" component={SupportPage} />
      <PrivateRouteComponent exact path="/settings" component={SettingsPage} />
      <Route exact path="/login" component={LoginPage} />
      <Route component={NotFoundPage} />
    </Switch>
  );
}
