import React from 'react';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import classNames from 'classnames';
import { rem } from '../app/AppUtilities';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subTitle: {
      margin: 0,
      fontSize: rem(theme.generalStyles.biggerTextSize),
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
  })
);

interface Props extends React.HTMLAttributes<HTMLHeadingElement> {
  children: React.ReactNode;
}

export default function SubTitle({ children, className }: Props) {
  const classes = useStyles();

  return <h3 className={classNames(classes.subTitle, className)}>{children}</h3>;
}
