import { UnleashClient } from '@unleash/proxy-client-react';
import { getUeatEnvironment, getUnleashProxyKey, getUnleashProxyUrl } from '../utils/env';

const getRefreshInterval = () => 300;

const getUnleashConfig = () => {
  return {
    url: getUnleashProxyUrl(),
    clientKey: getUnleashProxyKey(),
    refreshInterval: getRefreshInterval(),
    appName: 'uboard',
    context: {
      properties: {
        /**
         * Since our Unleash license only supports two environements, we need an additional
         * custom constraint to be able to differentiate Staging & Beta from Development. The
         * different name is to avoid the constraint being overwritten by the Unleash-used
         * environment variable.
         */
        ueatEnvironment: getUeatEnvironment(),
      },
    },
    environment: getUeatEnvironment() === 'production' ? getUeatEnvironment() : 'development',
  };
};

export const getUnleashClient = (config = getUnleashConfig()) => new UnleashClient(config);
