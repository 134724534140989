import { Drawer as MuiDrawer, alpha } from '@mui/material';
import { ReactNode } from 'react';
import { rem } from '../../app/AppUtilities';

export interface DrawerProps {
  children?: ReactNode;
  fullscreen?: boolean;
  open: boolean;
  onClose: () => void;
}

export default function Drawer({ children, fullscreen, open, onClose }: DrawerProps) {
  return (
    <MuiDrawer
      anchor="right"
      open={open}
      onClose={onClose}
      transitionDuration={500}
      PaperProps={{
        sx: (theme) => ({
          backgroundColor: theme.palette.background.default,
          boxShadow: 'none',
          ...(fullscreen
            ? {
                maxWidth: '100%',
                width: '100%',
              }
            : {
                width: '62.5vw',
                maxWidth: rem(800),
                '@media (orientation: portrait)': {
                  maxWidth: '100%',
                  width: '100%',
                },
              }),
          // Since transition is added directly into the element style property by MUI
          // We have unfortunately no way to increase selector specificity
          // Therefore "!important" is really required here
          transitionTimingFunction: 'ease-in-out !important',
        }),
      }}
      slotProps={{
        backdrop: {
          sx: (theme) => ({
            background: alpha(theme.palette.primary.main, 0.25),
          }),
        },
      }}
    >
      {children}
    </MuiDrawer>
  );
}
