import { amber, green } from '@mui/material/colors';
import { DefaultTheme } from '@mui/material/styles/createTheme';
import { DotIcon } from '@ueat/poke';
import { Chip } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import classNames from 'classnames';

import { rem } from '../../app/AppUtilities';
import EllipsisIcon from '../icons/EllipsisIcon';

export interface PauseChannelChipLabel {
  active: string;
}

export interface PauseChannelChipData {
  remainingTime?: string;
  selected?: boolean;
  disabled?: boolean;
  pending?: boolean;
}

export interface PauseChannelChipProps {
  label: PauseChannelChipLabel;
  data: PauseChannelChipData;
  onClick: () => void;
}

const useStyles = makeStyles((theme: DefaultTheme) =>
  createStyles({
    root: {
      borderColor: theme.pauseComponent.button.border.primary,
      borderRadius: rem(100),
      height: rem(40),
      fontWeight: '700',
      fontSize: rem(theme.generalStyles.bigTextSize),
      lineHeight: rem(20),
      padding: `${rem(10)} ${rem(24)}`,
      minWidth: rem(132),

      '& .MuiChip-icon': {
        margin: 0,
        backgroundColor: green[500],
        width: rem(16),
        height: rem(16),
        borderRadius: rem(8),
        alignSelf: 'center',
      },
    },
    paused: {
      backgroundColor: amber[700],
      color: theme.palette.text.default,
      '& .MuiChip-label': {
        padding: 0,
        alignSelf: 'center',
      },
      '&:hover': {
        backgroundColor: amber[700],
      },
      '&:active': {
        backgroundColor: amber[700],
      },
    },
    active: {
      '& .MuiChip-label': {
        paddingLeft: rem(8),
        paddingRight: 0,
        alignSelf: 'center',
      },
    },
    activeSelected: {
      borderColor: theme.palette.background.paper,
      color: theme.palette.background.paper,
    },
    ellipsis: {
      height: rem(32),
      width: rem(32),
    },
  })
);

export const PauseChannelChip = ({ label, data, onClick }: PauseChannelChipProps) => {
  const styles = useStyles();

  if (data.pending) {
    return (
      <Chip
        label={<EllipsisIcon className={styles.ellipsis} />}
        color="secondary"
        className={classNames(styles.root, styles.paused)}
        disabled
        onClick={onClick}
      />
    );
  }

  if (!data.remainingTime) {
    return (
      <Chip
        icon={<DotIcon color="success" />}
        label={label.active}
        variant="outlined"
        className={classNames(styles.root, styles.active, data.selected ? styles.activeSelected : '')}
        disabled={data.disabled}
        onClick={onClick}
      />
    );
  }

  return (
    <Chip
      label={data.remainingTime}
      color="secondary"
      className={classNames(styles.root, styles.paused)}
      disabled={data.disabled}
      onClick={onClick}
    />
  );
};
