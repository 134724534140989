import { ThemeProvider as Emotion10ThemeProvider } from '@emotion/react';
import { StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider } from '@mui/styles';
import { FlagProvider } from '@unleash/proxy-client-react';
import { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import EventModal from '../orderModal/EventModal';
import WelcomeModal from '../welcomeModal/WelcomeModal';
import ApiClient from './ApiClient';
import AppLayout from './AppLayout';
import ConnectionLostModal from './ConnectionLostModal';
import theme from './muiTheme';
import RestaurantManager from './RestaurantManager';
import Routes from './Routes';
import { AuthProvider } from './AuthProvider';
import { SettingsProvider } from './SettingsContext';
import WebSocketClient from './WebSocketClient';

import 'moment-timezone';
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';
import EnvironmentBanner from './EnvironmentBanner';
import { getUnleashClient } from '../featureFlags';
import UnleashConfigurator from '../featureFlags/UnleashConfigurator';
import { canPlayNativeSound } from './features';
import { SnackbarProvider } from './SnackbarProvider';
import { LoggingErrorBoundary } from './errors/LoggingErrorBoundary';
import { useCustomEventListener } from 'react-custom-events';
import { EventTypes } from '../events';

addLocaleData([...fr, ...en]);

function App() {
  return (
    <FlagProvider unleashClient={getUnleashClient()}>
      <LoggingErrorBoundary>
        <AuthProvider>
          <SettingsProvider>
            <StyledEngineProvider injectFirst>
              <Emotion10ThemeProvider theme={theme}>
                <ThemeProvider theme={theme}>
                  <SnackbarProvider>
                    <BrowserRouter>
                      <ApiClient>
                        <RestaurantManager>
                          <UnleashConfigurator />
                          <AppContent />
                        </RestaurantManager>
                      </ApiClient>
                    </BrowserRouter>
                  </SnackbarProvider>
                </ThemeProvider>
              </Emotion10ThemeProvider>
            </StyledEngineProvider>
          </SettingsProvider>
        </AuthProvider>
      </LoggingErrorBoundary>
    </FlagProvider>
  );
}

function AppContent() {
  const [connectionLostModalOpen, setConnectionLostModalOpen] = useState(false);
  const [welcomeOpen, setWelcomeOpen] = useState(!canPlayNativeSound());

  useCustomEventListener(EventTypes.CONNECTION_LOST, () => {
    setConnectionLostModalOpen(true);
  });

  useCustomEventListener(EventTypes.CONNECTION_BACK, () => {
    setConnectionLostModalOpen(false);
  });

  return (
    <WebSocketClient>
      <AppLayout>
        <EnvironmentBanner />
        <Routes />
        <ConnectionLostModal open={connectionLostModalOpen} />
        <WelcomeModal isOpen={welcomeOpen} setOpen={setWelcomeOpen} />
        {!connectionLostModalOpen && !welcomeOpen && <EventModal />}
      </AppLayout>
    </WebSocketClient>
  );
}

export default App;
