import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { Typography, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useTraductions } from '../app/TraductionsContext';
import { rem } from '../app/AppUtilities';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      width: '100%',
      margin: `${rem(theme.spacing(2))} 0`,
      textAlign: 'center',
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
    legend: {
      padding: rem(theme.spacing(3)),
      position: 'fixed',
      bottom: 0,
      right: 0,
      left: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.secondary.main,
      boxShadow: `0px ${rem(-4)} ${rem(4)} rgba(0, 0, 0, 0.25)`,
      '&::after, &::before': {
        position: 'absolute',
        zIndex: 1000,
        content: `""`,
        height: rem(50),
        width: rem(75),
      },
      '&::after': {
        top: rem(theme.spacing(3)),
        borderTop: `${rem(2)} solid ${theme.palette.primary.contrastText}`,
        borderLeft: `${rem(2)} solid ${theme.palette.primary.contrastText}`,
      },
      '&::before': {
        borderBottom: `${rem(2)} solid ${theme.palette.primary.contrastText}`,
        borderRight: `${rem(2)} solid ${theme.palette.primary.contrastText}`,
        bottom: rem(theme.spacing(3)),
        right: rem(theme.spacing(3)),
      },
      [theme.breakpoints.up('md')]: {
        width: '30%',
        top: rem(theme.generalStyles.appBarLargeScreenHeight),
        left: 'auto',
        justifyContent: 'center',
        boxShadow: `${rem(-4)} 0px ${rem(4)} rgba(0, 0, 0, 0.25)`,
      },
    },
    legendItems: {
      marginBottom: rem(theme.spacing(2)),
      width: '100%',
      display: 'flex',
      justifyContent: 'space-around',
      flexWrap: 'wrap',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'column',
        alignContent: 'center',
      },
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      fontSize: rem(theme.generalStyles.baseTextSize),
      [theme.breakpoints.up('md')]: {
        width: '70%',
        marginRight: 'auto',
        marginLeft: 'auto',
      },
    },
    itemText: {
      marginLeft: rem(theme.spacing(2)),
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
  })
);

export default function PartnerStatesCaptions() {
  const traductions = useTraductions();
  const classes = useStyles();

  return (
    <div className={classes.legend}>
      <Typography variant="h4" className={classes.title}>
        {traductions.thirdPartyDeliveryPartnerPage.legend}
      </Typography>
      <div className={classes.legendItems}>
        <div className={classes.item}>
          <CheckCircleOutlineIcon />
          <p className={classes.itemText}>{traductions.thirdPartyDeliveryPartnerPage.on}</p>
        </div>
        <div className={classes.item}>
          <HighlightOffOutlinedIcon />
          <p className={classes.itemText}>{traductions.thirdPartyDeliveryPartnerPage.off}</p>
        </div>
      </div>
    </div>
  );
}
