export interface RestaurantManagerType {
  loading: string;
  error: string;
}

export const enCa: RestaurantManagerType = {
  loading: 'loading available restaurants...',
  error: 'error: Could not find any restaurant.',
};

export const frCa: RestaurantManagerType = {
  loading: 'chargement des restaurants disponibles...',
  error: 'erreur: aucun restaurant trouvé.',
};
