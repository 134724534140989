type Props = {
  className?: string;
};

export default function EllipsisIcon({ className }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
    >
      <circle cx="3" cy="12" r="3">
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0 3 ; 0 -3; 0 3"
          dur="1s"
          begin="0.1"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx="12" cy="12" r="3">
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0 2 ; 0 -2; 0 2"
          begin="0.2"
          dur="1s"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx="21" cy="12" r="3">
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0 1 ; 0 -1; 0 1"
          dur="1s"
          begin="0.3"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  );
}
