import { Grid, Typography, Theme, Box } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import React from 'react';
import { PauseButton, PauseButtonData, PauseButtonLabel } from '../pauseButton';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { rem } from '../../app/AppUtilities';
import { green } from '@mui/material/colors';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.pauseComponent.banner.background.default,
      alignItems: 'center',
      borderRadius: `${rem(39)} 0 0 ${rem(39)}`,
      paddingRight: rem(24),
      marginRight: rem(-24),
      paddingLeft: rem(28),
    },
    icon: {
      color: green[500],
    },
    circle: {
      height: rem(16),
      width: rem(16),
      marginTop: rem(4),
    },
    title: {
      fontWeight: 800,
      fontSize: rem(theme.generalStyles.bigTextSize),
      textAlign: 'left',
      lineHeight: rem(20),
    },
    bannerItems: {
      '&.MuiGrid-item': {
        padding: `${rem(12)} ${rem(24)} ${rem(12)} ${rem(12)}`,
      },
    },
    pending: {
      opacity: 0.6,
    },
  })
);
export interface ResumeBannerLabel {
  title: string;
  pauseButton: PauseButtonLabel;
}
export interface ResumeBannerData {
  pauseButton: PauseButtonData;
}
export interface ResumeBannerProps {
  label: ResumeBannerLabel;
  data: ResumeBannerData;
  onPauseButtonClick: () => void;
}
const ResumeBanner = ({ label, data, onPauseButtonClick }: ResumeBannerProps) => {
  const classes = useStyles();
  return (
    <Grid
      container
      classes={{
        root: classes.container,
      }}
    >
      <Grid item className={classes.icon}>
        <CircleIcon className={classNames(classes.circle, data.pauseButton.pending && classes.pending)} />
      </Grid>
      <Grid
        item
        classes={{
          item: classes.bannerItems,
        }}
      >
        <Typography
          color="textPrimary"
          display="block"
          className={classNames(classes.title, data.pauseButton.pending && classes.pending)}
          component="span"
        >
          <pre style={{ fontFamily: 'inherit', margin: 0 }}>{label.title}</pre>
        </Typography>
      </Grid>
      <Grid item>
        <PauseButton label={label.pauseButton} data={data.pauseButton} onPauseButtonClick={onPauseButtonClick} />
      </Grid>
    </Grid>
  );
};

export default ResumeBanner;
