import React from 'react';
import { Order } from './OrderDetailTypes';
import FormattedPrice from '../formatted/FormattedPrice';
import SeparationLine from './SeparationLine';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import ItemElement, { ElementType } from './ItemElement';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flexShrink: 0,
      flexBasis: 'auto',
    },
  })
);

interface Props {
  orderData: Order;
  currency: string;
}

export default function Taxes({ orderData, currency }: Props) {
  const classes = useStyles();

  if (orderData.taxes && orderData.taxes.length > 0) {
    return (
      <>
        <SeparationLine />
        {orderData.taxes.map((tax) => (
          <li className={classes.itemContainer} key={tax.name}>
            <ItemElement type={ElementType.Name}>{tax.name}</ItemElement>
            <ItemElement type={ElementType.Price}>
              <FormattedPrice value={tax.amount} currency={currency} />
            </ItemElement>
          </li>
        ))}
      </>
    );
  }

  return null;
}
