import { gql } from 'graphql-tag';

export const PAUSE_STATES_SUBSCRIPTION = gql`
  subscription pauseStates($branchId: Int!) {
    pauseStates(branchId: $branchId) {
      channel
      pausedUntil
      status
      statusCreatedAt
    }
  }
`;
