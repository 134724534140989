import { useQuery } from '@apollo/client';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import moment from 'moment';
import 'moment/locale/fr-ca';
import React, { useEffect, useState } from 'react';
import { RESTAURANTMANAGER_QUERY, RESTAURANT_BRANCHES_QUERY, USER_ROLE_QUERY } from '../queries/queries';
import { rem } from './AppUtilities';
import {
  Branch,
  BranchesQueryData,
  BranchesQueryVariables,
  QueryData,
  UserRoleQueryData,
} from './RestaurantInfoQueryType';
import { useSettings } from './SettingsContext';
import { useTraductions } from './TraductionsContext';

export const useQueryMessageStyle = makeStyles((theme: Theme) =>
  createStyles({
    message: {
      fontSize: rem(theme.generalStyles.mediumTextSize),
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
  })
);

interface Props {
  children: React.ReactNode;
}

const useRestaurantManagerQuery = () => {
  const settings = useSettings();

  moment.locale(settings.culture);

  const restaurantManager = useQuery<QueryData>(RESTAURANTMANAGER_QUERY, { skip: !settings.authToken });

  const branches = useQuery<BranchesQueryData, BranchesQueryVariables>(RESTAURANT_BRANCHES_QUERY, {
    skip: !settings.authToken || !settings.currentRestaurantId,
    variables: { restaurantId: settings.currentRestaurantId! },
  });

  const userRole = useQuery<UserRoleQueryData>(USER_ROLE_QUERY, { skip: !settings.authToken });

  const [lastBranchData, setLastBranchData] = useState<Branch[] | undefined>([]);

  useEffect(() => {
    if (restaurantManager.data?.myRestaurants && branches.data?.branches !== lastBranchData) {
      setLastBranchData(branches.data?.branches);

      settings.setRestaurant(
        settings.currentRestaurantId!,
        settings.currentBranchId!,
        restaurantManager.data.myRestaurants,
        branches.data?.branches ?? []
      );
    }
  }, [restaurantManager.data, branches.data, settings, lastBranchData]);

  useEffect(() => {
    if (userRole.data) {
      if (settings.userRole !== userRole.data.user.companyUserRole) {
        settings.setUserRole(userRole.data.user.companyUserRole);
      }
      if (settings.isGlobalAdmin !== userRole.data.user.isGlobalAdmin) {
        settings.setGlobalAdmin(userRole.data.user.isGlobalAdmin);
      }
    }
  }, [userRole.data]);

  return {
    restaurantManager,
    userRole,
    branches,
  };
};

export default function RestaurantManager({ children }: Props) {
  const settings = useSettings();

  const { restaurantManager, branches } = useRestaurantManagerQuery();

  const traductions = useTraductions();
  const classes = useQueryMessageStyle();

  if (!settings.authToken) {
    return <>{children}</>;
  }

  if (restaurantManager.loading || branches.loading)
    return <p className={classes.message}>{traductions.restaurantManager.loading}</p>;
  if (restaurantManager.error || branches.error)
    return <p className={classes.message}>{traductions.restaurantManager.error}</p>;

  return <>{children}</>;
}
