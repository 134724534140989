import { Box } from '@mui/material';
import React from 'react';
import { Order } from './OrderDetailTypes';
import { useTraductions } from '../app/TraductionsContext';
import SubTitle from './SubTitle';

interface Props {
  orderData: Order;
}

export default function ServicePrecisions({ orderData }: Props) {
  const traductions = useTraductions();

  if (orderData.service) {
    const precisions = orderData.service;

    if (precisions) {
      for (let key in precisions) {
        if (key !== 'name' && key !== '__typename' && precisions[key]) {
          return (
            <Box>
              <SubTitle>{traductions.orderDetailPage[key.replace('Number', '').replace('Location', '')]}</SubTitle>
              <p>{precisions[key]}</p>
            </Box>
          );
        }
      }
    }
  }

  return null;
}
