function getEnvironmentVariable<T>(name: string, defaultValue: T): T {
  if (import.meta.env.DEV) {
    return (import.meta.env[`REACT_APP_${name}`] as T) || defaultValue;
  }

  return ((window as any)[name] as T) || defaultValue;
}

export function getAppUrl() {
  return getEnvironmentVariable('UEAT_APP_URL', 'https://uboard.ueat.dev:7000');
}

export function getBaseApiUrl(): string {
  return getEnvironmentVariable('UBOARD_API', 'https://api.staging.ueat.io/v1/uboard');
}

export function getApiUrl(): string {
  const graphqlSegment = '/graphql';
  return `${getBaseApiUrl()}${graphqlSegment}`;
}

export function getMainApiUrl() {
  return getEnvironmentVariable('UEAT_API', 'https://api.ueat.dev:7000');
}

export function getWebSocketURL() {
  return getEnvironmentVariable('UEAT_WEBSOCKET_URL', 'wss://api.staging.ueat.io');
}

export function getUboardWebSocketURL() {
  return getEnvironmentVariable('UBOARD_WEBSOCKET_URL', 'wss://api.staging.ueat.io/v1/uboard');
}

export function isPrintPreviewEnabled(): boolean {
  if (import.meta.env.DEV) {
    return (
      import.meta.env.REACT_APP_ALLOW_PRINT_PREVIEW === 'true' ||
      (window as any).UEAT_ENVIRONMENT === 'staging' ||
      false
    );
  }

  return (window as any).ALLOW_PRINT_PREVIEW;
}

export function getUeatEnvironment() {
  return getEnvironmentVariable('UEAT_ENVIRONMENT', 'development');
}

export function getUnleashProxyUrl() {
  return getEnvironmentVariable('UNLEASH_PROXY_URL', 'https://unleash-proxy.ueat.dev:7000');
}

export function getUnleashProxyKey() {
  return getEnvironmentVariable('UNLEASH_PROXY_KEY', 'ueat-dev-unleash-secrets');
}

export function getIdentityProviderUrl() {
  return getEnvironmentVariable('UEAT_IDENTITY_PROVIDER_URL', 'https://auth.staging.ueat.io/realms/b2b-global');
}

export function getIdentityProviderClientId() {
  return getEnvironmentVariable('UEAT_IDENTITY_PROVIDER_CLIENT_ID', 'uboard');
}

export function getImageUrl() {
  return getEnvironmentVariable('IMAGE_URL', 'https://storage.googleapis.com/ueat-assets');
}
