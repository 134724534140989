import React from 'react';
import { Typography, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useTraductions } from '../app/TraductionsContext';
import DecorationLine, { DecorationLineVariant } from '../prepTime/DecorationLine';
import ueatLogo from '../auth/ueat-logo.svg';
import * as SupportInformation from './SupportInformation';
import classNames from 'classnames';
import { rem } from '../app/AppUtilities';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center', // 'flex-start'
      // [theme.breakpoints.up('md')]: {
      //   justifyContent: 'space-between'
      // }
    },
    gridContainer: {
      justifyContent: 'center',
      // marginBottom: rem(theme.spacing(6)),
      flexBasis: '100%',
      display: 'grid',
      gridTemplateColumns: '300px auto', //'220px auto'
      gridTemplateRows: 'auto auto auto',
      fontSize: rem(theme.generalStyles.biggerTextSize),
      [theme.breakpoints.up('md')]: {
        flexBasis: '46%',
      },
    },
    logoContainer: {
      margin: '0 calc(50% - 238px/2)',
    },
    // logoContainer: {
    //   margin: 'auto auto 0'
    // }
    title: {
      gridColumn: '1/3',
      gridRow: '1/2',
      marginBottom: rem(theme.spacing(2)),
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
    titleLine: {
      gridColumn: '1/3',
      gridRow: '1/2',
      alignSelf: 'end',
    },
    paragraph: {
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
    button: {
      padding: `${rem(theme.spacing(1))} ${rem(theme.spacing(4))}`,
      fontSize: rem(theme.generalStyles.mediumTextSize),
      letterSpacing: rem(1),
      wordSpacing: rem(2),
    },
    supportInformation: {
      margin: `0 0 ${rem(theme.spacing(3))}`,
      fontWeight: 'bold',
      fontSize: rem(theme.generalStyles.biggerTextSize + 10),
      [theme.breakpoints.up('md')]: {
        fontSize: rem(theme.generalStyles.biggerTextSize),
      },
    },
    phoneSubtitle: {
      marginBottom: rem(theme.spacing(1)),
    },
    poweredBy: {
      fontSize: rem(theme.generalStyles.mediumTextSize),
      fontWeight: 'bold',
    },
    firstColumn: {
      gridColumn: '1/2',
    },
    secondColumn: {
      gridColumn: '2/3',
    },
    firstRow: {
      gridRow: '2/3',
    },
    secondRow: {
      margin: 0,
      gridRow: '3/4',
      alignSelf: 'center',
    },
    fullWidth: {
      gridColumn: '1/3',
    },
  })
);

export default function SupportPage() {
  const traductions = useTraductions();
  const classes = useStyles();

  return (
    <div className={classes.pageContainer}>
      <div className={classes.gridContainer}>
        <Typography variant="h4" className={classes.title}>
          {traductions.supportPage.baseTitle}
        </Typography>
        <DecorationLine variant={DecorationLineVariant.Red} className={classes.titleLine} />
        <p className={classNames(classes.firstColumn, classes.firstRow, classes.paragraph)}>
          {traductions.supportPage.phone}
        </p>
        <div className={classNames(classes.secondColumn, classes.firstRow)}>
          <p className={classNames(classes.paragraph, classes.phoneSubtitle)}>
            {traductions.supportPage.international}
          </p>
          <p className={classes.supportInformation}>{SupportInformation.INTERNATIONAL_NUMBER}</p>
          <p className={classNames(classes.paragraph, classes.phoneSubtitle)}>{traductions.supportPage.quebecArea}</p>
          <p className={classes.supportInformation}>{SupportInformation.QUEBEC_AREA_NUMBER}</p>
        </div>
        <p className={classNames(classes.firstColumn, classes.secondRow, classes.paragraph)}>
          {traductions.supportPage.email}
        </p>
        <p className={classNames(classes.supportInformation, classes.secondColumn, classes.secondRow)}>
          {SupportInformation.SUPPORT_EMAIL}
        </p>
      </div>
      {/* <div className={classes.gridContainer}>
        <Typography variant="h4" className={classes.title}>
          {traductions.supportPage.videoTutorialTitle}
        </Typography>
        <DecorationLine variant={DecorationLineVariant.Red} className={classes.titleLine} />
        <p className={classNames(classes.paragraph, classes.fullWidth)}>
          {traductions.supportPage.videoTutorialParagraph}
        </p>
        <Button classes={{ root: classes.button }} variant="contained" color="primary">
          {traductions.supportPage.buttonText}
        </Button>
      </div> */}
      <div className={classes.logoContainer}>
        <p className={classNames(classes.poweredBy, classes.paragraph)}>{traductions.supportPage.poweredBy}</p>
        <img src={ueatLogo} alt={SupportInformation.UEAT_LOGO_ALT} />
      </div>
    </div>
  );
}
