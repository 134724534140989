import React from 'react';
import { TextField, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { rem } from '../app/AppUtilities';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputBase: {
      width: rem(theme.generalStyles.settingsInputContainerWidth),
      marginTop: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
    },
    textField: {
      fontSize: rem(theme.generalStyles.baseTextSize + 4),
    },
    inputLabel: {
      fontSize: rem(theme.generalStyles.baseTextSize + 4),
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
  })
);

interface Props {
  id: string;
  label: string;
  value: string;
  name: string;
  onChange: (event: React.ChangeEvent<{ name: string; value: unknown }>) => void;
}

export default function SettingsInput({ id, label, value, name, onChange }: Props) {
  const classes = useStyles();

  return (
    <TextField
      id={id}
      name={name}
      label={label}
      classes={{
        root: classes.inputBase,
      }}
      inputProps={{
        className: classes.textField,
      }}
      InputLabelProps={{
        className: classes.inputLabel,
      }}
      value={value}
      onChange={onChange}
      variant="outlined"
    />
  );
}
