import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { rem } from './AppUtilities';
import { useTraductions } from './TraductionsContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      marginTop: rem(theme.spacing(2)),
      color: theme.palette.secondary.main,
      lineHeight: 1,
      fontSize: rem(theme.generalStyles.mediumTextSize),
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: rem(theme.spacing(3)),
      fontSize: rem(theme.generalStyles.biggerTextSize),
      fontWeight: 'bold',
      padding: rem(theme.generalStyles.containerPadding),
      borderLeft: `${rem(10)} solid ${theme.palette.primary.main}`,
    },
    titleText: {
      paddingLeft: rem(theme.generalStyles.containerPadding),
      display: 'inline-block',
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
    icon: {
      height: rem(theme.generalStyles.mediumIconSize),
      width: rem(theme.generalStyles.mediumIconSize),
    },
  })
);

interface Props {
  open: boolean;
}

export default function ConnectionLostModal({ open }: Props) {
  const traductions = useTraductions();
  const classes = useStyles();

  return (
    <Dialog open={open} maxWidth="sm" fullWidth={true}>
      <DialogTitle
        classes={{
          root: classes.title,
        }}
      >
        <NewReleasesIcon className={classes.icon} />
        <span className={classes.titleText}>{traductions.connectionLostModal.title}</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          classes={{
            root: classes.text,
          }}
          id="connectionlost-dialog-description"
        >
          {traductions.connectionLostModal.text}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
