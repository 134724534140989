import * as React from 'react';
import ReactModal from 'react-modal';

type Props = {
  onClose: () => void;
  open: boolean;
  image: string;
};

function PrintPreviewModal({ onClose, open, image }: Props) {
  return (
    <ReactModal isOpen={open} onRequestClose={onClose} contentLabel="Print Preview">
      <img src={image} alt="to be rendered if compatible" />
      <button onClick={onClose}>Close</button>
    </ReactModal>
  );
}

export default PrintPreviewModal;
