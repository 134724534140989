import * as main from './main';
import * as notFound from './notFound';
import * as loginPage from './loginPage';
import * as restaurantManager from './restaurantManager';
import * as menuItems from './menuItems';
import * as menuItemsPage from './menuItemsPage';
import * as ordersListPage from './ordersListPage';
import * as orderDetailPage from './orderDetailPage';
import * as preparationTimePage from './preparationTime';
import * as settingsPage from './settingsPage';
import * as supportPage from './supportPage';
import * as bottomNav from './bottomNav';
import * as eventModal from './eventModal';
import * as welcomeModal from './welcomeModal';
import * as connectionLostModal from './connectionLostModal';
import * as thirdPartyDeliveryPartnerPage from './thirdPartyDeliveryPartnerPage';
import * as pauseBanner from './pauseBanner';

export interface TraductionsType {
  main: main.MainType;
  notFound: notFound.NotFoundType;
  loginPage: loginPage.LoginPageType;
  restaurantManager: restaurantManager.RestaurantManagerType;
  menuItems: menuItems.MenuItemsType;
  menuItemsPage: menuItemsPage.MenuItemsPageType;
  ordersListPage: ordersListPage.OrdersListPageType;
  orderDetailPage: orderDetailPage.OrderDetailPageType;
  preparationTimePage: preparationTimePage.PreparationTimePageType;
  bottomNav: bottomNav.BottomNavType;
  settingsPage: settingsPage.SettingsPageType;
  supportPage: supportPage.SupportPageType;
  eventModal: eventModal.EventModalType;
  welcomeModal: welcomeModal.WelcomeModalType;
  connectionLostModal: connectionLostModal.ConnectionLostModalType;
  thirdPartyDeliveryPartnerPage: thirdPartyDeliveryPartnerPage.ThirdPartyDeliveryPartnerPageType;
  pauseBanner: pauseBanner.PauseBannerType;
}

export const frCa: TraductionsType = {
  main: main.frCa,
  notFound: notFound.frCa,
  loginPage: loginPage.frCa,
  restaurantManager: restaurantManager.frCa,
  menuItems: menuItems.frCa,
  menuItemsPage: menuItemsPage.frCa,
  ordersListPage: ordersListPage.frCa,
  orderDetailPage: orderDetailPage.frCa,
  preparationTimePage: preparationTimePage.frCa,
  settingsPage: settingsPage.frCa,
  supportPage: supportPage.frCa,
  bottomNav: bottomNav.frCa,
  eventModal: eventModal.frCa,
  welcomeModal: welcomeModal.frCa,
  connectionLostModal: connectionLostModal.frCa,
  thirdPartyDeliveryPartnerPage: thirdPartyDeliveryPartnerPage.frCa,
  pauseBanner: pauseBanner.frCa,
};

export const enCa: TraductionsType = {
  main: main.enCa,
  notFound: notFound.enCa,
  loginPage: loginPage.enCa,
  restaurantManager: restaurantManager.enCa,
  menuItems: menuItems.enCa,
  menuItemsPage: menuItemsPage.enCa,
  ordersListPage: ordersListPage.enCa,
  orderDetailPage: orderDetailPage.enCa,
  preparationTimePage: preparationTimePage.enCa,
  settingsPage: settingsPage.enCa,
  supportPage: supportPage.enCa,
  bottomNav: bottomNav.enCa,
  eventModal: eventModal.enCa,
  welcomeModal: welcomeModal.enCa,
  connectionLostModal: connectionLostModal.enCa,
  thirdPartyDeliveryPartnerPage: thirdPartyDeliveryPartnerPage.enCa,
  pauseBanner: pauseBanner.enCa,
};
