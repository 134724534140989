export interface Data {
  thirdPartyDeliveryPartners: ThirdPartyDeliveryPartnerType[];
}

export interface Variables {
  branchId: number;
}

export interface ThirdPartyDeliveryPartnerType {
  id: number;
  order: number;
  isDisabled: boolean;
  thirdPartyDeliveryProviderName: string;
}

export enum ThirdPartyDeliveryPartnerState {
  Active = 0,
  Disabled = 1,
}
