import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Checkbox, FormControlLabel, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { rem } from '../app/AppUtilities';
import { Settings, useSettings } from '../app/SettingsContext';
import { useTraductions } from '../app/TraductionsContext';
import { TraductionsType } from '../traductions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: 'flex-start',
      width: 'auto',
      marginRight: 0,
    },
    label: {
      fontSize: rem(theme.generalStyles.baseTextSize + 4),
      '&:first-letter': {
        textTransform: 'capitalize',
      },
    },
    checkbox: {
      fontSize: rem(theme.generalStyles.baseTextSize + 4),
    },
  })
);

export enum CheckBoxType {
  Alert = 'alert',
  Sound = 'sound',
}

interface Props {
  type: CheckBoxType;
}

export default function SettingsCheckBox({ type }: Props) {
  const settings = useSettings();
  const traductions = useTraductions();
  const classes = useStyles();
  const isChecked = getCheckboxState(type, settings);
  const label = getCheckBoxLabel(type, traductions);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (type === CheckBoxType.Alert) {
      settings.setAlertProfile(event.target.checked, settings.alertProfile.soundOn, settings.alertProfile.id);
    } else if (type === CheckBoxType.Sound) {
      settings.setAlertProfile(settings.alertProfile.alertsOn, event.target.checked, settings.alertProfile.id);
    }
  };

  if (type === CheckBoxType.Sound && !settings.alertProfile.alertsOn) {
    return null;
  }

  return (
    <FormControlLabel
      classes={{ root: classes.root, label: classes.label }}
      control={
        <Checkbox
          className={classes.checkbox}
          color="primary"
          icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
          checkedIcon={<CheckBoxIcon fontSize="large" />}
          checked={isChecked}
          onChange={handleChange}
          value={type}
        />
      }
      label={label}
      data-testid={`settings-${type}-checkbox`}
    />
  );
}

function getCheckBoxLabel(type: CheckBoxType, traductions: TraductionsType): string {
  switch (type) {
    case CheckBoxType.Alert:
      return traductions.settingsPage.alertsLabel;
    case CheckBoxType.Sound:
      return traductions.settingsPage.soundLabel;
    default:
      return '';
  }
}

function getCheckboxState(type: CheckBoxType, settings: Settings) {
  switch (type) {
    case CheckBoxType.Alert:
      return settings.alertProfile.alertsOn;
    case CheckBoxType.Sound:
      return settings.alertProfile.soundOn;
    default:
      return false;
  }
}
